import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import ThemeLoader from "helpers/components/ThemeLoader";
import { BrowserRouter as Router } from "react-router-dom";
import store from "store";
import {
  AmplifyAuthenticator,
  AmplifySignIn,
  AmplifySignUp,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import { onAuthUIStateChange } from "@aws-amplify/ui-components";

import Amplify, { Auth, I18n } from "aws-amplify";
import { CssBaseline, Box, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Dashboard from "pages/Dashboard";
import markerSDK from "@marker.io/browser";
import { markerIo } from "constants/index";
import awsmobile from "./aws-exports";
Amplify.configure(awsmobile);
Auth.configure(awsmobile);

const authScreenLabels = {
  en: {
    "Enter your code": "Confirmation Code Sent to Mobile Phone",
    "Sign in to your account": "Login to your Employifi Account",
    "Confirm Sign up":
      "To Create an Account, please verify your phone number by entering SMS code",
    "Confirm SMS Code": "To login, please enter code sent to phone",
  },
};

I18n.setLanguage("en");
I18n.putVocabularies(authScreenLabels);

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },

  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
  },
  cardActions: {
    flexDirection: "column",
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
    paddingTop: "10px",
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  submitCode: {
    color: "#ffffff",
    backgroundColor: "#ff9900",

    "&:hover": {
      backgroundColor: "#ffac31",
    },
  },
  cardMain: {
    display: "flex",
    maxWidth: "450px",
    justifyContent: "center",
    margin: "auto",
  },
}));

const userDataInitialState = {
  loading: true,
  data: null,
  error: null,
};

const MyAuth = (props) => {
  const [userData, setUserData] = useState(userDataInitialState);
  const [authState, setAuthState] = useState();
  const [user, setUser] = useState(null); // eslint-disable-line no-unused-vars
  const [userGroup, setUserGroup] = useState("");

  useEffect(() => {
    (async () => {
      if (authState === "signedin") {
        const userInfo = await Auth.currentUserInfo();
        let user = await Auth.currentAuthenticatedUser();
        let group =
          user.signInUserSession.accessToken.payload["cognito:groups"];
        if (group && group.length > 0) {
          setUserGroup(group[0]);
        }
        console.log(group, user, userInfo);
        setUserData({
          ...userData,
          loading: false,
          data: userInfo,
        });
      }
//       await markerSDK.loadWidget({
//         destination: markerIo,
//       });
    })();
    // eslint-disable-next-line
  }, [authState, setUserData]);

  useEffect(() => {
    return onAuthUIStateChange((newAuthState, authData) => {
      setUser(authData);
      setAuthState(newAuthState);
    });
  }, [props]);

  const getCircularProgress = () => {
    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        <CircularProgress />
      </Box>
    );
  };

  const renderMainApp = (isEmailVerified) => {
    return (
      <React.Fragment>
        {/* <Router> */}
        <CssBaseline />
        <Provider store={store}>
          <ThemeLoader>
            <Dashboard
              userGroup={userGroup}
              isEmailVerified={isEmailVerified}
              userData={userData}
              path={window.location.pathname}
            />
          </ThemeLoader>
        </Provider>
        {/* </Router> */}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <AmplifyAuthenticator
        usernameAlias="email"
        style={
          authState === "signedin"
            ? {}
            : {
                display: "flex",
                justifyContent: "center",
              }
        }
      >
        <div slot="sign-up">
          <AmplifySignUp
            slot="sign-up"
            usernameAlias="email"
            formFields={[
              {
                type: "email",
                label: "Email",
                required: true,
              },
              {
                type: "password",
                label: "Password",
                required: true,
              },
              {
                type: "phone_number",
                label: "Cell-Phone Number",
                required: true,
              },
            ]}
          />
        </div>

        <div slot="sign-in">
          <AmplifySignIn
            slot="sign-in"
            usernameAlias="email"
            className="SignInForm"
          />
        </div>
        <>
          {userData.loading && getCircularProgress()}
          {!userData.loading &&
            renderMainApp(userData.data?.attributes?.email_verified)}
        </>
      </AmplifyAuthenticator>
    </React.Fragment>
  );
};
export default withAuthenticator(MyAuth);
//export default App;
