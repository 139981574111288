export const domainTitles = {
	'ca-usa.com': 'Consolidated Analytics',
	'erelations.com': 'Employee Relations',
	'playwithdough.com': 'Play With Dough',
};

export const svsStatus = ['Yes', 'No'];

export const progressBarConstants = {
	isOfferLetterSent: 'Conditional Offer Letter',
	erxId: 'Background Check',
	backgroundCheckResponse: 'Drug Test',
	ecocForm: 'ECOC Form',
	finalOfferLetter: 'Final Offer Letter',
};

export const pageTitles = {
	adverse: 'Adverse',
	pcr: 'PCR',
	orders: 'Orders',
	products: 'Products',
	communication: 'Communication',
	constant: 'Constants',
	company: 'Companies',
	'': 'Dashboard',
	applicants: 'Applicant',
	user: 'Submissions',
	users: 'Users',
	profile: 'Profile',
};

const getInitialValue = (data, key, defaultValue) => {
	if (data && data[key]) {
		return data[key];
	}
	return defaultValue;
};

export const csvInitialData = (backgroundData, applicationData) => {
	return [
		{
			firstName: getInitialValue(backgroundData, 'firstName', ''),
			lastName: getInitialValue(backgroundData, 'lastName', ''),
			ssn: getInitialValue(backgroundData, 'SSN', ''),
			dateOfBirth: getInitialValue(backgroundData, 'dateOfBirth', ''),
			licenseNumber: getInitialValue(backgroundData, 'licenseNumber', ''),
			licenseState: getInitialValue(backgroundData, 'state', ''),
			email: getInitialValue(backgroundData, 'email', ''),
			phoneNumber: getInitialValue(backgroundData, 'phoneNumber', ''),

			gradSchoolName: getInitialValue(applicationData, 'universityName', ''),
			gradDegree: getInitialValue(
				applicationData,
				'universityDegreeReceived',
				''
			),
			gradMajor: getInitialValue(applicationData, 'major4', ''),

			highSchoolName: getInitialValue(applicationData, 'highSchoolName', ''),
			highSchoolDegree: getInitialValue(
				applicationData,
				'highSchoolDegree',
				''
			),

			employerName: getInitialValue(applicationData, 'employerName', ''),
			employmentPosition: getInitialValue(
				applicationData,
				'employeeJobTitle',
				''
			),
			employmentCity: getInitialValue(applicationData, 'addressCitySuburb', ''),
			employmentState: getInitialValue(applicationData, 'addressState', ''),

			mailingAddress: getInitialValue(applicationData, 'contactAddress', ''),
			mailingCity: getInitialValue(applicationData, 'contactCity', ''),
			mailingState: getInitialValue(applicationData, 'contactState', ''),
			mailingZipcode: getInitialValue(applicationData, 'contactZipCode', ''),

			reference1: getInitialValue(applicationData, 'referenceName1', ''),
			referencePhone1: getInitialValue(applicationData, 'phoneNumber', ''),
			referenceEmail1: getInitialValue(
				applicationData,
				'referenceEmailAddress1',
				''
			),

			reference2: getInitialValue(applicationData, 'referenceName2', ''),
			referencePhone2: getInitialValue(applicationData, 'phoneNumber3', ''),
			referenceEmail2: getInitialValue(
				applicationData,
				'referenceEmailAddress2',
				''
			),
		},
	];
};

export const productPackages = [
	{
		id: 'default',
		value: 'Default',
	},
	{
		id: 'optional',
		value: 'Optional',
	},
];

export const disqualifyingEvents = [
	'Criminal History',
	'Credit History',
	'Employment History',
	'Reference Information',
	'Application Inconsistency',
	'Driving Record',
	'Unable to Verify Social Security Number',
];

export const jobDutiesConstants = [
	'Position of Trust',
	'Works with others',
	'Works with cash receipts',
	'Access to sensitive/confidential data',
];

export const criminalOffenses = [
	'Misdemeanor',
	'Felony',
	'Violence',
	'Theft',
	'Fraud',
];

export const timeToOffenses = ['1 Year', '1 - 5 years', 'More than 5 years'];

export const payType = [
	{
		id: 'hourly',
		value: 'Hourly',
	},
	{
		id: 'salary',
		value: 'Salary',
	},
];

export const states = [
	{
		name: 'Alabama',
		abbreviation: 'AL',
	},
	{
		name: 'Alaska',
		abbreviation: 'AK',
	},
	{
		name: 'American Samoa',
		abbreviation: 'AS',
	},
	{
		name: 'Arizona',
		abbreviation: 'AZ',
	},
	{
		name: 'Arkansas',
		abbreviation: 'AR',
	},
	{
		name: 'California',
		abbreviation: 'CA',
	},
	{
		name: 'Colorado',
		abbreviation: 'CO',
	},
	{
		name: 'Connecticut',
		abbreviation: 'CT',
	},
	{
		name: 'Delaware',
		abbreviation: 'DE',
	},
	{
		name: 'District Of Columbia',
		abbreviation: 'DC',
	},
	{
		name: 'Federated States Of Micronesia',
		abbreviation: 'FM',
	},
	{
		name: 'Florida',
		abbreviation: 'FL',
	},
	{
		name: 'Georgia',
		abbreviation: 'GA',
	},
	{
		name: 'Guam',
		abbreviation: 'GU',
	},
	{
		name: 'Hawaii',
		abbreviation: 'HI',
	},
	{
		name: 'Idaho',
		abbreviation: 'ID',
	},
	{
		name: 'Illinois',
		abbreviation: 'IL',
	},
	{
		name: 'Indiana',
		abbreviation: 'IN',
	},
	{
		name: 'Iowa',
		abbreviation: 'IA',
	},
	{
		name: 'Kansas',
		abbreviation: 'KS',
	},
	{
		name: 'Kentucky',
		abbreviation: 'KY',
	},
	{
		name: 'Louisiana',
		abbreviation: 'LA',
	},
	{
		name: 'Maine',
		abbreviation: 'ME',
	},
	{
		name: 'Marshall Islands',
		abbreviation: 'MH',
	},
	{
		name: 'Maryland',
		abbreviation: 'MD',
	},
	{
		name: 'Massachusetts',
		abbreviation: 'MA',
	},
	{
		name: 'Michigan',
		abbreviation: 'MI',
	},
	{
		name: 'Minnesota',
		abbreviation: 'MN',
	},
	{
		name: 'Mississippi',
		abbreviation: 'MS',
	},
	{
		name: 'Missouri',
		abbreviation: 'MO',
	},
	{
		name: 'Montana',
		abbreviation: 'MT',
	},
	{
		name: 'Nebraska',
		abbreviation: 'NE',
	},
	{
		name: 'Nevada',
		abbreviation: 'NV',
	},
	{
		name: 'New Hampshire',
		abbreviation: 'NH',
	},
	{
		name: 'New Jersey',
		abbreviation: 'NJ',
	},
	{
		name: 'New Mexico',
		abbreviation: 'NM',
	},
	{
		name: 'New York',
		abbreviation: 'NY',
	},
	{
		name: 'North Carolina',
		abbreviation: 'NC',
	},
	{
		name: 'North Dakota',
		abbreviation: 'ND',
	},
	{
		name: 'Northern Mariana Islands',
		abbreviation: 'MP',
	},
	{
		name: 'Ohio',
		abbreviation: 'OH',
	},
	{
		name: 'Oklahoma',
		abbreviation: 'OK',
	},
	{
		name: 'Oregon',
		abbreviation: 'OR',
	},
	{
		name: 'Palau',
		abbreviation: 'PW',
	},
	{
		name: 'Pennsylvania',
		abbreviation: 'PA',
	},
	{
		name: 'Puerto Rico',
		abbreviation: 'PR',
	},
	{
		name: 'Rhode Island',
		abbreviation: 'RI',
	},
	{
		name: 'South Carolina',
		abbreviation: 'SC',
	},
	{
		name: 'South Dakota',
		abbreviation: 'SD',
	},
	{
		name: 'Tennessee',
		abbreviation: 'TN',
	},
	{
		name: 'Texas',
		abbreviation: 'TX',
	},
	{
		name: 'Utah',
		abbreviation: 'UT',
	},
	{
		name: 'Vermont',
		abbreviation: 'VT',
	},
	{
		name: 'Virgin Islands',
		abbreviation: 'VI',
	},
	{
		name: 'Virginia',
		abbreviation: 'VA',
	},
	{
		name: 'Washington',
		abbreviation: 'WA',
	},
	{
		name: 'West Virginia',
		abbreviation: 'WV',
	},
	{
		name: 'Wisconsin',
		abbreviation: 'WI',
	},
	{
		name: 'Wyoming',
		abbreviation: 'WY',
	},
];
