import axios from "axios";
import { Auth } from "aws-amplify";
import { baseUrl } from "../constants";

const options = {
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};

async function prepareRequest() {
  const amplifyToken = await Auth.currentSession();
  const accessToken = amplifyToken.getAccessToken().getJwtToken();
  return {
    ...options,
    headers: {
      ...options.headers,
      Authorization: `Bearer ${accessToken}`,
    },
  };
}

/**
 *
 * @param {*} url
 * @param {*} params
 */
async function request(_request) {
  try {
    const preparedOptions = await prepareRequest();
    const response = await axios({
      ..._request,
      ...preparedOptions,
    });

    return response.data;
  } catch (error) {
    let response = error.response;

    if (response.status >= 400 && response.status < 500) {
      throw response.data;
    }

    response = { error: { message: response.statusText } };

    throw response;
  }
}

/**
 *
 * @param {*} request
 */
function get(params) {
  let url = `${baseUrl}${params.url}`;
  return request({
    ...params,
    method: "GET",
    url,
  });
}

async function downloadErx(data) {
  try {
    const linkSource = `data:application/pdf;base64,${data}`;
    const downloadLink = document.createElement("a");
    const fileName = "BackgroundReport.pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  } catch (err) {
    throw err;
  }
}

async function download({ url }) {
  // const defaultParams = await prepareRequest();
  const fullUrl = url;
  try {
    const download = await axios({
      url: fullUrl,
      method: "GET",
      responseType: "blob", // important,
    });
    const url = window.URL.createObjectURL(new Blob([download.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `OfferLetter.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    URL.revokeObjectURL(url);
  } catch (err) {
    throw err;
  }
}

async function downloadApiDocument({ url, filename }) {
  const defaultParams = await prepareRequest();
  let fullUrl = `${baseUrl}${url}`;
  try {
    const download = await axios({
      url: fullUrl,
      method: "GET",
      headers: {
        Authorization: defaultParams.headers.Authorization,
      },
    });

    let link = document.createElement("a"); //Create <a>
    link.href = "data:application/pdf;base64," + download.data; //pdf Base64 Goes here
    link.download = `${filename}.pdf`; //File name Here
    link.click(); //Downloaded file
    link.remove();
    URL.revokeObjectURL(link);
  } catch (err) {
    throw err;
  }
}

/**
 *
 * @param {*} request
 */
function post({ url, body }) {
  url = `${baseUrl}${url}`;
  return request({
    method: "POST",
    url,
    data: body,
  });
}

export default {
  get,
  post,
  download,
  downloadErx,
  downloadApiDocument
};
