import React, { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Typography, Grid, CircularProgress, Box } from "@material-ui/core";
import CommunicationForm from "forms/Communication";
import { fetchCommunication } from "store/actions/communication";

const EditCommunication = (props) => {
  const { fetchCommunication, communication } = props;
  const { id } = useParams();

  useEffect(() => {
    fetchCommunication(id);
  }, [fetchCommunication]);

  return (
    <Fragment>
      {communication && communication.loading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />{" "}
        </Box>
      )}
      {communication && !communication.loading && communication.data && (
        <Grid
          container
          spacing={3}
          display="flex"
          justify="center"
          alignItems="left"
        >
          <Grid item xs={12}>
            <Typography variant="h4">Edit Communication</Typography>
          </Grid>
          <Grid item xs={12}>
            <CommunicationForm
              communicationData={communication.data}
              reloadDataFetch={() => fetchCommunication(id)}
            />
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

export default connect(
  (state) => ({
    communication: state.communication.communication,
  }),
  (dispatch) => ({
    fetchCommunication: (id) => dispatch(fetchCommunication(id)),
  })
)(EditCommunication);
