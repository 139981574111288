import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "styles/components/Chart";
import CircularProgress from "./CircularProgress";
import {
  Typography,
  Card,
  CardContent
} from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function Stats(props) {
  const classes = useStyles();

  const { title, value, isLoading } = props;
  return (
    <Card className={classes.root}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          <Typography variant="body1" color="textSecondary" >
            {isLoading ? <CircularProgress /> : value }
          </Typography>
        </CardContent>
    </Card>
  );
}
