export default (theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },

  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
  },
  cardActions: {
    flexDirection: "column",
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
    paddingTop: "10px",
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  submitCode: {
    color: "#ffffff",
    backgroundColor: "#ff9900",
    "&:hover": {
      backgroundColor: "#ffac31",
    },
  },
  cardMain: {
    display: "flex",
    maxWidth: "450px",
    justifyContent: "center",
    margin: "auto",
  },
});
