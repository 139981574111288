import request from "helpers/request";

import { fetchStart, fetchSuccess, fetchFail } from "./api";
import axios from "axios";

const actions = {
  LOGIN: {
    type: "LOGIN",
    url: "auth",
  },
  LOGOUT: {
    type: "LOGOUT",
    url: "logout",
  },
};

export const getAccessToken = () => {
  return window.localStorage.getItem("deal_source_token");
};

/**
 *
 * @param {*} param0
 */
export const loginWithToken = () => (dispatch, getState) => {
  //   const action = actions.LOGIN;
  //   const state = getState();
  //   if (state.auth.user && state.auth.user.data) {
  //     return undefined;
  //   }
  //   // dispatch(fetchStart(action.type));
  //   return request
  //     .post({ url: "auth/access-token", body: { accessToken: getAccessToken() } })
  //     .then(data => {
  //       dispatch(fetchSuccess(data, action.type));
  //     })
  //     .catch(err => {
  //       // dispatch(fetchFail(err, action.type));
  //       throw err;
  //     });
};

/**
 *
 * @param {*} param0
 */
export const loginAction = ({ email = "", password = "", force = false }) => (
  dispatch,
  getState
) => {
  const action = actions.LOGIN;
  const state = getState();

  if (!force && state.auth.user && state.auth.user.data) {
    return undefined;
  }

  dispatch(fetchStart(action.type));
  return request
    .post({ url: action.url, body: { email, password } })
    .then((data) => {
      dispatch(fetchSuccess(data, action.type));
    })
    .catch((err) => {
      dispatch(fetchFail(err, action.type));

      throw err;
    });
};

/**
 *
 */
export const logoutAction = () => {
  localStorage.removeItem("deal_source_token");
  delete axios.defaults.headers.common["Authorization"];
  return true;
};

export default actions;
