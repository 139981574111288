import { Auth } from "aws-amplify";
import {
  fetchStart,
  fetchSuccess,
  fetchFail,
  fetchAction,
  getQueryString,
  listUsers
} from "./api";

const actions = {
  FETCH_USER_DATA: {
    type: "FETCH_USER_DATA",
    url: "user",
    key: "user",
  },
  FETCH_PCR_USERS: {
    type: "FETCH_PCR_USERS",
    url: "pcrUsers",
    key: "pcrUsers",
  },
  FETCH_ALL_APPLICANTS: {
    type: "FETCH_ALL_APPLICANTS",
    url: "applicants",
    key: "applicants",
  },
  FETCH_APPLICANT: {
    type: "FETCH_APPLICANT",
    url: "applicant",
    key: "applicant",
  },
  FETCH_APPLICANT_REPORT: {
    type: "FETCH_APPLICANT_REPORT",
    url: "applicantReport",
    key: "applicantReport",
  },
  FETCH_APPLICANT_ERXREPORT: {
    type: "FETCH_APPLICANT_REPORT",
    url: "applicantErxReport",
    key: "applicantErxReport",
  },
  FETCH_ALL_ADMIN_USERS: {
    type: "FETCH_ALL_ADMIN_USERS",
    url: "adminUsers",
    key: "adminUsers",
  },

  FETCH_USER_ATTRIBUTES: {
    type: "FETCH_USER_ATTRIBUTES",
    url: "userAttributes",
    key: "userAttributes",
  },
};
/**
 *
 * @param {*} action
 */
const request = ({ action, force = false }) => (dispatch, getState) => {
  const state = getState();

  if (!force && state.user[action.key] && state.user[action.key].data) {
    return undefined;
  }

  dispatch(fetchStart(action));
  return Auth.currentUserInfo()
    .then((data) => dispatch(fetchSuccess(data, action)))
    .catch((err) => dispatch(fetchFail(err, action)));
};

export const fetchUserData = () => {
  return request({ action: actions.FETCH_USER_DATA });
};

export const fetchPcrUsers = (params) => {
  return fetchAction(
    actions.FETCH_PCR_USERS,
    `pcrUsers?${getQueryString(params)}`
  );
};

export const fetchAllApplicants = (params) => {
  return fetchAction(
    actions.FETCH_ALL_APPLICANTS,
    `applicants?${getQueryString(params)}`
  );
};

export const fetchApplicant = (id) => {
  return fetchAction(
    actions.FETCH_APPLICANT,
    `applicants/${id}`
  );
};

export const fetchApplicantReport = (id, folder) => {
  return fetchAction(
    actions.FETCH_APPLICANT_REPORT,
    `offerLetter/${id}/${folder}/letter`
  );
};

export const fetchApplicantErxReport = (id) => {
  return fetchAction(
    actions.FETCH_APPLICANT_ERXREPORT,
    `orders/${id}/erx/report`
  );
};

export const fetchAllAdminUsers = () => {
  return fetchAction(
    actions.FETCH_ALL_ADMIN_USERS,
    `users`
  );
};

export const fetchUserAttributes = (username) => {
  return fetchAction(
    actions.FETCH_USER_ATTRIBUTES,
    `users/attributes/${username}`
  );
};

export default actions;
