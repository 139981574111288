import React, { Fragment, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import {
	Box,
	Paper,
	TextField,
	LinearProgress,
	Grid,
	Button,
	MenuItem,
} from '@material-ui/core';
import FlexGrid from 'components/FlexGrid';
import request from 'helpers/request';
import { states } from 'constants/constants';

import styles from 'styles/forms/Company';

const useStyles = makeStyles(styles);

const SourceDataDoc = (props) => {
	const classes = useStyles();
	const { applicantData, setSnackBar, snackBar } = props;

	console.log('from my files', applicantData.application);
	const fields1 = {
		firstName: 'First Name',
		lastName: 'Last Name',
		email: 'Email',
		phone: 'Phone',
		address: 'Address',
	};

	const fields2 = {
		city: 'City',
		zip: 'Zip',
	};

	const renderApplication = Object.entries(applicantData.application).map(
		([key, value]) => {
			return (
				<Grid item xs={4} className="my-col">
					{' '}
					<b>{key} : </b>
					<p> {value.toString()}</p>{' '}
				</Grid>
			);
		}
	);

	const renderBackground = Object.entries(applicantData.background).map(
		([key, value]) => {
			return (
				<Grid item xs={4} className="my-col">
					{' '}
					<b>{key} : </b>
					<p> {value.toString()}</p>{' '}
				</Grid>
			);
		}
	);

	function capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	//   console.log(capitalizeFirstLetter('foo')); // Foo

	const myTitle = (a) => {
		let capitalText = capitalizeFirstLetter(a);
		let newKey = capitalText.match(/[A-Z][a-z]+|[0-9]+/g).join(' ');
		return newKey;
	};

	const renderGeneral = () => {
		return (
			<Grid container spacing={3} className="SD-main-container">
				<Grid item xs={6}>
					<b>First Name : </b>
					<p> {applicantData.application.firstName} </p>{' '}
				</Grid>
				<Grid item xs={6}>
					<p> {applicantData.application.lastName} </p>{' '}
				</Grid>
			</Grid>
		);
	};

	const renderForm = () => {
		return (
			<div className="SD-parent">
				<h1> Applicant Submission </h1>
				<br />
				<h2> Application </h2>
				<Grid container spacing={3} className="SD-main-container">
					{renderApplication}
				</Grid>

				<br />
				<br />
				<h2> Background </h2>

				<Grid container spacing={3} className="SD-main-container">
					{renderBackground}
				</Grid>
			</div>
		);
	};

	return (
		<Fragment>
			<Paper className={classes.root}> {renderForm()}</Paper>
		</Fragment>
	);
};

export default SourceDataDoc;
