import React, { Fragment, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Paper,
  TextField,
  LinearProgress,
  Grid,
  Button,
} from "@material-ui/core";
import FlexGrid from "components/FlexGrid";
import SnackBar from "components/SnackBar";
import request from "helpers/request";

import styles from "styles/forms/Company";

const useStyles = makeStyles(styles);

const validationSchema = Yup.object({
  email: Yup.string().required("Email Domain is required"),
});

const Products = (props) => {
  const classes = useStyles();
  const { companyData } = props;
  const [snackBar, setSnackBar] = useState({ isOpen: false, type: "" });

  const getInitialValue = (data, key, defaultValue) => {
    if (data && data[key]) {
      return data[key];
    }
    return defaultValue;
  };

  const submitForm = async (values, resetForm) => {
    try {
      const { ...finalPayload } = {
        ...values,
      };
      if (companyData) {
        await request.post({
          url: `company/${companyData.id}/update`,
          body: finalPayload,
        });
      } else {
        await request.post({
          url: `company`,
          body: finalPayload,
        });
        resetForm();
      }
      setSnackBar({ ...snackBar, isOpen: true, type: "success" });
    } catch (error) {
      setSnackBar({ ...snackBar, isOpen: true, type: "error" });
    }
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          email: getInitialValue(companyData, "email", ""),
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          submitForm(values, resetForm).then(() => {
            setSubmitting(false);
          });
        }}
        render={({
          submitForm,
          isSubmitting,
          setFieldValue,
          values,
          errors,
          touched,
        }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <Box mx={3} mt={3}>
                  <FlexGrid columns={3}>
                    <Box pt={3}>
                      <TextField
                        label="Email"
                        name="email"
                        value={values.email}
                        variant="outlined"
                        fullWidth
                        error={errors.email && touched.email}
                        helperText={touched.email ? errors.email : ""}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) =>
                          setFieldValue("email", e.target.value)
                        }
                      />
                    </Box>
                  </FlexGrid>
                </Box>
              </Grid>
              {isSubmitting && (
                <Grid item xs={12} lg={12}>
                  <Box mx={3}>
                    <LinearProgress />
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} lg={12}>
                <Box ml={3} mb={3}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={submitForm}
                    className={classes.button}
                  >
                    Submit
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      />
    );
  };

  return (
    <Fragment>
      <SnackBar
        snackBar={snackBar}
        type={snackBar.type}
        setSnackBar={setSnackBar}
      />
      <Paper className={classes.root}> {renderForm()}</Paper>
    </Fragment>
  );
};

export default Products;
