import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Typography, Grid, Box, Tooltip, IconButton } from "@material-ui/core";
import { fetchAllCommunications } from "store/actions/communication";
import Table from "components/Table";
import SnackBar from "components/SnackBar";
import { makeStyles } from "@material-ui/core/styles";
import { AddBox, Edit } from "@material-ui/icons";
import styles from "styles/pages/Company";

const useStyles = makeStyles(styles);

const pageInitState = {
  page: 1,
};

const Communication = (props) => {
  const { fetchAllCommunications, communications } = props;
  const [pagination, setPagination] = useState(pageInitState);
  const [snackBar, setSnackBar] = useState({ isOpen: false, type: "" });

  const classes = useStyles();

  useEffect(() => {
    fetchAllCommunications(pagination);
  }, [fetchAllCommunications, pagination]);

  const handleChangePage = (event, newPage) => {
    setPagination({ page: newPage + 1 });
  };

  const meta = [
    {
      label: "Id",
      render: (row) => <Typography variant={"body2"}>{row.id}</Typography>,
    },
    {
      label: "Title",
      render: (row) => <Typography variant={"body2"}>{row.title}</Typography>,
    },
    {
      label: "Message",
      render: (row) => <Typography variant={"body2"}>{row.message}</Typography>,
    },
    {
        label: "Action",
        render: (row) => (
          <>
            <Tooltip title="Edit Communication">
              <Link
                to={`/communication/edit/${row.id}`}
                style={{ textDecoration: "none" }}
              >
                <IconButton color="inherit" size="small">
                  <Edit fontSize="small" color="secondary" />
                </IconButton>
              </Link>
            </Tooltip>
          </>
        ),
      },
  ];

  const addNewProduct = () => {
    return (
      <Tooltip title="Add new Communication">
        <Link to={`/communication/add`} style={{ textDecoration: "none" }}>
          <IconButton color="inherit" size="large">
            <AddBox fontSize="large" color="secondary" />
          </IconButton>
        </Link>
      </Tooltip>
    );
  };

  return (
    <Fragment>
      <SnackBar
        snackBar={snackBar}
        type={snackBar.type}
        setSnackBar={setSnackBar}
      />
      <Grid container>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pb={3}
          >
            <Typography variant="h6">Communication</Typography>
            {addNewProduct()}
          </Box>
        </Grid>
        <Grid item xs={12}>
          {/* {renderBreadCrumbs()} */}
        </Grid>
        <Grid item xs={12}>
          <Table
            meta={meta}
            size="medium"
            data={communications.data && communications.data.data}
            loading={communications.loading}
            errorMessage={communications.error && communications.error.message}
            withPagination
            currentPage={communications.data && communications.data.currentPage}
            rowsPerPage={communications.data && communications.data.perPage}
            totalRowsCount={communications.data && communications.data.total}
            onPageChange={handleChangePage}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default connect(
  (state) => ({
    communications: state.communication.communications,
  }),
  (dispatch) => ({
    fetchAllCommunications: () => dispatch(fetchAllCommunications()),
  })
)(Communication);
