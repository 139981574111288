import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	Typography,
	Grid,
	Box,
	Tooltip,
	IconButton,
	Breadcrumbs,
	Button,
	Divider,
} from '@material-ui/core';
import { fetchAllCompanies } from 'store/actions/company';
import Sidebar from './Applicants/components/ApplicantInfo/Sidebar';
import SnackBar from 'components/SnackBar';
import { makeStyles } from '@material-ui/core/styles';
import { AddBox } from '@material-ui/icons';
import styles from 'styles/pages/ApplicantInfo';

const useStyles = makeStyles(styles);

const pageInitState = {
	page: 1,
};

const ApplicantInfo = (props) => {
	const { fetchAllCompanies, companies } = props;
	const [pagination, setPagination] = useState(pageInitState);
	const [snackBar, setSnackBar] = useState({ isOpen: false, type: '' });

	const classes = useStyles();

	//   useEffect(() => {
	//     fetchAllCompanies(pagination);
	//   }, [fetchAllCompanies, pagination]);

	const handleChangePage = (event, newPage) => {
		setPagination({ page: newPage + 1 });
	};

	const meta = [
		{
			label: 'Email',
			render: (row) => <Typography variant={'body2'}>{row.email}</Typography>,
		},
	];

	const addNewProduct = () => {
		return (
			<Tooltip title="Add new Company">
				<Link to={`/company/add`} style={{ textDecoration: 'none' }}>
					<IconButton color="inherit" size="large">
						<AddBox fontSize="large" color="secondary" />
					</IconButton>
				</Link>
			</Tooltip>
		);
	};

	const renderBreadCrumbs = () => {
		return (
			<Breadcrumbs separator="›" aria-label="breadcrumb">
				<Link to="/applicants" style={{ textDecoration: 'none' }}>
					<Typography color="textPrimary">Applicants</Typography>
				</Link>
				<Typography color="textPrimary">Tony Martin</Typography>
			</Breadcrumbs>
		);
	};

	const renderPositionInfo = () => {
		return (
			<>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					width="85%"
				>
					<Box pb={2} pt={2}>
						<Typography variant="subtitle2" className={classes.subHeading}>
							Position Info
						</Typography>
					</Box>
					<Box pb={2} pt={2}>
						<Button
							variant="contained"
							color="inherit"
							size="small"
							className={classes.button}
						>
							Edit
						</Button>
					</Box>
				</Box>
				<Box
					display="flex"
					alignItems="baseline"
					justifyContent="space-between"
					width={3 / 4}
				>
					{/* left side */}
					<Box>
						<Box>
							<Typography variant="caption" className={classes.subHeading}>
								Position:
							</Typography>
						</Box>
						<Box pb={1}>
							<Typography variant="caption">Processor</Typography>
						</Box>

						<Box>
							<Typography variant="caption" className={classes.subHeading}>
								Position Employment Status:
							</Typography>
						</Box>
						<Box pb={1}>
							<Typography variant="caption">Full Time</Typography>
						</Box>

						<Box>
							<Typography variant="caption" className={classes.subHeading}>
								Vacation Days:
							</Typography>
						</Box>
						<Box pb={3}>
							<Typography variant="caption">Three (3) Days</Typography>
						</Box>

						<Box>
							<Typography variant="caption" className={classes.subHeading}>
								Position Start Date:
							</Typography>
						</Box>
						<Box pb={2}>
							<Typography variant="caption">10/14/2021</Typography>
						</Box>

						<Box>
							<Typography variant="caption" className={classes.subHeading}>
								Pay Amount:
							</Typography>
						</Box>
						<Box pb={2}>
							<Typography variant="caption">$ 30000</Typography>
						</Box>
					</Box>
					{/* right side */}
					<Box>
						<Box>
							<Typography variant="caption" className={classes.subHeading}>
								Department:
							</Typography>
						</Box>
						<Box pb={1}>
							<Typography variant="caption">Finance</Typography>
						</Box>

						<Box>
							<Typography variant="caption" className={classes.subHeading}>
								Bonus/Comission:
							</Typography>
						</Box>
						<Box pb={1}>
							<Typography variant="caption">Bonus</Typography>
						</Box>

						<Box>
							<Typography variant="caption" className={classes.subHeading}>
								Exempt/Non-Exempt Status:
							</Typography>
						</Box>
						<Box pb={2}>
							<Typography variant="caption">Exempt</Typography>
						</Box>

						<Box>
							<Typography variant="caption" className={classes.subHeading}>
								Pay Type:
							</Typography>
						</Box>
						<Box pb={2}>
							<Typography variant="caption">Salary</Typography>
						</Box>
					</Box>
				</Box>
			</>
		);
	};

	const renderReportsSection = () => {
		return (
			<>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					width="85%"
				>
					<Box pb={1} pt={2}>
						<Typography variant="caption" className={classes.subHeading}>
							Reports To:
						</Typography>
					</Box>
					<Box pb={1} pt={2}>
						<Typography variant="caption" className={classes.subHeading}>
							Location:
						</Typography>
					</Box>
					<Box pb={1} pt={2}>
						<Button
							variant="contained"
							color="inherit"
							size="small"
							className={classes.button}
						>
							Edit
						</Button>
					</Box>
				</Box>
				<Box
					display="flex"
					alignItems="baseline"
					justifyContent="space-between"
					width="56%"
				>
					<Box>
						<Box>
							<Typography variant="caption">Carl Smith</Typography>
						</Box>
						<Box>
							<Typography variant="caption">Chief Revenue Officer</Typography>
						</Box>
					</Box>
					<Box>
						<Box>
							<Typography variant="caption">1011 Bayside Dr</Typography>
						</Box>
						<Box>
							<Typography variant="caption">Suite 270</Typography>
						</Box>
						<Box>
							<Typography variant="caption">Corona Del Mer, CA</Typography>
						</Box>
						<Box>
							<Typography variant="caption">92625</Typography>
						</Box>
					</Box>
				</Box>
			</>
		);
	};

	const renderApplicantInfo = () => {
		return (
			<>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					width="85%"
				>
					<Box pb={2} pt={3}>
						<Typography variant="subtitle2" className={classes.subHeading}>
							Personal Info
						</Typography>
					</Box>
					<Box pb={2} pt={3}>
						<Button
							variant="contained"
							color="inherit"
							size="small"
							className={classes.button}
						>
							Edit
						</Button>
					</Box>
				</Box>
				<Box
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					width={2 / 4}
				>
					<Box>
						<Box>
							<Typography variant="caption">Tony Martin</Typography>
						</Box>
						<Box>
							<Typography variant="caption">tony@gmail.com</Typography>
						</Box>
						<Box>
							<Typography variant="caption">(123) 22-1234</Typography>
						</Box>
					</Box>
					<Box>
						<Box>
							<Typography variant="caption">123 road</Typography>
						</Box>
						<Box>
							<Typography variant="caption">Glendale, CA</Typography>
						</Box>
						<Box>
							<Typography variant="caption">92134</Typography>
						</Box>
					</Box>
				</Box>
			</>
		);
	};

	return (
		<Fragment>
			<SnackBar
				snackBar={snackBar}
				type={snackBar.type}
				setSnackBar={setSnackBar}
			/>
			<Grid container>
				<Grid item xs={8}>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						pl={3}
					>
						{renderBreadCrumbs()}
					</Box>
					<Box pb={2} pl={3}>
						{renderApplicantInfo()}
					</Box>
					<Box px={4}>
						<Divider />
					</Box>
					<Box pb={2} pl={3}>
						{renderPositionInfo()}
					</Box>

					<Box px={4}>
						<Divider />
					</Box>
					<Box pb={2} pl={3}>
						{renderReportsSection()}
					</Box>
				</Grid>
				<Grid
					item
					xs={4}
					className={classes.sidebar + ' sidebar-main-container'}
				>
					<Sidebar />
				</Grid>
			</Grid>
		</Fragment>
	);
};

export default connect(
	(state) => ({
		companies: state.company.company,
	}),
	(dispatch) => ({
		fetchAllCompanies: (params) => dispatch(fetchAllCompanies(params)),
	})
)(ApplicantInfo);
