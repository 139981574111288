import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
	Typography,
	Grid,
	Box,
	Dialog,
	DialogTitle,
	DialogContent,
	Tooltip,
	IconButton,
} from '@material-ui/core';
import { fetchAllApplicants } from 'store/actions/user';
import { fetchAllOrders } from 'store/actions/orders';
import Table from 'components/Table';
import SnackBar from 'components/SnackBar';
import { makeStyles } from '@material-ui/core/styles';
import { Cancel } from '@material-ui/icons';
import styles from 'styles/pages/Orders';
import OrderBackgroundCheck from './Orders/OrderBackgroundCheck';
import CancelOrder from './Orders/CancelOrder';

const useStyles = makeStyles(styles);

const pageInitState = {
	page: 1,
};

const Orders = (props) => {
	const { fetchAllApplicants, applicants, fetchAllOrders, orders } = props;
	const [pagination, setPagination] = useState(pageInitState);
	const [open, setOpen] = useState({ isOpen: false, type: '' });
	const [snackBar, setSnackBar] = useState({ isOpen: false, type: '' });
	const [dialogData, setdialogData] = useState('');
	console.log('orders', orders);

	const classes = useStyles();

	useEffect(() => {
		fetchAllApplicants(pagination);
	}, [fetchAllApplicants, pagination]);

	useEffect(() => {
		fetchAllOrders(pagination);
	}, [fetchAllOrders, pagination]);

	const handleChangePage = (event, newPage) => {
		setPagination({ page: newPage + 1 });
	};

	const closeDialog = () => {
		setOpen({ ...open, isOpen: false, type: '' });
	};

	const closeDialogSuccess = () => {
		setOpen({ ...open, isOpen: false, type: '' });
		fetchAllOrders(pagination);
	};

	const renderDialogContent = () => {
		switch (open.type) {
			case 'backgroundCheck':
				return (
					<OrderBackgroundCheck
						handleClose={() => closeDialogSuccess()}
						setSnackBar={setSnackBar}
						snackBar={snackBar}
						data={dialogData}
					/>
				);
			case 'cancelOrder':
				return (
					<CancelOrder
						handleClose={() => closeDialogSuccess()}
						setSnackBar={setSnackBar}
						snackBar={snackBar}
						data={dialogData}
					/>
				);
			default:
				return;
		}
	};

	const dialogBox = () => {
		return (
			<Dialog
				open={open.isOpen}
				fullWidth={true}
				onClose={closeDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">Cancel Order</DialogTitle>
				<DialogContent>{renderDialogContent()}</DialogContent>
			</Dialog>
		);
	};

	const meta = [
		{
			label: 'ID',
			render: (row) => <Typography variant={'body2'}>{row.id}</Typography>,
		},
		{
			label: 'Products',
			render: (row) => (
				<Typography variant={'body2'} className="limit-width">
					{row.products?.map((e) => e.description).join(', ')}
				</Typography>
			),
		},
		{
			label: 'First Name',
			render: (row) => (
				<Typography variant={'body2'}>
					{applicants?.data?.data.find(
						(applicant) => applicant.id === row.applicantId
					)?.firstName ?? ''}
				</Typography>
			),
		},
		{
			label: 'Last Name',
			render: (row) => (
				<Typography variant={'body2'}>
					{applicants?.data?.data.find(
						(applicant) => applicant.id === row.applicantId
					)?.lastName ?? ''}
				</Typography>
			),
		},
		{
			label: 'Email',
			render: (row) => (
				<Typography variant={'body2'}>
					{applicants?.data?.data.find(
						(applicant) => applicant.id === row.applicantId
					)?.email ?? ''}
				</Typography>
			),
		},
		{
			label: 'Action',
			render: (row) => (
				<Tooltip title="Cancel Order">
					<IconButton
						color="inherit"
						size="small"
						disabled={!row.applicant?.erxId}
						onClick={() => {
							setOpen({ isOpen: true, type: 'cancelOrder' });
							setdialogData(row);
						}}
					>
						<Cancel
							fontSize="small"
							color={!row.applicant?.erxId ? 'inherit' : 'secondary'}
						/>
					</IconButton>
				</Tooltip>
			),
		},
	];

	return (
		<Fragment>
			<SnackBar
				snackBar={snackBar}
				type={snackBar.type}
				setSnackBar={setSnackBar}
			/>
			{dialogBox()}
			<Grid container className="main-container">
				<Grid item xs={10}>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						pb={3}
					>
						<Typography variant="h6">Orders</Typography>
						{/* {addNewAccount()} */}
					</Box>
				</Grid>
				<Grid item xs={12}>
					{/* {renderBreadCrumbs()} */}
				</Grid>
				<Grid item xs={12}>
					<Table
						meta={meta}
						size="medium"
						data={orders.data && orders.data.data}
						loading={orders.loading}
						errorMessage={orders.error && orders.error.message}
						withPagination
						currentPage={orders.data && orders.data.currentPage}
						rowsPerPage={orders.data && orders.data.perPage}
						totalRowsCount={orders.data && orders.data.total}
						onPageChange={handleChangePage}
					/>
				</Grid>
			</Grid>
		</Fragment>
	);
};

export default connect(
	(state) => ({
		applicants: state.user.applicants,
		orders: state.orders.orders,
	}),
	(dispatch) => ({
		fetchAllApplicants: (params) => dispatch(fetchAllApplicants(params)),
		fetchAllOrders: (params) => dispatch(fetchAllOrders(params)),
	})
)(Orders);
