import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Typography,
	Grid,
	Tooltip,
	IconButton,
	Breadcrumbs,
	Box,
} from '@material-ui/core';
import { SupervisedUserCircle, Delete, Edit } from '@material-ui/icons';
import { fetchAllAdminUsers } from 'store/actions/user';
import Table from 'components/Table';
import ChangeRole from './Users/ChangeRole';
import DeleteUser from './Users/DeleteUser';
import UpdateUser from './Users/UpdateUser';
import { ReactComponent as TrashIcon } from '../assets/Icons/icon_trash.svg';
import { ReactComponent as AgentIcon } from '../assets/Icons/icon_agentCommunication.svg';
import { ReactComponent as EditIcon } from '../assets/Icons/icon_edit.svg';

const Users = (props) => {
	const pageInitState = {
		limit: 25,
		nextToken: '',
	};
	const [pagination, setPagination] = useState(pageInitState);
	const [open, setOpen] = useState(false);
	const [dialogData, setDialogData] = useState('');

	const { adminUsers, fetchAllAdminUsers } = props;

	useEffect(() => {
		fetchAllAdminUsers(pagination);
	}, [fetchAllAdminUsers, pagination]);

	const renderDialogContent = () => {
		switch (open.type) {
			case 'updateRole':
				return (
					<ChangeRole
						handleClose={() => handleCloseSuccess()}
						userRole={dialogData.userRole}
						userName={dialogData.userName}
					/>
				);

			case 'deleteUser':
				return (
					<DeleteUser
						handleClose={() => handleCloseSuccess()}
						userRole={dialogData.userRole}
						userName={dialogData.userName}
					/>
				);

			case 'updateUser':
				return (
					<UpdateUser
						handleClose={() => handleCloseSuccess()}
						userName={dialogData.userName}
						userData={dialogData.userData}
					/>
				);
			default:
				return;
		}
	};

	const renderDialogTitle = () => {
		switch (open.type) {
			case 'updateRole':
				return 'Update User Role';

			case 'deleteUser':
				return 'Delete User';

			case 'updateUser':
				return 'Update User';
			default:
				return;
		}
	};

	const meta = [
		{
			label: 'Name',
			render: (row) => (
				<Typography variant={'subtitle1'}>
					{(row.Attributes.find((e) => e.Name === 'custom:firstName')?.[
						'Value'
					] || '') +
						' ' +
						(row.Attributes.find((e) => e.Name === 'custom:lastName')?.[
							'Value'
						] || '')}
				</Typography>
			),
		},
		{
			label: 'Email',
			render: (row) => (
				<Typography variant={'subtitle1'}>
					{row.Attributes.find((e) => e.Name === 'email')?.['Value']}
				</Typography>
			),
		},
		{
			label: 'Phone',
			render: (row) => (
				<Typography variant={'subtitle1'}>
					{row.Attributes.find((e) => e.Name === 'phone_number')?.['Value']}
				</Typography>
			),
		},
		{
			label: 'Role',
			render: (row) => (
				<Typography variant={'subtitle1'}>{row.role}</Typography>
			),
		},
		{
			label: 'Update Role',
			render: (row) => (
				<Tooltip title="Update User Role">
					<IconButton
						color="inherit"
						size="small"
						onClick={() => {
							setOpen({ isOpen: true, type: 'updateRole' });
							setDialogData({ userName: row.Username, userRole: row.role });
						}}
					>
						<AgentIcon className="icon-size" />
					</IconButton>
				</Tooltip>
			),
		},
		{
			label: 'User Attributes',
			render: (row) => (
				<Tooltip title="Update User Attributes">
					<IconButton
						color="inherit"
						size="small"
						onClick={() => {
							setOpen({ isOpen: true, type: 'updateUser' });
							setDialogData({
								userName: row.Username,
								userData: row.Attributes,
							});
						}}
					>
						<EditIcon fontSize="small" className="icon-size" />
					</IconButton>
				</Tooltip>
			),
		},
		{
			label: 'Delete User',
			render: (row) => (
				<Tooltip title="Delete User">
					<IconButton
						color="inherit"
						size="small"
						onClick={() => {
							setOpen({ isOpen: true, type: 'deleteUser' });
							setDialogData({ userName: row.Username, userRole: row.role });
						}}
					>
						<TrashIcon fontSize="small" className="icon-size" />
					</IconButton>
				</Tooltip>
			),
		},
	];

	const handleCloseSuccess = () => {
		setOpen({ ...open, isOpen: false, type: '' });
		fetchAllAdminUsers(pagination);
	};

	const closeDialog = () => {
		setOpen({ ...open, isOpen: false, type: '' });
	};

	const dialogBox = () => {
		return (
			<Dialog
				open={open.isOpen}
				fullWidth={true}
				onClose={closeDialog}
				maxWidth={open.type === 'updateUser' ? 'md' : 'sm'}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{renderDialogTitle()}</DialogTitle>
				<DialogContent>{renderDialogContent()}</DialogContent>
			</Dialog>
		);
	};

	return (
		<Fragment>
			{dialogBox()}
			<Grid
				container
				// spacing={3}
				// display="flex"
				// justify="center"
				// alignItems="left"
				className="main-container"
			>
				<Grid item xs={12}>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						pb={3}
					>
						<Typography variant="h6">Users</Typography>
					</Box>
				</Grid>
				{/* <Grid item xs={10}>
          <Typography variant="h4">Users</Typography>
        </Grid>
        <Grid item xs={10}>
          {renderBreadCrumbs()}
        </Grid> */}
				<Grid item xs={12}>
					<Table
						meta={meta}
						data={adminUsers.data && adminUsers.data}
						loading={adminUsers.loading}
						errorMessage={adminUsers.error && adminUsers.error.message}
					/>
				</Grid>
			</Grid>
		</Fragment>
	);
};

export default connect(
	(state) => ({
		adminUsers: state.user.adminUsers,
	}),
	(dispatch) => ({
		fetchAllAdminUsers: (params) => dispatch(fetchAllAdminUsers(params)),
	})
)(Users);
