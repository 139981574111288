import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
	Typography,
	Grid,
	Box,
	Dialog,
	DialogTitle,
	DialogContent,
	Tooltip,
	IconButton,
	Divider,
} from '@material-ui/core';
import { SaveAlt, Cached } from '@material-ui/icons';
import { fetchPcrUsers } from 'store/actions/user';
import { fetchPcrStats } from 'store/actions/stats';
import Table from 'components/Table';
import { makeStyles } from '@material-ui/core/styles';
import styles from 'styles/pages/PcrUser';
import ConfirmUserMigration from './PcrUser/ConfirmUserMigration';
import SearchUsers from 'components/SearchUsers';
import SnackBar from 'components/SnackBar';
import Stats from 'components/Stats';
import request from 'helpers/request';
import FlexGrid from 'components/FlexGrid';
import { ReactComponent as ActionsIcon } from '../assets/Icons/import.svg';
const useStyles = makeStyles(styles);

const pageInitState = {
	page: 1,
};

const Orders = (props) => {
	const { fetchPcrUsers, pcrUsers, pcrStats, fetchPcrStats } = props;
	const [pagination, setPagination] = useState(pageInitState);
	const [open, setOpen] = useState({ isOpen: false, type: '' });
	const [snackBar, setSnackBar] = useState({ isOpen: false, type: '' });
	const [dialogData, setdialogData] = useState('');

	const classes = useStyles();

	useEffect(() => {
		fetchPcrUsers(pagination);
	}, [fetchPcrUsers, pagination]);

	useEffect(() => {
		fetchPcrStats();
	}, [fetchPcrStats]);

	const handleChangePage = (event, newPage) => {
		setPagination({ ...pagination, page: newPage + 1 });
	};

	const closeDialog = () => {
		setOpen({ ...open, isOpen: false, type: '' });
	};

	const closeDialogSuccess = () => {
		setOpen({ ...open, isOpen: false, type: '' });
		fetchPcrUsers(pagination);
	};

	const manualSyncPcr = async () => {
		try {
			await request.post({
				url: `pcrUsers/sync`,
			});
			setSnackBar({
				...snackBar,
				isOpen: true,
				type: 'success',
				message: 'Manual sync has been initiated',
			});
		} catch (error) {
			setSnackBar({ ...snackBar, isOpen: true, type: 'error' });
		}
	};

	const meta = [
		{
			label: 'First Name',
			render: (row) => (
				<Typography variant={'body2'}>{row.firstName}</Typography>
			),
		},
		{
			label: 'Last Name',
			render: (row) => (
				<Typography variant={'body2'}>{row.lastName}</Typography>
			),
		},
		{
			label: 'Email',
			render: (row) => <Typography variant={'body2'}>{row.email}</Typography>,
		},
		{
			label: 'Address',
			render: (row) => <Typography variant={'body2'}>{row.address}</Typography>,
		},
		{
			label: 'Import',
			render: (row) => (
				<Tooltip title="Import user to Applicants">
					<IconButton
						color="inherit"
						size="small"
						disabled={row.isMoved}
						onClick={() => {
							setOpen({ isOpen: true, type: 'migrate' });
							setdialogData(row);
						}}
					>
						<ActionsIcon
							fontSize="small"
							className={
								row.isMoved
									? 'inherit-color adj-size'
									: 'secondary-color adj-size'
							}
						/>
					</IconButton>
				</Tooltip>
			),
		},
	];

	const renderDialogContent = () => {
		switch (open.type) {
			case 'migrate':
				return (
					<ConfirmUserMigration
						handleClose={() => closeDialogSuccess()}
						setSnackBar={setSnackBar}
						snackBar={snackBar}
						data={dialogData}
					/>
				);
			default:
				return;
		}
	};

	const dialogBox = () => {
		return (
			<Dialog
				open={open.isOpen}
				fullWidth={true}
				onClose={closeDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">Migrate User</DialogTitle>
				<DialogContent>{renderDialogContent()}</DialogContent>
			</Dialog>
		);
	};

	const manualSync = () => {
		return (
			<Tooltip title="Force Sync PCR Users">
				<IconButton
					color="inherit"
					size="large"
					onClick={() => manualSyncPcr()}
				>
					<Cached fontSize="medium" color="secondary" />
				</IconButton>
			</Tooltip>
		);
	};

	return (
		<Fragment className="main-container">
			<div className="main-container">
				<SnackBar
					snackBar={snackBar}
					type={snackBar.type}
					setSnackBar={setSnackBar}
					message={snackBar?.message ?? ''}
				/>
				{dialogBox()}
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<FlexGrid columns={3}>
							<Box>
								<Stats
									title="Total ATS Records"
									value={pcrStats?.data?.pcrUsers ?? 0}
									isLoading={pcrStats.loading}
								/>
							</Box>
							<Box>
								<Stats
									title="Total Applicants"
									value={pcrStats?.data?.applicants ?? 0}
									isLoading={pcrStats.loading}
								/>
							</Box>
							<Box>
								<Stats
									title="Migrated Records"
									value={pcrStats?.data?.migratedUsers ?? 0}
									isLoading={pcrStats.loading}
								/>
							</Box>
						</FlexGrid>
					</Grid>
					{/* </FlexGrid> */}
				</Grid>
				<Grid item xs={12}>
					<Box pt={2}>
						<Divider />
					</Box>
				</Grid>
				<Grid container>
					<Grid item xs={10}>
						<Box
							display="flex"
							// justifyContent="space-between"
							alignItems="center"
							pb={3}
							pt={3}
						>
							<Typography variant="h6">ATS Records</Typography>
							{manualSync()}
						</Box>
					</Grid>
					<Grid item xs={12}>
						<Box pb={3}>
							<SearchUsers
								setPagination={setPagination}
								pagination={pagination}
							/>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<Table
							meta={meta}
							size="medium"
							data={pcrUsers.data && pcrUsers.data.data}
							loading={pcrUsers.loading}
							errorMessage={pcrUsers.error && pcrUsers.error.message}
							withPagination
							currentPage={pcrUsers.data && pcrUsers.data.currentPage}
							rowsPerPage={pcrUsers.data && pcrUsers.data.perPage}
							totalRowsCount={pcrUsers.data && pcrUsers.data.total}
							onPageChange={handleChangePage}
						/>
					</Grid>
				</Grid>
			</div>
		</Fragment>
	);
};

export default connect(
	(state) => ({
		pcrUsers: state.user.pcrUsers,
		pcrStats: state.stats.pcrStats,
	}),
	(dispatch) => ({
		fetchPcrUsers: (params) => dispatch(fetchPcrUsers(params)),
		fetchPcrStats: () => dispatch(fetchPcrStats()),
	})
)(Orders);
