import React, { Fragment } from "react";
import { Typography, Grid } from "@material-ui/core";
import CommunicationForm from "forms/Communication";

const AddCommunication = () => {
  return (
    <Fragment>
      <Grid
        container
        spacing={3}
        display="flex"
        justify="center"
        alignItems="left"
      >
        <Grid item xs={12}>
          <Typography variant="h4">Add Communication</Typography>
        </Grid>
        <Grid item xs={12}>
          <CommunicationForm />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default AddCommunication;
