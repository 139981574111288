import React from 'react';
import clsx from 'clsx';
import { TableCell as MuiTableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import styles from 'styles/components/TableCell';

const useStyles = makeStyles(styles);

const TableCell = (props) => {
	const { children, ...otherProps } = props;
	const { className } = props;
	const classes = useStyles();

	return (
		<MuiTableCell
			{...otherProps}
			align="left"
			className={clsx(classes.cell, className)}
		>
			{children}
		</MuiTableCell>
	);
};

export default TableCell;
