import React from "react";
import {
  Grid,
  Typography,
  Box,
  ListItem,
  ListItemText,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import FlexGrid from "components/FlexGrid";
import ReactJson from 'react-json-view'
import styles from "styles/pages/Orders/OrderBackgroundCheck";

const useStyles = makeStyles(styles);

const ViewSourceData = (props) => {
  const { data } = props;
  const classes = useStyles();

  return (
    <Grid container spacing={3} display="flex" flexDirection="column">
      
       <ReactJson src={data} name="Applicant Submissions" displayDataTypes={false}/>
      {/* <Grid item xs={12} lg={12}>
        {data && data.background && (
          <FlexGrid columns={1}>
            <Typography variant="subtitle1">Background Form Data:</Typography>
            {Object.entries(data.background).map(([key, value]) => {
              return (
                <ListItem key={key}>
                  <ListItemText primary={`${key} : ${value}`} />
                </ListItem>
              );
            })}
          </FlexGrid>
        )}
        <Divider />

        <Box pb={2}></Box>
        {data && data.application && (
          <FlexGrid columns={1}>
            <Typography variant="subtitle1">Application Form Data:</Typography>
            {Object.entries(data.application).map(([key, value]) => {
              return (
                <ListItem key={key}>
                  <ListItemText primary={`${key} : ${value}`} />
                </ListItem>
              );
            })}
          </FlexGrid>
        )} */}
        <Box pb={2}></Box>
      {/* </Grid> */}
    </Grid>
  );
};

export default ViewSourceData;
