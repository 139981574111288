export default (theme) => ({
	button: {
		backgroundColor: theme.color.lightGray,
		color: theme.color.white,
		'&:hover': {
			backgroundColor: theme.color.darkGray,
			color: theme.color.white,
		},
	},
	sidebar: {
		backgroundColor: '#F1F1F1',
		height: '100vh',
	},
	subHeading: {
		fontWeight: 'bold',
		fontFamily: 'Lato',
		fontSize: '14px',
		lineHeight: '25px',
		color: '#000',
	},
});
