import React, { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Typography, Grid, CircularProgress, Box } from "@material-ui/core";
import AdverseForm from "forms/Adverse";
import { fetchApplicant } from "store/actions/user";

const AddAdverse = (props) => {
  const { applicant, fetchApplicant } = props;
  const { applicantId } = useParams();

  useEffect(() => {
    fetchApplicant(applicantId);
  }, [fetchApplicant]);

  return (
    <Fragment>
      {applicant && applicant.loading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />{" "}
        </Box>
      )}
      {applicant && !applicant.loading && applicant.data && (
        <Grid
          container
          spacing={3}
          display="flex"
          justify="center"
          alignItems="left"
        >
          <Grid item xs={12}>
            <Typography variant="h4">Initial Adverse Action Assessment</Typography>
          </Grid>
          <Grid item xs={12}>
            <AdverseForm applicantData={applicant.data} />
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

export default connect(
  (state) => ({
    applicant: state.user.applicant,
  }),
  (dispatch) => ({
    fetchApplicant: (applicantId) => dispatch(fetchApplicant(applicantId)),
  })
)(AddAdverse);
