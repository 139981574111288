import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const CustomizedSnackbars = (props) => {
  const classes = useStyles();
  const { type, snackBar, setSnackBar, message } = props;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBar({ ...snackBar, isOpen: false });
  };

  const messageType = {
    success: "Successful",
    error: "Error",
  };

  return (
    <div className={classes.root}>
      <Snackbar
        open={snackBar.isOpen}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={type}>
          {message || messageType[type]}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CustomizedSnackbars;
