import React from "react";
import { Formik, Form } from "formik";
import {
  LinearProgress,
  Box,
  TextField,
  Button,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import FlexGrid from "components/FlexGrid";
import { Auth } from "aws-amplify";

import styles from "styles/pages/profile/ChangePassword";

const useStyles = makeStyles(styles);

const ChangePassword = (props) => {
  const classes = useStyles();

  const changePassword = async (values) => {
    try {
      if (values.newPassword === values.confirmNewPassword) {
        const currentUser = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(
          currentUser,
          values.oldPassword,
          values.newPassword
        );
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <Box p={3}>
      <Formik
        initialValues={{
          oldPassword: "",
          newPassword: "",
          confirmNewPassword: "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          changePassword(values).then(() => {
            setSubmitting(false);
          });
        }}
        render={({ submitForm, isSubmitting, setFieldValue, values }) => (
          <Form>
            <Grid container spacing={3} display="flex">
              <Grid item xs={12} lg={12}>
                <FlexGrid columns={3}>
                  <Box pt={3}>
                    <TextField
                      label="Old Password"
                      name="oldPassword"
                      type="password"
                      value={values.oldPassword}
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) =>
                        setFieldValue("oldPassword", e.target.value)
                      }
                    />
                  </Box>
                  <Box pt={3}>
                    <TextField
                      label="New Password"
                      name="newPassword"
                      type="password"
                      value={values.newPassword}
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) =>
                        setFieldValue("newPassword", e.target.value)
                      }
                    />
                  </Box>
                  <Box pt={3}>
                    <TextField
                      label="Confirm New Password"
                      name="confirmNewPassword"
                      type="password"
                      value={values.confirmNewPassword}
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) =>
                        setFieldValue("confirmNewPassword", e.target.value)
                      }
                    />
                  </Box>
                </FlexGrid>
              </Grid>
              {isSubmitting && (
                <Grid item xs={12} lg={12}>
                  <LinearProgress />
                </Grid>
              )}
              <Grid item xs={12} lg={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={submitForm}
                  className={classes.button}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      />
    </Box>
  );
};

export default ChangePassword;
