export default theme => ({
    cell: {
        padding: theme.spacing(1.5, 1),

        '&:first-child': {
            paddingLeft: theme.spacing(2),
        },
        '&:last-child': {
            paddingRight: theme.spacing(2),
        },
        '& > svg': {
            verticalAlign: 'middle',
            marginLeft: theme.spacing(0.5),
        },
    },
});
