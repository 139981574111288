import React, { Fragment, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Paper,
  TextField,
  LinearProgress,
  Grid,
  Button,
  MenuItem,
} from "@material-ui/core";
import FlexGrid from "components/FlexGrid";
import request from "helpers/request";
import { states } from "constants/constants";

import styles from "styles/forms/Company";

const useStyles = makeStyles(styles);

const validationSchema = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email().required("Email is required"),
  phone: Yup.string().required("Phone is required"),
  address: Yup.string().required("Address is required"),
  state: Yup.string().required("State is required"),
  city: Yup.string().required("City is required"),
  zip: Yup.string().required("Zip is required"),
});

const PersonalInfo = (props) => {
  const classes = useStyles();
  const { applicantData, setSnackBar, snackBar, handleClose } = props;

  const fields1 = {
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    phone: "Phone",
    address: "Address",
  };

  const fields2 = {
    city: "City",
    zip: "Zip",
  };

  const getInitialValue = (data, key, defaultValue) => {
    if (data && data[key]) {
      return data[key];
    }
    return defaultValue;
  };

  const submitForm = async (values, resetForm) => {
    try {
      const { ...finalPayload } = {
        ...values,
      };
      await request.post({
        url: `applicants/${applicantData.id}/update`,
        body: finalPayload,
      });
      setSnackBar({ ...snackBar, isOpen: true, type: "success" });
      handleClose();
    } catch (error) {
      setSnackBar({ ...snackBar, isOpen: true, type: "error" });
    }
  };

  const renderField = ({
    setFieldValue,
    value,
    errors,
    touched,
    name,
    label,
  }) => {
    return (
      <Box pt={3} key={name}>
        <TextField
          label={label}
          name={name}
          value={value}
          variant="outlined"
          fullWidth
          multiline={name === "address"}
          error={errors[name] && touched[name]}
          helperText={touched[name] ? errors[name] : ""}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setFieldValue(name, e.target.value)}
        />
      </Box>
    );
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          firstName: getInitialValue(applicantData, "firstName", ""),
          lastName: getInitialValue(applicantData, "lastName", ""),
          email: getInitialValue(applicantData, "email", ""),
          phone: getInitialValue(applicantData, "phone", ""),
          address: getInitialValue(applicantData, "address", ""),
          state: getInitialValue(applicantData, "state", ""),
          city: getInitialValue(applicantData, "city", ""),
          zip: getInitialValue(applicantData, "zip", ""),
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          submitForm(values, resetForm).then(() => {
            setSubmitting(false);
          });
        }}
        render={({
          submitForm,
          isSubmitting,
          setFieldValue,
          values,
          errors,
          touched,
        }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <Box mx={3} mt={3}>
                  <FlexGrid columns={3}>
                    {Object.keys(fields1).map((data) => {
                      return renderField({
                        setFieldValue: setFieldValue,
                        value: values[data],
                        errors: errors,
                        touched: touched,
                        name: data,
                        label: fields1[data],
                      });
                    })}
                    <Box pt={3}>
                      <TextField
                        select
                        label="State"
                        name="state"
                        type="text"
                        value={values.state}
                        variant="outlined"
                        error={errors.state && touched.state}
                        helperText={touched.state ? errors.state : ""}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => setFieldValue("state", e.target.value)}
                      >
                        {states.map((val) => (
                          <MenuItem
                            key={val.abbreviation}
                            value={val.abbreviation}
                          >
                            {val.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                    {Object.keys(fields2).map((data) => {
                      return renderField({
                        setFieldValue: setFieldValue,
                        value: values[data],
                        errors: errors,
                        touched: touched,
                        name: data,
                        label: fields2[data],
                      });
                    })}
                  </FlexGrid>
                </Box>
              </Grid>
              {isSubmitting && (
                <Grid item xs={12} lg={12}>
                  <Box mx={3}>
                    <LinearProgress />
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} lg={12}>
                <Box ml={3} mb={3}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={submitForm}
                    className={classes.button}
                  >
                    Submit
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      />
    );
  };

  return (
    <Fragment>
      <Paper className={classes.root}> {renderForm()}</Paper>
    </Fragment>
  );
};

export default PersonalInfo;
