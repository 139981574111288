import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import {
  LinearProgress,
  Box,
  TextField,
  Button,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import FlexGrid from "components/FlexGrid";
import { CircularProgress } from "components";
import { Auth } from "aws-amplify";

import styles from "styles/pages/profile/UserAttributes";

const useStyles = makeStyles(styles);

const userDataInitialState = {
  loading: true,
  data: null,
  error: null,
};

const UserAttributes = () => {
  const classes = useStyles();
  const [userData, setUserData] = useState(userDataInitialState);

  useEffect(() => {
    (async () => {
      const userInfo = await Auth.currentUserInfo();
      setUserData({
        ...userData,
        loading: false,
        data: userInfo,
      });
    })();
    // eslint-disable-next-line
  }, [setUserData]);

  const changeUserAttributes = async (values) => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const updatedData = await Auth.updateUserAttributes(currentUser, {
        email: values.email,
        phone_number: values.phoneNumber,
        "custom:firstName": values.firstName,
        "custom:lastName": values.lastName,
        "custom:workPhone": values.workPhone,
        "custom:title": values.title,
      });
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <>
      {userData.loading && <CircularProgress />}
      {!userData.loading && (
        <Formik
          initialValues={{
            email: userData?.data?.attributes?.email || "",
            phoneNumber: userData?.data?.attributes?.phone_number || "",
            firstName: userData?.data?.attributes?.["custom:firstName"] || "",
            lastName: userData?.data?.attributes?.["custom:lastName"] || "",
            workPhone: userData?.data?.attributes?.["custom:workPhone"] || "",
            title: userData?.data?.attributes?.["custom:title"] || "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            changeUserAttributes(values).then(() => {
              setSubmitting(false);
            });
          }}
          render={({ submitForm, isSubmitting, setFieldValue, values }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <Box px={3}>
                    <FlexGrid columns={3}>
                      <Box pt={3}>
                        <TextField
                          label="Email"
                          name="email"
                          value={values.email}
                          variant="outlined"
                          disabled
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) =>
                            setFieldValue("email", e.target.value)
                          }
                        />
                      </Box>
                      <Box pt={3}>
                        <TextField
                          disabled
                          label="Phone Number"
                          name="phoneNumber"
                          value={values.phoneNumber}
                          variant="outlined"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) =>
                            setFieldValue("phoneNumber", e.target.value)
                          }
                        />
                      </Box>
                      <Box pt={3}>
                        <TextField
                          label="First Name"
                          name="firstName"
                          value={values.firstName}
                          variant="outlined"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) =>
                            setFieldValue("firstName", e.target.value)
                          }
                        />
                      </Box>
                      <Box pt={3}>
                        <TextField
                          label="Last Name"
                          name="lastName"
                          value={values.lastName}
                          variant="outlined"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) =>
                            setFieldValue("lastName", e.target.value)
                          }
                        />
                      </Box>
                      <Box pt={3}>
                        <TextField
                          label="Work Phone"
                          name="workPhone"
                          value={values.workPhone}
                          variant="outlined"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) =>
                            setFieldValue("workPhone", e.target.value)
                          }
                        />
                      </Box>
                      <Box pt={3}>
                        <TextField
                          label="Title"
                          name="title"
                          value={values.title}
                          variant="outlined"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) =>
                            setFieldValue("title", e.target.value)
                          }
                        />
                      </Box>
                    </FlexGrid>
                  </Box>
                </Grid>
                {isSubmitting && (
                  <Grid item xs={12} lg={12}>
                    <LinearProgress />
                  </Grid>
                )}
                <Grid item xs={12} lg={12}>
                  <Box pl={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={submitForm}
                      className={classes.button}
                    >
                      Update
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        />
      )}
    </>
  );
};

export default UserAttributes;
