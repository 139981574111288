import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Grid,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
  Tooltip,
  Button,
  LinearProgress,
} from "@material-ui/core";
// Icons
import EditIcon from "@material-ui/icons/EditOutlined";
import DoneIcon from "@material-ui/icons/DoneAllTwoTone";
import RevertIcon from "@material-ui/icons/NotInterestedOutlined";
import { Delete, AddBox } from "@material-ui/icons";
import styles from "styles/pages/Constants/ViewConstants";
import request from "helpers/request";
import SnackBar from "components/SnackBar";
import { config } from "constants/index";

const useStyles = makeStyles(styles);

const CustomTableCell = ({ row, name, onChange }) => {
  const classes = useStyles();
  const { isEditMode } = row;
  return (
    <TableCell align="left" className={classes.tableCell}>
      {isEditMode ? (
        <Input
          value={row[name]}
          name={name}
          onChange={(e) => onChange(e, row)}
          className={classes.input}
        />
      ) : (
        row[name]
      )}
    </TableCell>
  );
};

const ViewConstants = (props) => {
  const { fetchConstant, constantData, id, data } = props;

  const [rows, setRows] = React.useState(constantData || []);
  const [previous, setPrevious] = React.useState({});
  const [snackBar, setSnackBar] = useState({ isOpen: false, type: "" });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const classes = useStyles();

  const submitForm = async (values) => {
    try {
      setIsSubmitting(true);
      let finalValues = [];
      values.map((val) => {
        finalValues.push({
          name: val.label,
          label: val.label,
        });
      });
      const finalPayload = {
        ...data,
        value: finalValues,
      };
      await request.post({
        url: `constant/${id}/update`,
        body: finalPayload,
      });
      setSnackBar({ ...snackBar, isOpen: true, type: "success" });
      fetchConstant();
      setIsSubmitting(false);
    } catch (error) {
      setSnackBar({ ...snackBar, isOpen: true, type: "error" });
      setIsSubmitting(false);
    }
  };

  const onToggleEditMode = (id) => {
    setRows((state) => {
      return rows.map((row) => {
        if (row.id === id) {
          return { ...row, isEditMode: !row.isEditMode };
        }
        return row;
      });
    });
  };

  const deleteRow = (id) => {
    setRows(() => {
      return rows.filter((row) => row.id !== id);
    });
  };

  const onChange = (e, row) => {
    if (!previous[row.id]) {
      setPrevious((state) => ({ ...state, [row.id]: row }));
    }
    const value = e.target.value;
    const name = e.target.name;
    const { id } = row;
    const newRows = rows.map((row) => {
      if (row.id === id) {
        return { ...row, [name]: value };
      }
      return row;
    });
    setRows(newRows);
  };

  const onRevert = (id) => {
    const newRows = rows.map((row) => {
      if (row.id === id) {
        return previous[id] ? previous[id] : row;
      }
      return row;
    });
    setRows(newRows);
    setPrevious((state) => {
      delete state[id];
      return state;
    });
    onToggleEditMode(id);
  };

  const addNewRow = () => {
    return setRows([
      ...rows,
      {
        id: Math.random().toString(16).slice(2),
        name: "",
        label: "",
        isEditMode: true,
      },
    ]);
  };

  const addNewconstants = () => {
    return (
      <Tooltip title="Add new dropdown value">
        <IconButton color="inherit" size="large" onClick={() => addNewRow()}>
          <AddBox fontSize="md" color="secondary" />
        </IconButton>
      </Tooltip>
    );
  };

  return (
    <Grid item xs={10}>
      <Box display="flex" justifyContent="alignCenter" alignItems="center">
        <Typography variant="h6">{data.name}</Typography>
        {addNewconstants()}
      </Box>
      <Paper className={classes.root}>
        <Table className={classes.table} aria-label="caption table">
          {/* <caption>A barbone structure table example with a caption</caption> */}
          <TableHead>
            <TableRow>
              <TableCell align="left" />
              {/* <TableCell align="left">Name</TableCell> */}
              <TableCell align="left">Label</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell className={classes.selectTableCell}>
                  {row.isEditMode ? (
                    <>
                      <IconButton
                        aria-label="done"
                        onClick={() => onToggleEditMode(row.id)}
                      >
                        <DoneIcon />
                      </IconButton>
                      <IconButton
                        aria-label="revert"
                        onClick={() => onRevert(row.id)}
                      >
                        <RevertIcon />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <IconButton
                        aria-label="delete"
                        onClick={() => onToggleEditMode(row.id)}
                      >
                        <EditIcon />
                      </IconButton>

                      <IconButton
                        aria-label="delete"
                        onClick={() => deleteRow(row.id)}
                      >
                        <Delete />
                      </IconButton>
                    </>
                  )}
                </TableCell>
                {/* <CustomTableCell {...{ row, name: "name", onChange }} /> */}
                <CustomTableCell {...{ row, name: "label", onChange }} />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Box pt={2} />
      {isSubmitting && (
        <Grid item xs={12} lg={12}>
          <Box mb={3}>
            <LinearProgress />
          </Box>
        </Grid>
      )}
      <Grid item xs={12} lg={12}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => submitForm(rows)}
          className={classes.button}
        >
          Update
        </Button>
      </Grid>
      <SnackBar
        snackBar={snackBar}
        type={snackBar.type}
        setSnackBar={setSnackBar}
      />
    </Grid>
  );
};

export default ViewConstants;
