import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import ThemeLoader from "helpers/components/ThemeLoader";
import { BrowserRouter as Router, Route } from "react-router-dom";
// import { Route, Redirect, Router } from "react-router-dom";
import MyAuth from "MyAuth";

const App = (props) => {
  return (
    <div>
      <Router>
        <Route path="/" component={MyAuth} />
      </Router>
    </div>
  );
};
export default App;
//export default App;
