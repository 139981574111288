import React, { Fragment } from "react";
import { Formik, Form } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Paper,
  TextField,
  Typography,
  Grid,
} from "@material-ui/core";
import FlexGrid from "components/FlexGrid";

import styles from "styles/forms/UserSubmission";

const useStyles = makeStyles(styles);

const UserSubmissionForm = (props) => {
  const classes = useStyles();
  const {
    backgroundData,
    applicationData,
    isAdmin
  } = props;

  const fields1 = {
    firstName: "First Name",
    lastName: "Last Name",
    ssn: "SSN",
    dateOfBirth: "DateOfBirth",
    licenseNumber: "LicenseNumber",
    licenseState: "License Issuing State",
    email: "Email",
    phoneNumber: "Phone Number",
  };

  const applicationFields = {
    gradSchoolName: "Grad School Name",
    gradDegree: "Grad Degree",
    gradMajor: "Grad Major",

    highSchoolName: "High School Name",
    highSchoolDegree: "High School Degree",

    employerName: "Employer Name",
    employmentPosition: "Employment Position",
    employmentCity: "Employment City",
    employmentState: "Employment State",

    mailingAddress: "Mailing Address",
    mailingCity: "Mailing City",
    mailingState: "Mailing State",
    mailingZipcode: "Mailing Zipcode",

    reference1: "Reference 1",
    referencePhone1: "Reference Phone 1",
    referenceEmail1: "Reference Email 1",

    reference2: "Reference 2",
    referencePhone2: "Reference Phone 2",
    referenceEmail2: "Reference Email 2",
  };

  const getInitialValue = (data, key, defaultValue) => {
    if (data && data[key]) {
      return data[key];
    }
    return defaultValue;
  };

  const renderField = ({
    setFieldValue,
    value,
    errors,
    touched,
    name,
    label,
  }) => {
    return (
      <Box pt={3} key={name}>
        <TextField
          label={label}
          name={name}
          value={value}
          variant="outlined"
          disabled={true}
          fullWidth
          multiline={name === "address"}
          error={errors[name] && touched[name]}
          helperText={touched[name] ? errors[name] : ""}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setFieldValue(name, e.target.value)}
        />
      </Box>
    );
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          firstName: getInitialValue(backgroundData, "firstName", ""),
          lastName: getInitialValue(backgroundData, "lastName", ""),
          ssn: isAdmin ? getInitialValue(backgroundData, "SSN", "") : (backgroundData?.SSN?.substring(0, 4) + '.....'),
          dateOfBirth: isAdmin ? getInitialValue(backgroundData, "dateOfBirth", "") : (backgroundData?.dateOfBirth?.substring(0, 2) + '-..-....'),
          licenseNumber: isAdmin ? getInitialValue(backgroundData, "licenseNumber", "") : (backgroundData?.licenseNumber?.substring(0, 3) + '......'),
          licenseState: getInitialValue(backgroundData, "state", ""),
          email: getInitialValue(backgroundData, "email", ""),
          phoneNumber: getInitialValue(backgroundData, "phoneNumber", ""),

          gradSchoolName: getInitialValue(applicationData, "universityName", ""),
          gradDegree: getInitialValue(applicationData, "universityDegreeReceived", ""),
          gradMajor: getInitialValue(applicationData, "major4", ""),

          highSchoolName: getInitialValue(applicationData, "highSchoolName", ""),
          highSchoolDegree: getInitialValue(applicationData, "highSchoolDegree", ""),

          employerName: getInitialValue(applicationData, "employerName", ""),
          employmentPosition: getInitialValue(applicationData, "employeeJobTitle", ""),
          employmentCity: getInitialValue(applicationData, "addressCitySuburb", ""),
          employmentState: getInitialValue(applicationData, "addressState", ""),

          mailingAddress: getInitialValue(applicationData, "contactAddress", ""),
          mailingCity: getInitialValue(applicationData, "contactCity", ""),
          mailingState: getInitialValue(applicationData, "contactState", ""),
          mailingZipcode: getInitialValue(applicationData, "contactZipCode", ""),

          reference1: getInitialValue(applicationData, "referenceName1", ""),
          referencePhone1: getInitialValue(applicationData, "phoneNumber", ""),
          referenceEmail1: getInitialValue(applicationData, "referenceEmailAddress1", ""),

          reference2: getInitialValue(applicationData, "referenceName2", ""),
          referencePhone2: getInitialValue(applicationData, "phoneNumber3", ""),
          referenceEmail2: getInitialValue(applicationData, "referenceEmailAddress2", ""),
        }}
        render={({
          submitForm,
          isSubmitting,
          setFieldValue,
          values,
          errors,
          touched,
        }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <Box mx={3} mt={3}>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      Background Check Authorization
                    </Typography>
                  </Grid>
                  <FlexGrid columns={3}>
                    {Object.keys(fields1).map((data) => {
                      return renderField({
                        setFieldValue: setFieldValue,
                        value: values[data],
                        errors: errors,
                        touched: touched,
                        name: data,
                        label: fields1[data],
                      });
                    })}
                  </FlexGrid>
                  <Grid item xs={12}>
                    <Typography variant="h6">Application</Typography>
                  </Grid>
                  <FlexGrid columns={3}>
                    {Object.keys(applicationFields).map((data) => {
                      return renderField({
                        setFieldValue: setFieldValue,
                        value: values[data],
                        errors: errors,
                        touched: touched,
                        name: data,
                        label: applicationFields[data],
                      });
                    })}
                  </FlexGrid>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      />
    );
  };

  return (
    <Fragment>
      <Paper className={classes.root}> {renderForm()}</Paper>
    </Fragment>
  );
};

export default (UserSubmissionForm);
