import { fetchAction, getQueryString } from './api';

const actions = {
	FETCH_ORDERS: {
		type: 'FETCH_ORDERS',
		key: 'orders',
	},
};

export const fetchAllOrders = (params) => {
	return fetchAction(actions.FETCH_ORDERS, `orders?${getQueryString(params)}`);
};

export default actions;
