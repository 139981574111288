import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  LinearProgress,
  Box,
  TextField,
  Button,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import FlexGrid from "components/FlexGrid";
import request from "helpers/request";
import styles from "styles/forms/Constant";
import { config } from "constants/index";

const useStyles = makeStyles(styles);

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
});

const AddConstantForm = (props) => {
  const classes = useStyles();
  const { handleClose, setSnackBar, snackBar } = props;

  const submit = async (values) => {
    try {
      const finalPayload = {
        ...values,
      };

      await request.post({
        url: `constant`,
        body: finalPayload,
      });
      setSnackBar({ ...snackBar, isOpen: true, type: "success" });
      handleClose();
    } catch (error) {
      setSnackBar({ ...snackBar, isOpen: true, type: "error" });
    }
  };

  return (
    <Formik
      initialValues={{
        name: "",
        value: [],
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        submit(values).then(() => {
          setSubmitting(false);
        });
      }}
      render={({
        submitForm,
        isSubmitting,
        setFieldValue,
        values,
        errors,
        touched,
      }) => (
        <Form>
          <Grid container spacing={3} display="flex" flexDirection="column">
            <Grid item xs={12} lg={12}>
              <FlexGrid columns={2}>
                <Box>
                  <TextField
                    label="Name"
                    name="name"
                    type="text"
                    value={values.name}
                    variant="outlined"
                    error={errors.name && touched.name}
                    helperText={touched.name ? errors.name : ""}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setFieldValue("name", e.target.value);
                    }}
                  />
                </Box>
              </FlexGrid>
            </Grid>
            {isSubmitting && (
              <Grid item xs={12} lg={12}>
                <LinearProgress />
              </Grid>
            )}
            <Grid item xs={12} lg={12}>
              <Button
                variant="contained"
                color="secondary"
                onClick={submitForm}
                className={classes.button}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    />
  );
};

export default AddConstantForm;
