import React, { Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Typography, Grid, CircularProgress, Box } from '@material-ui/core';
import ProductForm from 'forms/Products';
import { fetchProduct } from 'store/actions/products';

const EditProduct = (props) => {
	const { fetchProduct, product } = props;
	const { id } = useParams();

	useEffect(() => {
		fetchProduct(id);
	}, [fetchProduct]);

	return (
		<div className="main-container">
			<Fragment>
				{product && product.loading && (
					<Box display="flex" justifyContent="center">
						<CircularProgress />{' '}
					</Box>
				)}
				{product && !product.loading && product.data && (
					<Grid
						container
						spacing={3}
						display="flex"
						justify="center"
						alignItems="left"
					>
						<Grid item xs={12}>
							<Typography variant="h6" className="add-app-title ">
								Edit Product
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<ProductForm
								productData={product.data}
								//   reloadDataFetch={() => fetchFamily(id)}
							/>
						</Grid>
					</Grid>
				)}
			</Fragment>
		</div>
	);
};

export default connect(
	(state) => ({
		product: state.products.product,
	}),
	(dispatch) => ({
		fetchProduct: (id) => dispatch(fetchProduct(id)),
	})
)(EditProduct);
