import React from "react";
import { makeStyles } from "@material-ui/styles";

import styles from "styles/components/FlexGrid";

const useStyles = makeStyles(styles);

const FlexGrid = props => {
  const classes = useStyles(props);

  const { children } = props;

  return <div className={classes.container}>{children}</div>;
};

export default FlexGrid;
