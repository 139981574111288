import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	Typography,
	Grid,
	Box,
	Dialog,
	DialogTitle,
	DialogContent,
	Tooltip,
	IconButton,
} from '@material-ui/core';
import { fetchAllProducts } from 'store/actions/products';
import Table from 'components/Table';
import SnackBar from 'components/SnackBar';
import { makeStyles } from '@material-ui/core/styles';
import { Edit, AddBox, DeleteForever } from '@material-ui/icons';
import styles from 'styles/pages/Applicants';
import DeleteProduct from './Products/DeleteProduct';
import { ReactComponent as TrashIcon } from '../assets/Icons/icon_trash.svg';
import { ReactComponent as AddIcon } from '../assets/Icons/icon_addAction.svg';

const useStyles = makeStyles(styles);

const pageInitState = {
	page: 1,
};

const Products = (props) => {
	const { fetchAllProducts, products } = props;
	const [pagination, setPagination] = useState(pageInitState);
	const [open, setOpen] = useState({ isOpen: false, type: '' });
	const [snackBar, setSnackBar] = useState({ isOpen: false, type: '' });
	const [dialogData, setdialogData] = useState('');

	const classes = useStyles();

	useEffect(() => {
		fetchAllProducts(pagination);
	}, [fetchAllProducts, pagination]);

	const handleChangePage = (event, newPage) => {
		setPagination({ page: newPage + 1 });
	};

	const closeDialog = () => {
		setOpen({ ...open, isOpen: false, type: '' });
	};

	const closeDialogSuccess = () => {
		setOpen({ ...open, isOpen: false, type: '' });
		fetchAllProducts(pagination);
	};

	const renderDialogContent = () => {
		switch (open.type) {
			case 'product':
				return (
					<DeleteProduct
						handleClose={() => closeDialogSuccess()}
						setSnackBar={setSnackBar}
						snackBar={snackBar}
						data={dialogData}
					/>
				);
			default:
				return;
		}
	};

	const dialogBox = () => {
		return (
			<Dialog
				open={open.isOpen}
				fullWidth={true}
				onClose={closeDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">Delete Product</DialogTitle>
				<DialogContent>{renderDialogContent()}</DialogContent>
			</Dialog>
		);
	};

	const meta = [
		{
			label: 'Product Id',
			render: (row) => (
				<Typography variant={'body2'}>{row.productId}</Typography>
			),
		},
		{
			label: 'Description',
			render: (row) => (
				<Typography variant={'body2'}>{row.description}</Typography>
			),
		},
		{
			label: 'Package',
			render: (row) => <Typography variant={'body2'}>{row.package}</Typography>,
		},
		{
			label: 'Action',
			render: (row) => (
				<>
					<Tooltip title="Edit Product">
						<Link
							to={`/products/edit/${row.id}`}
							style={{ textDecoration: 'none' }}
						>
							<IconButton color="inherit" size="small">
								<Edit fontSize="small" color="secondary" />
							</IconButton>
						</Link>
					</Tooltip>
					<Tooltip title="Delete Product">
						<IconButton
							color="inherit"
							size="small"
							onClick={() => {
								setOpen({ isOpen: true, type: 'product' });
								setdialogData(row);
							}}
						>
							<TrashIcon fontSize="small" className="icon-size" />
						</IconButton>
					</Tooltip>
				</>
			),
		},
	];

	const addNewProduct = () => {
		return (
			<Box display="flex" alignItems="center" className="add-btn-container">
				<Typography variant="body2" className={classes.bold + ' add-btn-title'}>
					Add Product
				</Typography>

				<Tooltip title="Add new Product">
					<Link to={`/products/add`} style={{ textDecoration: 'none' }}>
						<IconButton color="inherit" size="large">
							{/* <AddBox fontSize="large" color="secondary" /> */}
							<AddIcon />
						</IconButton>
					</Link>
				</Tooltip>
			</Box>
		);
	};

	return (
		<Fragment>
			<SnackBar
				snackBar={snackBar}
				type={snackBar.type}
				setSnackBar={setSnackBar}
			/>
			{dialogBox()}
			<Grid container>
				<div className="main-container">
					<Grid item xs={12}>
						<Box
							display="flex"
							justifyContent="space-between"
							alignItems="center"
							pb={3}
						>
							<Typography variant="h6">Products</Typography>
							{addNewProduct()}
						</Box>
					</Grid>
					<Grid item xs={12}>
						{/* {renderBreadCrumbs()} */}
					</Grid>
					<Grid item xs={12}>
						<Table
							meta={meta}
							size="medium"
							data={products.data && products.data.data}
							loading={products.loading}
							errorMessage={products.error && products.error.message}
							withPagination
							currentPage={products.data && products.data.currentPage}
							rowsPerPage={products.data && products.data.perPage}
							totalRowsCount={products.data && products.data.total}
							onPageChange={handleChangePage}
						/>
					</Grid>
				</div>
			</Grid>
		</Fragment>
	);
};

export default connect(
	(state) => ({
		products: state.products.products,
	}),
	(dispatch) => ({
		fetchAllProducts: (params) => dispatch(fetchAllProducts(params)),
	})
)(Products);
