import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Typography,
  Grid,
  Box,
  Tooltip,
  IconButton,
  Breadcrumbs,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import Table from "../../../components/Table";
import { fetchAllCompanies } from "store/actions/company";
import { fetchAllProducts } from "store/actions/products";
import { fetchAllOrders } from "store/actions/orders";
import Sidebar from "./ApplicantInfo/Sidebar";
import SnackBar from "components/SnackBar";
import { makeStyles } from "@material-ui/core/styles";
import styles from "styles/pages/ApplicantInfo";
import PersonalInfo from "./forms/PersonalInfo";
import PositionInfo from "./forms/PositionInfo";
import SourceDataDoc from "./doc/SourceDataDoc";
import ReportingInfo from "./forms/ReportingInfo";
import CreateOrder from "../CreateOrder";
import DeleteApplicant from "../DeleteApplicant";

const useStyles = makeStyles(styles);

const ApplicantInfo = (props) => {
  const {
    applicantData,
    reloadDataFetch,
    isAdmin,
    fetchAllProducts,
    fetchAllOrders,
    products,
    orders,
  } = props;
  const [dialogData, setdialogData] = useState("");
  const [open, setOpen] = useState({ isOpen: false, type: "" });
  const [snackBar, setSnackBar] = useState({ isOpen: false, type: "" });

  useEffect(() => {
    fetchAllProducts();
    fetchAllOrders({ all: "all" });
  }, [fetchAllProducts, fetchAllOrders]);

  const classes = useStyles();

  const renderBreadCrumbs = (data) => {
    return (
      <Breadcrumbs separator=">" aria-label="breadcrumb">
        <Link to="/applicants" style={{ textDecoration: "none" }}>
          <Typography color="textPrimary" className="page-title">
            Applicants
          </Typography>
        </Link>
        <Typography color="textPrimary" className="page-title">
          {data.firstName} {data.lastName}
        </Typography>
      </Breadcrumbs>
    );
  };

  const renderPositionInfo = (data) => {
    return (
      <>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="85%"
        >
          <Box pb={2} pt={2}>
            <Typography
              variant="subtitle2"
              className={classes.subHeading + " title-font"}
            >
              Position Info
            </Typography>
          </Box>
          <Box pb={2} pt={2}>
            <Button
              variant="contained"
              color="inherit"
              size="small"
              className={classes.button}
              onClick={() => {
                setOpen({ isOpen: true, type: "positionInfo" });
                setdialogData(data);
              }}
            >
              Edit
            </Button>
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="baseline"
          justifyContent="space-between"
          width={3 / 4}
        >
          {/* left side */}
          <Box>
            <Box>
              <Typography
                variant="caption"
                className={classes.subHeading + " sub-title"}
              >
                Position:
              </Typography>
            </Box>
            <Box pb={1}>
              <Typography variant="caption">{data.positionTitle}</Typography>
            </Box>

            <Box>
              <Typography
                variant="caption"
                className={classes.subHeading + " sub-title"}
              >
                Position Employment Status:
              </Typography>
            </Box>
            <Box pb={1}>
              <Typography variant="caption">
                {data.positionEmploymentStatus}
              </Typography>
            </Box>

            <Box>
              <Typography
                variant="caption"
                className={classes.subHeading + " sub-title"}
              >
                Vacation Days:
              </Typography>
            </Box>
            <Box pb={3}>
              <Typography variant="caption">{data.vacationDays}</Typography>
            </Box>

            <Box>
              <Typography
                variant="caption"
                className={classes.subHeading + " sub-title"}
              >
                Position Start Date:
              </Typography>
            </Box>
            <Box pb={2}>
              <Typography variant="caption">
                {data.positionStartDate
                  ? new Date(data.positionStartDate).toLocaleDateString()
                  : ""}
              </Typography>
            </Box>

            <Box>
              <Typography
                variant="caption"
                className={classes.subHeading + " sub-title"}
              >
                Pay Amount:
              </Typography>
            </Box>
            <Box pb={2}>
              <Typography variant="caption">
                {data.payAmount ? (
                  <span>
                    ${" "}
                    {data.payAmount
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </span>
                ) : (
                  <span> </span>
                )}
                {/* {data.payAmount
									.toString()
									.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} */}
              </Typography>
            </Box>
          </Box>
          {/* right side */}
          <Box>
            <Box>
              <Typography variant="caption" className={classes.subHeading}>
                Department:
              </Typography>
            </Box>
            <Box pb={1}>
              <Typography variant="caption">{data.department}</Typography>
            </Box>

            <Box>
              <Typography variant="caption" className={classes.subHeading}>
                Bonus/Comission:
              </Typography>
            </Box>
            <Box pb={1}>
              <Typography variant="caption">{data.bonus}</Typography>
            </Box>

            <Box>
              <Typography variant="caption" className={classes.subHeading}>
                Exempt/Non-Exempt Status:
              </Typography>
            </Box>
            <Box pb={2}>
              <Typography variant="caption">{data.exemptStatus}</Typography>
            </Box>

            <Box>
              <Typography variant="caption" className={classes.subHeading}>
                Pay Type:
              </Typography>
            </Box>
            <Box pb={2}>
              <Typography variant="caption" className="uppercase">
                {data.payType}
              </Typography>
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  const renderReportsSection = (data) => {
    return (
      <>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="85%"
        >
          <Box width={3 / 8}>
            <Typography variant="caption" className={classes.subHeading}>
              Reports To:
            </Typography>
          </Box>
          <Box width={3 / 8}>
            <Typography variant="caption" className={classes.subHeading}>
              Location:
            </Typography>
          </Box>
          <Box width={1 / 8}>
            <Button
              variant="contained"
              color="inherit"
              size="small"
              className={classes.button}
              onClick={() => {
                setOpen({ isOpen: true, type: "reportingInfo" });
                setdialogData(data);
              }}
            >
              Edit
            </Button>
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="baseline"
          justifyContent="space-between"
          width="81%"
        >
          <Box width={3 / 8}>
            <Box>
              <Typography variant="caption">{data.reportsTo}</Typography>
            </Box>
            <Box>
              <Typography variant="caption">{data.reportsToTitle}</Typography>
            </Box>
          </Box>
          <Box width={3 / 8} className="align-location">
            <Box>
              <Typography variant="caption">{data.positionAddress}</Typography>
            </Box>
            <Box>
              <Typography variant="caption">
                {data.positionCity}, {data.positionState}
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption">{data.positionZip}</Typography>
            </Box>
          </Box>
          <Box width={1 / 8}></Box>
        </Box>
      </>
    );
  };
  const fetchApplicantOrder = (order, applicantId) => {
    let userOrder = order?.find((e) => e.applicant.id === applicantId);
    console.log(userOrder);
    return userOrder;
  };

  const orderList = (products) => {
    return (
      <>
        {products?.map(({ id, description }) => (
          <Box
            display="flex"
            alignItems="baseline"
            justifyContent="space-between"
            className="orderItem"
            width={4 / 4}
          >
            <Box
              display="flex"
              alignItems="baseline"
              justifyContent="space-between"
              width={1 / 4}
            >
              {id}
            </Box>
            <Box
              display="flex"
              alignItems="baseline"
              justifyContent="space-between"
              width={3 / 4}
            >
              {description}
            </Box>
            {/* <Box
						display="flex"
						alignItems="baseline"
						justifyContent="space-between"
						width={1 / 4}
					>
						Action
					</Box> */}
            <Divider />
          </Box>
        ))}
      </>
    );
  };
  const renderOrders = (data) => {
    // Math
    let productsList = fetchApplicantOrder(
      orders?.data?.data,
      data.id
    )?.products;

    if (productsList) {
      productsList = productsList?.sort((a, b) => a["id"] - b["id"]);
      return (
        <div className="orderTable">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="85%"
          >
            <Box pb={2} pt={2}>
              <Typography
                variant="subtitle2"
                className={classes.subHeading + " title-font"}
              >
                Orders
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="baseline"
            justifyContent="space-between"
            width={4 / 4}
            className="tableTitle"
          >
            <Box
              display="flex"
              alignItems="baseline"
              justifyContent="space-between"
              width={1 / 4}
            >
              ID:
            </Box>
            <Box
              display="flex"
              alignItems="baseline"
              justifyContent="space-between"
              width={3 / 4}
            >
              Description:
            </Box>
            {/* <Box
						display="flex"
						alignItems="baseline"
						justifyContent="space-between"
						width={1 / 4}
					>
						Action
					</Box> */}
            <Divider />
          </Box>

          {orderList(productsList)}
        </div>
      );
    }
  };

  const renderApplicantInfo = (data) => {
    return (
      <>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="85%"
        >
          <Box pb={2} pt={3}>
            <Typography
              variant="subtitle2"
              className={classes.subHeading + " title-font"}
            >
              Personal Info
            </Typography>
          </Box>
          <Box pb={2} pt={3}>
            <Button
              variant="contained"
              color="inherit"
              size="small"
              className={classes.button}
              onClick={() => {
                setOpen({ isOpen: true, type: "personalInfo" });
                setdialogData(data);
              }}
            >
              Edit
            </Button>
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width={2 / 4}
        >
          <Box>
            <Box>
              <Typography variant="caption">
                {data.firstName} {data.lastName}
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption">{data.email}</Typography>
            </Box>
            <Box>
              <Typography variant="caption">{data.phone}</Typography>
            </Box>
          </Box>
          <Box>
            <Box>
              <Typography variant="caption">{data.address}</Typography>
            </Box>
            <Box>
              <Typography variant="caption">
                {data.city}, {data.state}
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption">{data.zip}</Typography>
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  const closeDialog = () => {
    setOpen({ ...open, isOpen: false, type: "" });
  };

  const closeDialogSuccess = () => {
    setOpen({ ...open, isOpen: false, type: "" });
    reloadDataFetch();
  };

  const renderDialogContent = () => {
    switch (open.type) {
      case "personalInfo":
        return (
          <PersonalInfo
            handleClose={() => closeDialogSuccess()}
            setSnackBar={setSnackBar}
            snackBar={snackBar}
            applicantData={dialogData}
          />
        );

      case "positionInfo":
        return (
          <PositionInfo
            handleClose={() => closeDialogSuccess()}
            setSnackBar={setSnackBar}
            snackBar={snackBar}
            applicantData={dialogData}
          />
        );

      case "sourceData":
        return (
          <SourceDataDoc
            setSnackBar={setSnackBar}
            snackBar={snackBar}
            applicantData={dialogData}
          />
        );

      case "reportingInfo":
        return (
          <ReportingInfo
            handleClose={() => closeDialogSuccess()}
            setSnackBar={setSnackBar}
            snackBar={snackBar}
            applicantData={dialogData}
          />
        );

      case "order":
        return (
          <CreateOrder
            handleClose={() => closeDialogSuccess()}
            setSnackBar={setSnackBar}
            snackBar={snackBar}
            data={dialogData}
            allProducts={products?.data?.data}
            products={
              products.data &&
              products?.data?.data
                .filter((f) => f.package === "optional")
                .sort((a, b) => a.description.localeCompare(b.description))
                .map((e) => {
                  let obj = {
                    [e.id]: e.description,
                  };
                  return obj;
                })
            }
          />
        );
      case "deleteApplicant":
        return (
          <DeleteApplicant
            handleClose={() => closeDialogSuccess()}
            setSnackBar={setSnackBar}
            snackBar={snackBar}
            data={dialogData}
          />
        );
      default:
        return;
    }
  };

  const getDialogueHeading = (type) => {
    switch (type) {
      case "personalInfo":
        return "Personal Info";

      case "positionInfo":
        return "Position Info";

      case "reportingInfo":
        return "Reporting Info";

      case "order":
        return "Background Check Order";

      case "deleteApplicant":
        return "Delete Applicant";

      default:
        return;
    }
  };

  const dialogBox = () => {
    return (
      <Dialog
        open={open.isOpen}
        fullWidth={true}
        maxWidth="md"
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {getDialogueHeading(open.type)}
        </DialogTitle>
        <DialogContent>{renderDialogContent()}</DialogContent>
      </Dialog>
    );
  };

  return (
    <Fragment>
      {dialogBox()}
      <SnackBar
        snackBar={snackBar}
        type={snackBar.type}
        setSnackBar={setSnackBar}
      />

      <Grid container>
        <Grid item xs={8}>
          <div className="center-items">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              pl={3}
            >
              {renderBreadCrumbs(applicantData)}
            </Box>
            <Box pb={2} pl={3}>
              {renderApplicantInfo(applicantData)}
            </Box>
            <Box px={4}>
              <Divider className="add-space" />
            </Box>
            <Box pb={2} pl={3}>
              {renderPositionInfo(applicantData)}
            </Box>

            <Box px={4}>
              <Divider className="add-space" />
            </Box>
            <Box pb={2} pl={3}>
              {renderReportsSection(applicantData)}
            </Box>
            <Box px={4}>
              <Divider className="add-space" />
            </Box>
            <Box pb={2} pl={3}>
              {renderOrders(applicantData)}
            </Box>
          </div>
        </Grid>
        <Grid item xs={4} className={classes.sidebar + " align-sidebar"}>
          <Sidebar
            applicantData={applicantData}
            isAdmin={isAdmin}
            setdialogData={(data) => setdialogData(data)}
            setOpen={(data) => setOpen(data)}
            setSnackBar={setSnackBar}
            snackBar={snackBar}
            orders={orders?.data?.data}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default connect(
  (state) => ({
    companies: state.company.company,
    products: state.products.products,
    orders: state.orders.orders,
  }),
  (dispatch) => ({
    fetchAllCompanies: (params) => dispatch(fetchAllCompanies(params)),
    fetchAllProducts: () => dispatch(fetchAllProducts()),
    fetchAllOrders: (params) => dispatch(fetchAllOrders(params)),
  })
)(ApplicantInfo);
