import React, { Fragment, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Paper,
  TextField,
  LinearProgress,
  Grid,
  Button,
  MenuItem,
} from "@material-ui/core";
import FlexGrid from "components/FlexGrid";
import SnackBar from "components/SnackBar";
import request from "helpers/request";
import { productPackages } from "constants/constants";

import styles from "styles/forms/Products";

const useStyles = makeStyles(styles);

const validationSchema = Yup.object({
  productId: Yup.number().required("Product Id is required"),
  description: Yup.string().required("Description is required"),
  package: Yup.string().required("Package is required"),
});

const Products = (props) => {
  const classes = useStyles();
  const { productData } = props;
  const [snackBar, setSnackBar] = useState({ isOpen: false, type: "" });

  const getInitialValue = (data, key, defaultValue) => {
    if (data && data[key]) {
      return data[key];
    }
    return defaultValue;
  };

  const submitForm = async (values, resetForm) => {
    try {
      const { ...finalPayload } = {
        ...values,
      };
      if (productData) {
        await request.post({
          url: `products/${productData.id}/update`,
          body: finalPayload,
        });
      } else {
        await request.post({
          url: `products`,
          body: finalPayload,
        });
        resetForm();
      }
      setSnackBar({ ...snackBar, isOpen: true, type: "success" });
    } catch (error) {
      setSnackBar({ ...snackBar, isOpen: true, type: "error" });
    }
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          productId: getInitialValue(productData, "productId", ""),
          description: getInitialValue(productData, "description", ""),
          package: getInitialValue(productData, "package", ""),
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          submitForm(values, resetForm).then(() => {
            setSubmitting(false);
          });
        }}
        render={({
          submitForm,
          isSubmitting,
          setFieldValue,
          values,
          errors,
          touched,
        }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <Box mx={3} mt={3}>
                  <FlexGrid columns={3}>
                    <Box pt={3}>
                      <TextField
                        label="Product Id"
                        name="productId"
                        type="number"
                        value={values.productId}
                        variant="outlined"
                        fullWidth
                        error={errors.productId && touched.productId}
                        helperText={touched.productId ? errors.productId : ""}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) =>
                          setFieldValue("productId", e.target.value)
                        }
                      />
                    </Box>
                    <Box pt={3}>
                      <TextField
                        label="Description"
                        name="description"
                        value={values.description}
                        variant="outlined"
                        fullWidth
                        multiline
                        error={errors.description && touched.description}
                        helperText={
                          touched.description ? errors.description : ""
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) =>
                          setFieldValue("description", e.target.value)
                        }
                      />
                    </Box>
                    <Box pt={3}>
                      <TextField
                        select
                        label="Package"
                        name="package"
                        type="text"
                        value={values.package}
                        variant="outlined"
                        error={errors.package && touched.package}
                        helperText={touched.package ? errors.package : ""}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) =>
                          setFieldValue("package", e.target.value)
                        }
                      >
                        {productPackages.map((val) => (
                          <MenuItem key={val.id} value={val.id}>
                            {val.value}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                  </FlexGrid>
                </Box>
              </Grid>
              {isSubmitting && (
                <Grid item xs={12} lg={12}>
                  <Box mx={3}>
                    <LinearProgress />
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} lg={12}>
                <Box ml={3} mb={3}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={submitForm}
                    className={classes.button}
                  >
                    Submit
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      />
    );
  };

  return (
    <Fragment>
      <SnackBar
        snackBar={snackBar}
        type={snackBar.type}
        setSnackBar={setSnackBar}
      />
      <Paper className={classes.root}> {renderForm()}</Paper>
    </Fragment>
  );
};

export default Products;
