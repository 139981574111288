import React from 'react';
import { Link, matchPath } from 'react-router-dom';
import {
	ListItem,
	ListItemIcon,
	ListItemText,
	Box,
	Grid,
	Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
	ErrorOutline,
	PersonOutlined,
	Home,
	Message,
	List as ListIcon,
	PeopleOutlineOutlined,
	ArrowDropDownCircleOutlined,
	PersonOutline,
	SettingsEthernet,
	PlaylistAddCheck,
	Business,
	ExitToApp,
} from '@material-ui/icons';
import { Auth } from 'aws-amplify';
import styles from 'styles/components/ListItems';
import { ReactComponent as HomeIcon } from '../assets/Icons/icon_nav_home.svg';
import { ReactComponent as ApplicantIcon } from '../assets/Icons/icon_nav_applicant.svg';
import { ReactComponent as OrdersIcon } from '../assets/Icons/icon_nav_orders.svg';
import { ReactComponent as AdverseIcon } from '../assets/Icons/icon_nav_adverseActions.svg';
import { ReactComponent as PcrIcon } from '../assets/Icons/icon_nav_pcr.svg';
import { ReactComponent as UserIcon } from '../assets/Icons/icon_nav_users.svg';
import { ReactComponent as CompaniesIcon } from '../assets/Icons/icon_nav_companies.svg';
import { ReactComponent as ConstantIcon } from '../assets/Icons/icon_nav_constants.svg';
import { ReactComponent as ProductsIcon } from '../assets/Icons/icon_nav_products.svg';
import { ReactComponent as ProfileIcon } from '../assets/Icons/icon_nav_myProfile.svg';

const useStyles = makeStyles(styles);

// const getParams = ( pathname) =>{
// 	const matchProfile = matchPath (pathname,{
// 		paht: '/profile/:profileId'
// 	});
// 	return ( matchProfile && matchProfile.params) || {}
// }

const MainListItems = ({ userEmail, route, userGroup }) => {
	const classes = useStyles();
	const signOut = () => {
		Auth.signOut()
			.then(() => console.log('Signed Out'))
			.catch((err) => console.log(err));
	};

	const renderActiveTab = ({ url, theIcon, text }) => {
		return (
			<Grid
				item
				xs={12}
				className={
					clsx(url === route && classes.activeTabBg + ' active-listItem') +
					' ListItem-amp'
				}
			>
				<Grid
					className={clsx(
						url === route && classes.activeTabWhite + ' active-tab'
					)}
				>
					<ListItem button component={Link} to={url}>
						<ListItemIcon>
							{/* <HomeIcon color={url === route ? 'primary' : 'default'} /> */}
							{theIcon}
						</ListItemIcon>
						<ListItemText primary={text} />
					</ListItem>
				</Grid>
			</Grid>
		);
	};

	const renderActiveButton = ({ url, Icon, text }) => {
		return (
			<Grid
				item
				xs={12}
				className={
					clsx(url === route && classes.activeTabBg + ' active-listItem') +
					' ListItem-amp'
				}
			>
				<Grid
					className={clsx(
						url === route && classes.activeTabWhite + ' active-tab'
					)}
				>
					<ListItem button onClick={() => signOut()}>
						<ListItemIcon>
							<Icon color={url === route ? 'primary' : 'default'} />
						</ListItemIcon>
						<ListItemText primary={text} />
					</ListItem>
				</Grid>
			</Grid>
		);
	};

	return (
		<div className="nav-list">
			<ListItem className={classes.list}>
				<ListItemText>
					<Typography variant="caption" className={classes.heading}>
						LAUNCHPAD
					</Typography>
				</ListItemText>
			</ListItem>
			{renderActiveTab({
				url: '/',
				theIcon: (
					<HomeIcon
						className={
							'/' === route ? 'secondary-color-stroke' : 'default-color-fill'
						}
					/>
				),
				text: 'Dashboard',
			})}
			{renderActiveTab({
				url: '/applicants',
				Icon: PeopleOutlineOutlined,
				theIcon: (
					<ApplicantIcon
						className={
							'/applicants' === route
								? 'secondary-color-stroke app-icon'
								: 'default-color-fill app-icon'
						}
					/>
				),
				text: 'Applicants',
			})}
			{/* {renderActiveTab({
				url: '/orders',
				Icon: ListIcon,
				theIcon: (
					<OrdersIcon
						className={
							'/orders' === route
								? 'secondary-color-stroke order-icon'
								: 'default-color-fill order-icon'
						}
					/>
				),
				text: 'Orders',
			})} */}
			{renderActiveTab({
				url: '/adverse',
				Icon: ErrorOutline,
				theIcon: (
					<AdverseIcon
						className={
							'/adverse' === route
								? 'secondary-color-stroke '
								: 'default-color-fill '
						}
					/>
				),
				text: 'Adverse Actions',
			})}

			<ListItem className={classes.list}>
				<ListItemText>
					<Typography variant="caption" className={classes.heading}>
						TOOLS
					</Typography>
				</ListItemText>
			</ListItem>
			{renderActiveTab({
				url: '/pcr',
				Icon: ArrowDropDownCircleOutlined,
				theIcon: (
					<PcrIcon
						className={
							'/pcr' === route
								? 'secondary-color-stroke '
								: 'default-color-fill '
						}
					/>
				),
				text: 'ATS',
			})}
			{userGroup === 'admin' &&
				renderActiveTab({
					url: '/users',
					Icon: PersonOutline,
					theIcon: (
						<UserIcon
							className={
								'/users' === route
									? 'secondary-color-stroke '
									: 'default-color-fill '
							}
						/>
					),
					text: 'Users',
				})}

			{(
				userEmail === 'erelations.com' ||
				userEmail === 'playwithdough.com') && (
				<ListItem className={classes.list}>
					<ListItemText>
						<Typography variant="caption" className={classes.heading}>
							SETTINGS
						</Typography>
					</ListItemText>
				</ListItem>
			)}
			{/* //todo Commented out untill working properly  */}
			{/* {(userEmail === "ironnickel.com" ||
        userEmail === "erelations.com" ||
        userEmail === "playwithdough.com") &&
        renderActiveTab({
          url: "/communication",
          Icon: Message,
          text: "Communication",
        })} */}
			{(
				userEmail === 'erelations.com' ||
				userEmail === 'playwithdough.com') &&
				renderActiveTab({
					url: '/company',
					Icon: Business,
					theIcon: (
						<CompaniesIcon
							className={
								'/company' === route ? 'secondary-color' : 'default-color-fill '
							}
						/>
					),
					text: 'Companies',
				})}

			{(
				userEmail === 'erelations.com' ||
				userEmail === 'playwithdough.com') &&
				renderActiveTab({
					url: '/constant',
					Icon: SettingsEthernet,
					theIcon: (
						<ConstantIcon
							className={
								'/constant' === route
									? 'secondary-color'
									: 'default-color-fill '
							}
						/>
					),

					text: 'Constants',
				})}

			{(
				userEmail === 'erelations.com' ||
				userEmail === 'playwithdough.com') &&
				renderActiveTab({
					url: '/products',
					Icon: PlaylistAddCheck,
					theIcon: (
						<ProductsIcon
							className={
								'/products' === route
									? 'secondary-color'
									: 'default-color-fill '
							}
						/>
					),
					text: 'Products',
				})}

			<Box pt={15} />

			{renderActiveTab({
				url: '/profile',
				Icon: PersonOutlined,
				theIcon: (
					<ProfileIcon
						className={
							'/profile' === route ? 'secondary-color' : 'default-color-fill '
						}
					/>
				),

				text: 'My Profile',
			})}
			{renderActiveButton({
				url: '/signOut',
				Icon: ExitToApp,
				text: 'Logout',
			})}
		</div>
	);
};

export default MainListItems;
