import { fetchAction, getQueryString } from "./api";

const actions = {
  FETCH_ALL_CONSTANTS: {
    type: "FETCH_ALL_CONSTANTS",
    key: "constants",
  },
  FETCH_CONSTANT: {
    type: "FETCH_CONSTANT",
    key: "constant",
  },
};

export const fetchAllConstants = (params) => {
  let url = params ? `constant?${getQueryString(params)}` : `constant`;
  return fetchAction(actions.FETCH_ALL_CONSTANTS, url);
};

export const fetchConstant = (id) => {
  return fetchAction(actions.FETCH_CONSTANT, `constant/${id}`);
};

export default actions;
