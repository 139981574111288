import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import ThemeLoader from "../../helpers/components/ThemeLoader";

const NewApplicant = (props) => {
  var script = document.createElement("script");
  script.src = "https://paperform.co/__embed.min.js";
  document.body.appendChild(script);
  return (
    <div>
      <div data-paperform-id="gekf7wvc" data-takeover="1"></div>
    </div>
  );
};
export default NewApplicant;
//export default App;
