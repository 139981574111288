import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import clsx from 'clsx';
import {
	Typography,
	Grid,
	Box,
	Dialog,
	DialogTitle,
	DialogContent,
	Tooltip,
	IconButton,
	Divider,
	Link as MuiLink,
} from '@material-ui/core';
import { fetchAllApplicants } from 'store/actions/user';
import { fetchBackgroundStats } from 'store/actions/stats';
import { fetchAllProducts } from 'store/actions/products';
import { fetchCommunication } from 'store/actions/communication';
import Table from 'components/Table';
import SnackBar from 'components/SnackBar';
import { makeStyles } from '@material-ui/core/styles';
import request from 'helpers/request';
import {
	ListAlt,
	Edit,
	AddBox,
	Ballot,
	Assessment,
	Report,
	BugReport,
	DeleteForever,
	FileCopy,
	ContactMail,
	CloudDownload,
	TextRotationDown,
	AssignmentTurnedIn,
	PermContactCalendar,
	Gavel,
} from '@material-ui/icons';
import styles from 'styles/pages/Applicants';
import CreateOrder from './Applicants/CreateOrder';
import ViewSourceData from './Applicants/ViewSourceData';
import ViewReports from './Applicants/ViewReports';
import DeleteApplicant from './Applicants/DeleteApplicant';
import SearchUsers from 'components/SearchUsers';
import Chart from 'components/Chart';
import Stats from 'components/Stats';
import FlexGrid from 'components/FlexGrid';
import { csvInitialData } from 'constants/constants';

const useStyles = makeStyles(styles);

const pageInitState = {
	page: 1,
};

const Applicants = (props) => {
	const {
		fetchAllApplicants,
		applicants,
		fetchAllProducts,
		products,
		fetchBackgroundStats,
		orderStats,
		fetchCommunication,
		communication,
		isAdmin,
	} = props;
	const [pagination, setPagination] = useState(pageInitState);
	const [open, setOpen] = useState({ isOpen: false, type: '' });
	const [snackBar, setSnackBar] = useState({ isOpen: false, type: '' });
	const [dialogData, setdialogData] = useState('');
	const [sortKey, setSortKey] = useState('');
	const [sortDirection, setSortDirection] = useState('');
	const [isDownloading, setIsDownloading] = useState(false);

	const classes = useStyles();
	const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

	useEffect(() => {
		fetchAllApplicants({
			...pagination,
			sortKey: sortKey,
			sortDirection: sortDirection,
		});
	}, [fetchAllApplicants, pagination, sortKey, sortDirection]);

	useEffect(() => {
		fetchAllProducts();
		fetchBackgroundStats();
		fetchCommunication('dashboard');
	}, [fetchAllProducts, fetchBackgroundStats, fetchCommunication]);

	const handleChangePage = (event, newPage) => {
		setPagination({ page: newPage + 1 });
	};

	const closeDialog = () => {
		setOpen({ ...open, isOpen: false, type: '' });
	};

	const closeDialogSuccess = () => {
		setOpen({ ...open, isOpen: false, type: '' });
		// fetchAllApplicants(pagination);
	};

	const downloadFile = async (id, type, name) => {
		try {
			setIsDownloading(true);
			await request.downloadApiDocument({
				url: `offerLetter/${id}/pdf/${type}`,
				filename: name,
			});
			setIsDownloading(false);
			setSnackBar({ ...snackBar, isOpen: true, type: 'success' });
		} catch (error) {
			setIsDownloading(false);
			setSnackBar({ ...snackBar, isOpen: true, type: 'error' });
		}
	};
	const downloadEntireEmployment = async (id, name) => {
		try {
			setIsDownloading(true);
			await request.downloadApiDocument({
				url: `offerLetter/${id}/employment`,
				filename: name,
			});
			setIsDownloading(false);
			setSnackBar({ ...snackBar, isOpen: true, type: 'success' });
		} catch (error) {
			setIsDownloading(false);
			setSnackBar({ ...snackBar, isOpen: true, type: 'error' });
		}
	};

	const renderDialogContent = () => {
		switch (open.type) {
			case 'order':
				return (
					<CreateOrder
						handleClose={() => closeDialogSuccess()}
						setSnackBar={setSnackBar}
						snackBar={snackBar}
						data={dialogData}
						allProducts={products?.data?.data}
						products={
							products.data &&
							products?.data?.data
								.filter((f) => f.package === 'optional')
								.sort((a, b) => a.description.localeCompare(b.description))
								.map((e) => {
									let obj = {
										[e.id]: e.description,
									};
									return obj;
								})
						}
					/>
				);
			case 'sourceData':
				return (
					<ViewSourceData
						handleClose={() => closeDialogSuccess()}
						setSnackBar={setSnackBar}
						snackBar={snackBar}
						data={dialogData}
					/>
				);

			case 'backgroundCheckResponse':
				return (
					<ViewReports
						handleClose={() => closeDialogSuccess()}
						setSnackBar={setSnackBar}
						snackBar={snackBar}
						data={dialogData}
						products={products?.data?.data}
					/>
				);

			case 'deleteApplicant':
				return (
					<DeleteApplicant
						handleClose={() => closeDialogSuccess()}
						setSnackBar={setSnackBar}
						snackBar={snackBar}
						data={dialogData}
					/>
				);
			default:
				return;
		}
	};

	const getDialogueHeading = (type) => {
		switch (type) {
			case 'order':
				return 'Background Check Order';
			case 'sourceData':
				return 'Source Data';

			case 'backgroundCheckResponse':
				return 'Report';

			case 'deleteApplicant':
				return 'Delete Applicant';
			default:
				return;
		}
	};

	const dialogBox = () => {
		return (
			<Dialog
				open={open.isOpen}
				fullWidth={true}
				maxWidth={open.type === 'sourceData' ? 'md' : 'sm'}
				onClose={closeDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{getDialogueHeading(open.type)}
				</DialogTitle>
				<DialogContent>{renderDialogContent()}</DialogContent>
			</Dialog>
		);
	};

	const getReportIcons = (requestList) => {
		let isError = requestList.find((e) => e.AlertFlag === true);

		if (isError) {
			return <Report fontSize="small" color="error" />;
		} else if (!isError && requestList.length > 0) {
			return <Assessment fontSize="small" color="secondary" />;
		}
		return <Assessment fontSize="small" color="inherit" />;
	};

	const getApplicantSubmissionIcons = (sourceData) => {
		if (!sourceData) {
			return 'inherit';
		} else if (sourceData.background) {
			return 'background';
		} else if (sourceData.application) {
			return 'secondary';
		}
	};

	const handleRequestSort = (property) => {
		const isAsc = sortKey === property && sortDirection === 'asc';
		setSortDirection(isAsc ? 'desc' : 'asc');
		setSortKey(property);
	};

	const meta = [
		{
			label: 'Reports',
			render: (row) => (
				<>
					<Tooltip title="Background Reports">
						<IconButton
							color="inherit"
							size="small"
							disabled={!row.erxId}
							onClick={() => {
								setOpen({ isOpen: true, type: 'backgroundCheckResponse' });
								setdialogData({
									backgroundResponse: row.backgroundCheckResponse,
									id: row.id,
									status: row.status,
									erxId: row.erxId,
								});
							}}
						>
							{getReportIcons(row.backgroundCheckResponse ?? [])}
						</IconButton>
					</Tooltip>
					{/* <Tooltip title="Download User Submission CSV">
            <IconButton
              color="secondary"
              size="small"
              disabled={!row.sourceData}
              component={CSVLink}
              data={csvInitialData(
                row.sourceData?.background,
                row.sourceData?.application
              )}
              filename="UserSubmission.csv"
            >
              <CloudDownload fontSize="small" />
            </IconButton>
          </Tooltip> */}
				</>
			),
		},
		{
			label: 'Applicant Submissions',
			render: (row) => (
				<>
					{/* <Tooltip title="Criminal">
            <IconButton
              color="secondary"
              size="small"
              disabled={!row.sourceData?.background || isDownloading}
              onClick={() =>
                downloadFile(row.id, "criminal", "Nevada Criminal")
              }
            >
              <Gavel fontSize="small" />
            </IconButton>
          </Tooltip> */}
					<Tooltip title="Entire Employment Application">
						<IconButton
							color="secondary"
							size="small"
							disabled={!row.sourceData?.background || isDownloading}
							onClick={() =>
								downloadEntireEmployment(row.id, 'Employment Application')
							}
						>
							<PermContactCalendar fontSize="small" />
						</IconButton>
					</Tooltip>
					<Tooltip title="Background Check Authorization Signature">
						<IconButton
							color="secondary"
							size="small"
							disabled={!row.sourceData?.application || isDownloading}
							onClick={() =>
								downloadFile(row.id, 'application', 'Background Check')
							}
						>
							<AssignmentTurnedIn fontSize="small" />
						</IconButton>
					</Tooltip>
					<Tooltip title="Employment Application Signature">
						<IconButton
							color="secondary"
							size="small"
							disabled={!row.sourceData?.background || isDownloading}
							onClick={() =>
								downloadFile(row.id, 'background', 'Application Signature')
							}
						>
							<TextRotationDown fontSize="small" />
						</IconButton>
					</Tooltip>
					{/* <Tooltip title="SSA Form">
            <IconButton
              color="inherit"
              size="small"
              disabled={!row.sourceData?.background?.SSAFormURL}
            >
              <MuiLink
                href={row.sourceData?.background?.SSAFormURL}
                target="_blank"
                rel="noopener noreferrer"
                color={
                  row.sourceData?.background?.SSAFormURL
                    ? "secondary"
                    : "inherit"
                }
              >
                <FileCopy fontSize="small" />
              </MuiLink>
            </IconButton>
          </Tooltip> */}
					<Tooltip title="Offer Letter">
						<IconButton
							color="inherit"
							size="small"
							disabled={!row.sourceData?.offerLetter?.url}
						>
							<MuiLink
								href={row.sourceData?.offerLetter?.url}
								target="_blank"
								rel="noopener noreferrer"
								className={
									classes[
										getOfferLetterColors(
											row.sourceData?.offerLetter?.url,
											row?.isOfferLetterSent
										)
									]
								}
							>
								<ContactMail fontSize="small" />
							</MuiLink>
						</IconButton>
					</Tooltip>
					<Tooltip title={'Applicant Data'}>
						<IconButton size="small" disabled={!row.sourceData}>
							<Link
								to={`/user/response/${row.id}`}
								style={{ textDecoration: 'none' }}
							>
								<Ballot
									fontSize="small"
									className={classes[row.sourceData ? 'secondary' : 'inherit']}
								/>
							</Link>
						</IconButton>
					</Tooltip>
				</>
			),
		},
		{
			label: 'Adverse Action',
			render: (row) => (
				<>
					<Tooltip
						title={row.adverse ? 'Edit Adverse Action' : 'Add Adverse Action'}
					>
						<IconButton
							color="inherit"
							size="small"
							disabled={row.status !== 'Complete'}
						>
							<Link
								to={
									row.adverse
										? `/adverse/edit/${row.adverse.id}/${row.id}`
										: `/adverse/${row.id}/add`
								}
								style={{ textDecoration: 'none' }}
							>
								<BugReport
									fontSize="small"
									className={
										classes[
											row.adverse
												? 'error'
												: row.status === 'Complete'
												? 'secondary'
												: 'inherit'
										]
									}
								/>
							</Link>
						</IconButton>
					</Tooltip>
				</>
			),
		},
		{
			label: 'First Name',
			sortKey: 'firstName',
			render: (row) => (
				<Typography variant={'body2'}>{row.firstName}</Typography>
			),
		},
		{
			label: 'Last Name',
			sortKey: 'lastName',
			render: (row) => (
				<Typography variant={'body2'}>{row.lastName}</Typography>
			),
		},
		{
			label: 'Email',
			render: (row) => <Typography variant={'body2'}>{row.email}</Typography>,
		},
		{
			label: 'Status',
			sortKey: 'status',
			render: (row) => (
				<Box display="flex" alignItems="center">
					<Typography
						variant={'subtitle1'}
						className={
							classes[row.status === 'In Progress' ? 'default' : row.status]
						}
					>
						{row.status}
					</Typography>
				</Box>
			),
		},
		{
			label: 'Action',
			render: (row) => (
				<>
					{isAdmin && (
						<Tooltip title="Applicant Submissions">
							<IconButton
								size="small"
								disabled={!row.sourceData}
								onClick={() => {
									setOpen({ isOpen: true, type: 'sourceData' });
									setdialogData(row.sourceData);
								}}
							>
								<Ballot
									fontSize="small"
									className={
										classes[getApplicantSubmissionIcons(row.sourceData)]
									}
								/>
							</IconButton>
						</Tooltip>
					)}
					<Tooltip title="Submit Order">
						<IconButton
							color="inherit"
							size="small"
							disabled={
								!row.positionTitle || !row.positionStartDate || !row.payAmount
							}
							onClick={() => {
								setOpen({ isOpen: true, type: 'order' });
								setdialogData(row);
							}}
						>
							<ListAlt
								fontSize="small"
								color={
									!row.positionTitle || !row.positionStartDate || !row.payAmount
										? 'inherit'
										: 'secondary'
								}
							/>
						</IconButton>
					</Tooltip>
					<Tooltip title="Edit Applicant">
						<Link
							to={`/applicants/edit/${row.id}`}
							style={{ textDecoration: 'none' }}
						>
							<IconButton color="inherit" size="small">
								<Edit fontSize="small" color="secondary" />
							</IconButton>
						</Link>
					</Tooltip>
					{isAdmin && (
						<Tooltip title="Delete Applicant">
							<IconButton
								color="inherit"
								size="small"
								onClick={() => {
									setOpen({ isOpen: true, type: 'deleteApplicant' });
									setdialogData(row);
								}}
							>
								<DeleteForever fontSize="small" color="secondary" />
							</IconButton>
						</Tooltip>
					)}
				</>
			),
		},
	];

	const getOfferLetterColors = (url, isLetterSent) => {
		if (url) {
			return 'Complete';
		} else if (!url && isLetterSent) {
			return 'secondary';
		}
		return 'inherit';
	};

	const addNewApplicant = () => {
		return (
			<Tooltip title="Add Applicant">
				<Link to={`/applicants/add`} style={{ textDecoration: 'none' }}>
					<IconButton color="inherit" size="large">
						<AddBox fontSize="large" color="secondary" />
					</IconButton>
				</Link>
			</Tooltip>
		);
	};

	return (
		<Fragment>
			<SnackBar
				snackBar={snackBar}
				type={snackBar.type}
				setSnackBar={setSnackBar}
			/>
			{dialogBox()}
			<Grid container className="main-container">
				<Grid item xs={12}>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						pb={3}
						pt={2}
					>
						<Typography variant="h6">Dashboard</Typography>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<FlexGrid columns={3}>
						<Box>
							<Stats
								title="Completed Checks"
								value={orderStats?.data?.completed ?? 0}
								isLoading={orderStats.loading}
							/>
						</Box>
						<Box>
							<Stats
								title="Pending Checks"
								value={orderStats?.data?.pending ?? 0}
								isLoading={orderStats.loading}
							/>
						</Box>
						{/* Add Communication box once chat is available */}
						{/* <Box>
							<Chart
								title={communication.data?.title}
								value={communication.data?.message}
								isLoading={communication.loading}
							/>
						</Box> */}
					</FlexGrid>
				</Grid>
				{/* <Grid item xs={12}>
          <Box pt={2}>
            <Divider />
          </Box>
        </Grid> */}
				{/* <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pb={3}
            pt={2}
          >
            <Typography variant="h6">Applicants</Typography>
            {addNewApplicant()}
          </Box>
        </Grid> */}
				{/* <Grid item xs={12}>
          <Box pb={3}>
            <SearchUsers
              setPagination={setPagination}
              pagination={pagination}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Table
            meta={meta}
            size="medium"
            data={applicants.data && applicants.data.data}
            loading={applicants.loading}
            errorMessage={applicants.error && applicants.error.message}
            withPagination
            currentPage={applicants.data && applicants.data.currentPage}
            rowsPerPage={applicants.data && applicants.data.perPage}
            totalRowsCount={applicants.data && applicants.data.total}
            onPageChange={handleChangePage}
            sortKey={sortKey}
            sortDirection={sortDirection}
            onSort={(e) => handleRequestSort(e)}
          />
        </Grid> */}
			</Grid>
		</Fragment>
	);
};

export default connect(
	(state) => ({
		applicants: state.user.applicants,
		products: state.products.products,
		orderStats: state.stats.backgroundStats,
		communication: state.communication.communication,
	}),
	(dispatch) => ({
		fetchAllApplicants: (params) => dispatch(fetchAllApplicants(params)),
		fetchAllProducts: () => dispatch(fetchAllProducts()),
		fetchBackgroundStats: () => dispatch(fetchBackgroundStats()),
		fetchCommunication: (id) => dispatch(fetchCommunication(id)),
	})
)(Applicants);
