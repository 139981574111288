import React, { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Typography, Grid, CircularProgress, Box } from "@material-ui/core";
// import ApplicantForm from "forms/Applicant";
import ApplicantInfo from "./components/ApplicantInfo";
import { fetchApplicant } from "store/actions/user";

const EditApplicant = (props) => {
  const { fetchApplicant, applicant, isAdmin } = props;
  const { id } = useParams();

  useEffect(() => {
    fetchApplicant(id);
  }, [fetchApplicant]);

  return (
    <Fragment>
      {applicant && applicant.loading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />{" "}
        </Box>
      )}
      {applicant && !applicant.loading && applicant.data && (
        // <Grid
        //   container
        //   spacing={3}
        //   display="flex"
        //   justify="center"
        //   alignItems="left"
        // >
        <Grid item xs={12}>
          <ApplicantInfo
            applicantData={applicant.data}
            reloadDataFetch={() => fetchApplicant(id)}
            isAdmin={isAdmin}
          />
        </Grid>
        // </Grid>
      )}
    </Fragment>
  );
};

export default connect(
  (state) => ({
    applicant: state.user.applicant,
  }),
  (dispatch) => ({
    fetchApplicant: (id) => dispatch(fetchApplicant(id)),
  })
)(EditApplicant);
