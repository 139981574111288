export default (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
  },
  appBar: {
    backgroundColor: theme.color.primary,
    color: theme.color.white,
    alignitems: "center",
  },
});
