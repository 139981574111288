export default (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing * 3,
    overflowX: "auto",
  },
  button: {
    color: theme.color.white,
  },
  cardRoot: {
    minWidth: 275,
  },
});
