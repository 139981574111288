import { fetchAction, getQueryString } from "./api";

const actions = {
  FETCH_PRODUCTS: {
    type: "FETCH_PRODUCTS",
    key: "products",
  },
  FETCH_PRODUCT: {
    type: "FETCH_PRODUCT",
    key: "product",
  },
};

export const fetchAllProducts = (params) => {
  let url = params ? `products?${getQueryString(params)}` : `products`;
  return fetchAction(actions.FETCH_PRODUCTS, url);
};

export const fetchProduct = (id) => {
  return fetchAction(actions.FETCH_PRODUCT, `products/${id}`);
};
export default actions;
