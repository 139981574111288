import React, { Fragment } from "react";
import { Typography, Grid } from "@material-ui/core";
import ApplicantForm from "forms/Applicant";

const AddApplicant = () => {
  return (
    <Fragment>
      <div className="main-container">
        <Grid
          container
          spacing={3}
          display="flex"
          justify="center"
          alignItems="left"
        >
          <Grid item xs={12}>
            <Typography variant="h4" className="add-app-title">
              Add Applicant
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ApplicantForm />
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};

export default AddApplicant;
