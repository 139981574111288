import React from 'react';
import Logo from './logo/MNO_Logo.webp';
import EMPLOYIFY from './logo/logo_employifi.svg';
import { ReactComponent as ReactLogo } from './Icons/icon_nav_home.svg';
import { Icon } from '@material-ui/core';

import { ReactComponent as Checklist } from './Icons/Icon awesome-list-ul.svg';
import { ReactComponent as Send } from './Icons/icon_actions.svg';
import { ReactComponent as DownloadBtn } from './Icons/icon_download.svg';
import { ReactComponent as MainDownloadButton } from './Icons/icon_download.svg';
import { ReactComponent as ViewBtn } from './Icons/icon_adverseAction_view.svg';
import { ReactComponent as Options } from './Icons/icon_nav_products.svg';

export const MNOLOGO = () => {
	return (
		<Icon>
			<img src={Logo} alt="" width="150px" fontSize="0" />
		</Icon>
	);
};

export const EmployifyLogo = () => {
	return (
		<Icon>
			<img src={EMPLOYIFY} alt="" width="160px" fontSize="0" />
		</Icon>
	);
};

export const HomeIcon = (color) => {
	return (
		<Icon>
			<ReactLogo color="red" />
		</Icon>
	);
};

export const ChecklistIcon = (prop) => {
	return <Checklist className={prop.className} />;
};

export const SendIcon = (prop) => {
	return <Send className={prop.className} />;
};

export const DownloadBtnIcon = (prop) => {
	return <DownloadBtn className={prop.className} />;
};

export const MainDownloadIcon = (prop) => {
	return <MainDownloadButton className={prop.className} />;
};

export const ViewBtnIcon = (prop) => {
	return <ViewBtn className={prop.className} />;
};

export const OptionsIcon = (prop) => {
	return <Options className={prop.className} />;
};
