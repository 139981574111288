import React, { Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Stats from './Stats';
import EditApplicant from './Applicants/EditApplicant';
import AddApplicant from './Applicants/AddApplicant';
import VerifyEmail from './VerifyEmail';
import PcrUsers from './PcrUsers';
import Orders from './Orders';
import Products from './Products';
import AddProduct from './Products/AddProduct';
import EditProduct from './Products/EditProduct';
import Company from './Company';
import AddCompany from './Company/AddCompany';

import Communication from './Communication';
import AddCommunication from './Communication/AddCommunication';
import EditCommunication from './Communication/EditCommunication';

import Adverse from './Adverse';
import AddAdverse from './Adverse/AddAdverse';
import EditAdverse from './Adverse/EditAdverse';

import Constant from './Constants';
import EditConstant from './Constants/EditConstant';

import UserSubmission from './UserSubmission/UserSubmission';

import ApplicantInfo from './ApplicantInfo';
import Applicants from './Applicants';

import Profile from './Profile';

import Users from './Users';

import NewApplicant from "./StaticPages/NewApplicant";
import CABackground from "./StaticPages/CABackground";
import OfferLetterUpload from "./StaticPages/OfferLetterUpload";

const Layout = (props) => {
	const { isEmailVerified, setEmailVerification, userEmail, userGroup } = props;
	return (
		<Fragment>
			{/* <Router> */}
			<div>
				{!isEmailVerified && (
					<Route
						exact
						path="/"
						render={() => (
							<VerifyEmail setEmailVerification={setEmailVerification} />
						)}
					/>
				)}
				{isEmailVerified && (
					<>
						<Route
							exact
							path="/users"
							render={() =>
								userGroup === 'admin' ? <Users /> : <Redirect to="/" />
							}
						/>
						<Route exact path="/profile" component={Profile} />
						<Route exact path="/pcr" component={PcrUsers} />
						<Route
							exact
							path="/"
							render={() => (
								<Stats
									isAdmin={
										userEmail === 'erelations.com' ||
										userEmail === 'playwithdough.com'
									}
								/>
							)}
						/>
						<Route
							exact
							path="/applicants"
							render={() => (
								<Applicants
									isAdmin={
										userEmail === 'erelations.com' ||
										userEmail === 'playwithdough.com'
									}
								/>
							)}
						/>
						<Route exact path="/applicants/add" component={AddApplicant} />
						<Route exact path="/applicant/info" component={ApplicantInfo} />
						<Route
							exact
							path="/applicants/edit/:id"
							render={() => (
								<EditApplicant
									isAdmin={
										userEmail === 'erelations.com' ||
										userEmail === 'playwithdough.com'
									}
								/>
							)}
						/>
						<Route exact path="/orders" component={Orders} />
						<Route exact path="/products" component={Products} />
						<Route exact path="/products/add" component={AddProduct} />
						<Route exact path="/products/edit/:id" component={EditProduct} />
						<Route
							exact
							path="/user/response/:id"
							render={() => (
								<UserSubmission
									isAdmin={
										userEmail === 'erelations.com' ||
										userEmail === 'playwithdough.com'
									}
								/>
							)}
						/>
						<Route exact path="/communication" component={Communication} />
						<Route
							exact
							path="/communication/add"
							component={AddCommunication}
						/>
						<Route
							exact
							path="/communication/edit/:id"
							component={EditCommunication}
						/>
						<Route exact path="/constant" component={Constant} />
						<Route exact path="/constant/edit/:id" component={EditConstant} />
						<Route exact path="/adverse" component={Adverse} />
						<Route
							exact
							path="/adverse/:applicantId/add"
							component={AddAdverse}
						/>
						<Route
							exact
							path="/adverse/edit/:id/:applicantId"
							component={EditAdverse}
						/>
						<Route
							exact
							path="/company"
							render={() =>
								userEmail === 'erelations.com' ||
								userEmail === 'playwithdough.com' ? (
									<Company />
								) : (
									<Redirect to="/" />
								)
							}
						/>
						<Route
							exact
							path="/company/add"
							render={() =>
								userEmail === 'erelations.com' ||
								userEmail === 'playwithdough.com' ? (
									<AddCompany />
								) : (
									<Redirect to="/" />
								)
							}
						/>
						<Route exact path="/ca/application" component={NewApplicant}/>
						<Route exact path="/ca/compliance" component={CABackground} />
        				<Route exact path="/ca/offer-upload" component={OfferLetterUpload} />
					</>
				)}
			</div>
			{/* </Router> */}
		</Fragment>
	);
};

export default Layout;
