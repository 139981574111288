export default (theme) => ({
    root: {
      width: "100%",
      marginTop: theme.spacing(3),
      overflowX: "auto",
    },
    button: {
      backgroundColor: theme.color.secondary,
      color: theme.color.white,
      "&:hover": {
        backgroundColor: theme.color.secondary,
        color: theme.color.white,
      },
    },
    table: {
      minWidth: 650,
    },
    selectTableCell: {
      width: 60,
    },
    tableCell: {
      width: 130,
      height: 40,
    },
    input: {
      width: 130,
      height: 40,
    },
  });
  