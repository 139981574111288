import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Tabs, Tab, Typography, Box, Grid } from '@material-ui/core';
import styles from 'styles/pages/Profile';
import UserAttributes from './profile/UserAttributes';
import ChangePassword from './profile/ChangePassword';

const useStyles = makeStyles(styles);

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

export default function SimpleTabs() {
	const classes = useStyles();
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const renderTab = (index) => {
		switch (index) {
			case 0:
				return <UserAttributes />;
			case 1:
				return <ChangePassword />;
			default:
				return null;
		}
	};

	return (
		<Grid container className="main-container">
			<Grid item xs={12}>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					pb={3}
				>
					<Typography variant="h6">Profile</Typography>
				</Box>
			</Grid>
			<Grid className={classes.root}>
				<AppBar
					position="static"
					className={classes.appBar + ' appbar-styling'}
				>
					<Tabs
						value={value}
						onChange={handleChange}
						centered
						TabIndicatorProps={{
							style: {
								backgroundColor: 'white',
							},
						}}
					>
						<Tab label="User Attributes" />
						<Tab label="Change Password" />
					</Tabs>
				</AppBar>
				{renderTab(value)}
			</Grid>
		</Grid>
	);
}
