import React, { useState, Fragment } from "react";
import {
  Typography,
  Box,
  Card,
  CardActions,
  CardContent,
  Button,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import { CircularProgress } from "components";
import { makeStyles } from "@material-ui/core/styles";
import { Auth } from "aws-amplify";
import styles from "styles/pages/VerifyEmail";

const codeInitialState = {
  status: false,
  loading: false,
  error: null,
};

const pinInitialState = {
  loading: false,
  value: "",
  error: null,
};

const useStyles = makeStyles(styles);

const VerifyEmail = (props) => {
  const { setEmailVerification } = props;

  const classes = useStyles();
  const [code, setCode] = useState(codeInitialState);
  const [pin, setPin] = useState(pinInitialState);

  const sendEmailCode = async () => {
    setCode({ ...codeInitialState, loading: true });
    try {
      const code = await Auth.verifyCurrentUserAttribute("email");
      setCode({
        ...code,
        status: true,
        loading: false,
        error: null,
      });
    } catch (err) {
      setCode({
        ...code,
        status: false,
        loading: false,
        error: err,
      });
    }
  };

  const verifyEmailCode = async () => {
    setPin({ ...pin, loading: true, value: "", error: null });
    try {
      await Auth.verifyCurrentUserAttributeSubmit("email", pin.value);
      setEmailVerification(true);
      setCode(codeInitialState);
    } catch (err) {
      setPin({
        ...pin,
        loading: false,
        value: "",
        error: err,
      });
    }
  };

  const renderCodeSubmission = () => {
    return (
      <TextField
        required
        id="outlined-helperText"
        label="Code"
        value={pin.value}
        error={pin?.error?.message ? true : false}
        helperText={pin?.error?.message ?? "Enter code sent to email"}
        onChange={(e) => setPin({ ...pin, value: e.target.value })}
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => verifyEmailCode()}
                className={classes.submitCode}
              >
                <ArrowForward />
              </Button>
            </InputAdornment>
          ),
        }}
      />
    );
  };

  return (
    <Fragment>
      <Card className={classes.cardMain}>
        <Box>
          <CardContent>
            <Typography variant="h6" align="center" color="textPrimary">
              Please verify your email address
            </Typography>
          </CardContent>
          <CardActions className={classes.cardActions}>
            {!code.status && !code.loading && (
              <Button
                variant="contained"
                className={classes.submitCode}
                onClick={() => sendEmailCode()}
              >
                Verify
              </Button>
            )}
            {!code.status && code.loading && <CircularProgress />}
            {code.status && renderCodeSubmission()}
          </CardActions>
        </Box>
      </Card>
    </Fragment>
  );
};

export default VerifyEmail;
