import { states } from "constants/constants";

const getContactReference = (data) => {
  let contactReference = [];

  if (data.referenceName1) {
    contactReference.push({
      FirstName: data.referenceName1,
      LastName: "",
      Phone: data.phoneNumber,
      Email: data.referenceEmailAddress1,
    });
  }
  if (data.referenceName2) {
    contactReference.push({
      FirstName: data.referenceName2,
      LastName: "",
      Phone: data.phoneNumber3,
      Email: data.referenceEmailAddress2,
    });
  }
  return contactReference;
};

const getContactLicense = (data) => {
  let licenses = [];

  if (data.licenseNumber && data.state) {
    licenses.push({
      LicenseTypeSys: "DRIVERS",
      IssuingBody: "",
      LicenseNumber: data.licenseNumber,
      IssuingState: states.find(val => val.name === data.state)?.abbreviation,
    },);
  }
  return licenses;
};

const getEducationHistory = (data) => {
  let educationHistory = [];

  if (data.highSchoolName) {
    educationHistory.push({
      SchoolTypeSys: "HIGHSCHOOL",
      SchoolName: data.highSchoolName,
      City: "",
      State: "",
      Major: "",
      DegreeEarned: data.highSchoolDegree,
      StartDate: "",
      EndDate: "",
    });
  }
  if (data.universityName) {
    educationHistory.push({
      SchoolTypeSys: "GRAD",
      SchoolName: data.universityName,
      City: "",
      State: "",
      DegreeEarned: data.universityDegreeReceived,
      Major: data.major4,
      StartDate: "",
      EndDate: "",
    });
  }
  return educationHistory;
};

const formatPayload = (data, productIds) => {
  let payload = {
    User: "zack@ironnickel.com",
    ClientId: 1340,
    Applicant: {
      Contact: {
        FirstName: data.background.firstName,
        LastName: data.background.lastName,
        SSN: data.background.SSN,
        DateOfBirth: data.background.dateOfBirth,
      },
      Application: {
        JobID: "5164",
      },
      ContactEducationHistoryList: getEducationHistory(data.application),
      ContactEmploymentHistoryList: [
        {
          EmployerName: data.application.employerName,
          Position: data.application.employeeJobTitle,
          City: data.application.addressCitySuburb,
          State: data.application.addressState,
        },
      ],
      ContactMailingAddress: {
        Address1: data.application.contactAddress,
        City: data.application.contactCity,
        State: data.application.contactState,
        ZipCode: data.application.contactZipCode,
      },
      ContactReferenceList: getContactReference(data.application),
      ContactLicenseList: getContactLicense(data.background)
    },
    BackgroundCheck: {
      ApplicationID: null,
      VulnerablePopulation: false,
      SelectedQBProductIds: productIds.map((e) => `${e}`),
    },
  };

  return payload;
};

export default formatPayload;
