import axios from "axios";

import actions from "store/actions/auth";
import { START, SUCCESS, FAIL } from "../actions/types";

const initialState = {
  user: {
    data: null,
    loggedIn: false,
    loading: false,
    error: null
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case actions.LOGIN.type:
      switch (action.status) {
        case START:
          return {
            ...state,
            user: {
              ...state.user,
              loggedIn: false,
              error: null,
              loading: true
            }
          };

        case SUCCESS:
          if (action.payload.user && action.payload.access_token) {
            localStorage.setItem(
              "deal_source_token",
              action.payload.access_token
            );
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + action.payload.access_token;
          } else {
            localStorage.removeItem("deal_source_token");
            delete axios.defaults.headers.common["Authorization"];
          }
          return {
            ...state,
            user: {
              error: null,
              loading: false,
              loggedIn: true,
              data: action.payload.user
            }
          };

        case FAIL:
          return {
            ...state,
            user: {
              ...state.user,
              loggedIn: false,
              loading: false,
              error: action.payload.error
            }
          };

        default:
          return state;
      }

    case actions.LOGOUT.type:
      switch (action.status) {
        case START:
          return {
            ...state,
            user: {
              ...state.user,
              error: null,
              loading: true
            }
          };

        case SUCCESS:
          // delete axios.defaults.headers.common["Http-Authorization"];

          return {
            ...state,
            user: {
              ...state.user,
              loading: false,
              loggedIn: false,
              data: null
            }
          };

        case FAIL:
          return {
            ...state,
            user: {
              ...state.user,
              loading: false,
              error: action.payload.error
            }
          };

        default:
          return state;
      }

    default:
      return state;
  }
}
