export default theme => ({
    progress: {
        margin: theme.spacing(2),
        color: theme.color.primary,
    },
    downloadProgress: {
        color: theme.color.primary,
    },
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});
