import { fetchAction, getQueryString } from "./api";

const actions = {
  FETCH_COMPANIES: {
    type: "FETCH_COMPANIES",
    key: "company",
  },
};

export const fetchAllCompanies = (params) => {
  let url = `company?${getQueryString(params)}`;
  return fetchAction(actions.FETCH_COMPANIES, url);
};

export default actions;
