import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Typography,
	IconButton,
	Tooltip,
	Grid,
	Box,
} from '@material-ui/core';
import { AddBox, Edit } from '@material-ui/icons';
import { fetchAllConstants } from 'store/actions/constant';
import Table from 'components/Table';
import { makeStyles } from '@material-ui/core/styles';
import SnackBar from 'components/SnackBar';
import AddConstant from 'forms/AddConstantForm';
import styles from 'styles/pages/Constants';
import { ReactComponent as AddIcon } from '../assets/Icons/icon_addAction.svg';

const useStyles = makeStyles(styles);

const pageInitState = {
	page: 1,
};

const Constants = (props) => {
	const { fetchAllConstants, constants } = props;
	const [pagination, setPagination] = useState(pageInitState);
	const [snackBar, setSnackBar] = useState({ isOpen: false, type: '' });
	const [open, setOpen] = useState({ isOpen: false, type: '' });

	const classes = useStyles();

	useEffect(() => {
		fetchAllConstants(pagination);
	}, [fetchAllConstants, pagination]);

	const handleChangePage = (event, newPage) => {
		setPagination({ page: newPage + 1 });
	};

	const meta = [
		{
			label: 'Name',
			render: (row) => (
				<Typography variant={'subtitle1'}>{row.name}</Typography>
			),
		},
		{
			label: 'Values',
			render: (row) => (
				<Typography variant={'subtitle1'}>
					{row?.value?.map((val) => val.label).join(', ') || ''}
				</Typography>
			),
		},
		{
			label: 'Update',
			render: (row) => (
				<Tooltip title="Edit">
					<Link
						to={`/constant/edit/${row.id}`}
						style={{ textDecoration: 'none' }}
					>
						<IconButton color="inherit" size="small">
							<Edit fontSize="small" color="secondary" />
						</IconButton>
					</Link>
				</Tooltip>
			),
		},
	];

	const closeDialog = () => {
		setOpen({ ...open, isOpen: false, type: '' });
	};

	const closeDialogSuccess = () => {
		setOpen({ ...open, isOpen: false, type: '' });
		fetchAllConstants();
	};

	const renderDialogContent = () => {
		switch (open.type) {
			case 'addConstant':
				return (
					<AddConstant
						handleClose={() => closeDialogSuccess()}
						setSnackBar={setSnackBar}
						snackBar={snackBar}
					/>
				);
			default:
				return;
		}
	};

	const dialogBox = () => {
		return (
			<Dialog
				open={open.isOpen}
				fullWidth={true}
				maxWidth={'md'}
				onClose={closeDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">Add New Constant</DialogTitle>
				<DialogContent>{renderDialogContent()}</DialogContent>
			</Dialog>
		);
	};

	const addNewConstant = () => {
		return (
			<Box display="flex" alignItems="center" className="add-btn-container">
				<Typography variant="body2" className={classes.bold + ' add-btn-title'}>
					Add Constant
				</Typography>

				<Tooltip title="Add new constant">
					<IconButton
						color="inherit"
						size="large"
						onClick={() => setOpen({ isOpen: true, type: 'addConstant' })}
					>
						{/* <AddBox fontSize="large" color="secondary" /> */}
						<AddIcon />
					</IconButton>
				</Tooltip>
			</Box>
		);
	};

	return (
		<Fragment>
			<SnackBar
				snackBar={snackBar}
				type={snackBar.type}
				setSnackBar={setSnackBar}
			/>
			{dialogBox()}
			<Grid container>
				<div className="main-container">
					<Grid item xs={12}>
						<Box
							display="flex"
							justifyContent="space-between"
							alignItems="center"
							pb={3}
						>
							<Typography variant="h6">Constants</Typography>
							{addNewConstant()}
						</Box>
					</Grid>
					<Grid item xs={12}>
						{/* {renderBreadCrumbs()} */}
					</Grid>
					<Grid item xs={12}>
						<Table
							meta={meta}
							size="medium"
							data={constants.data && constants.data.data}
							loading={constants.loading}
							errorMessage={constants.error && constants.error.message}
							withPagination
							currentPage={constants.data && constants.data.currentPage}
							rowsPerPage={constants.data && constants.data.perPage}
							totalRowsCount={constants.data && constants.data.total}
							onPageChange={handleChangePage}
						/>
					</Grid>
				</div>
			</Grid>
		</Fragment>
	);
};

export default connect(
	(state) => ({
		constants: state.constant.constants,
	}),
	(dispatch) => ({
		fetchAllConstants: (params) => dispatch(fetchAllConstants(params)),
	})
)(Constants);
