import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "styles/components/Stats";
import CircularProgress from "./CircularProgress";
import {
  Typography,
  Box,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  CardActionArea,
} from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function Stats(props) {
  const classes = useStyles();

  const { title, value, helperText, isLoading } = props;
  return (
    <Card className={classes.root}>
      <CardHeader title={title}></CardHeader>
      <CardContent className={classes.stat}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Typography variant="h2" color="inherit" gutterBottom>
            {value || "0"}
          </Typography>
        )}
      </CardContent>
      {helperText && (
        <CardActions>
          <Box pl={2}>
            <Typography variant="caption" color="inherit" gutterBottom>
              {helperText}
            </Typography>
          </Box>
        </CardActions>
      )}
    </Card>
  );
}
