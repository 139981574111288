import { fetchAction } from "./api";

const actions = {
  FETCH_ALL_COMMUNICATIONS: {
    type: "FETCH_ALL_COMMUNICATIONS",
    key: "communications",
  },

  FETCH_COMMUNICATION: {
    type: "FETCH_COMMUNICATION",
    key: "communication",
  },
};

export const fetchAllCommunications = () => {
  let url = `communication`;
  return fetchAction(actions.FETCH_ALL_COMMUNICATIONS, url);
};

export const fetchCommunication = (id) => {
    return fetchAction(actions.FETCH_COMMUNICATION, `communication/${id}`);
  };

export default actions;
