import { combineReducers } from "redux";

import auth from "./auth";
import user from "./user";
import stats from "./stats";
import products from "./products";
import orders from "./orders";
import company from "./company";
import communication from "./communication";
import adverse from "./adverse";
import constant from "./constant";

// Combine all reducers.
const appReducer = combineReducers({
  adverse,
  auth,
  user,
  stats,
  products,
  orders,
  company,
  communication,
  constant
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
