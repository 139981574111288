import React from "react";
import { Box, CircularProgress } from "@material-ui/core";

const Progress = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <CircularProgress />
    </Box>
  );
};

export default Progress;
