export default (theme) => ({
  list: {
    color: theme.color.black,
    backgroundColor: "#F1F1F1",
    fontSize: 9
  },
  activeTabWhite: {
    color: theme.color.primary,
    backgroundColor: theme.color.white,
    width: "98%",
  },
  activeTabBg: {
    backgroundColor: theme.color.primary,
    color: theme.color.primary,
  },
  heading: {
      fontWeight: "bold",
      fontSize: 13
  }
});
