export default (theme) => ({
  button: {
    backgroundColor: theme.color.secondary,
    color: theme.color.white,
    "&:hover": {
      backgroundColor: theme.color.secondary,
      color: theme.color.white,
    },
  },
  viewButton: {
    backgroundColor: theme.color.disabled,
    color: theme.color.white,
    "&:hover": {
      backgroundColor: theme.color.darkGray,
      color: theme.color.white,
    },
  },
  default: {
    color: theme.color.violet,
  },
  Complete: {
    color: theme.color.darkGreen,
  },
  Request: {
    color: theme.color.secondary,
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  primary: {
    color: theme.color.primary,
  },
  secondary: {
    color: theme.color.secondary,
  },
  inherit: {
    color: theme.color.disabled,
  },
  background: {
    color: theme.color.lightGreen,
  },
  error: {
    color: theme.color.lightRed,
  },
  bold: {
    fontWeight: "bold",
  },
  progressBar: {
    height: 10,
    borderRadius: 5,
    backgroundColor: theme.color.primary,
    position: "absolute"
  },
  barColor: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.color.primary,
  },
  checklistButton: {
    color: theme.color.lightGreen,
    fontSize: "20px",
  },
  checklistButtonDisabled: {
    color: theme.color.lightGray,
    fontSize: "20px",
  },

  progressbackground: {
    height: 10,
    backgroundColor: theme.color.white,
    borderRadius: 5,
    border: "1px solid #dadde9",
    position: "absolute",
    width: "214px"
  },

  tooltip: {
    backgroundColor: theme.color.white,
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },

  container: {
    position: "relative"
  },

});
