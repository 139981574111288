import React, { Fragment, useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import {
	Box,
	Paper,
	TextField,
	LinearProgress,
	Grid,
	Button,
	MenuItem,
	Tooltip,
	IconButton,
	InputAdornment,
} from '@material-ui/core';
import FlexGrid from 'components/FlexGrid';
import SnackBar from 'components/SnackBar';
import request from 'helpers/request';
import { fetchAllConstants } from 'store/actions/constant';
import { Email, Backup, Visibility } from '@material-ui/icons';
import { payType, states, svsStatus } from 'constants/constants';

import styles from 'styles/forms/Applicant';

const useStyles = makeStyles(styles);

const validationSchema = Yup.object({
	firstName: Yup.string().required('First Name is required'),
	lastName: Yup.string().required('Last Name is required'),
	email: Yup.string().email().required('Email is required'),
	phone: Yup.string().required('Phone is required'),
	address: Yup.string().required('Address is required'),
	state: Yup.string().required('State is required'),
	city: Yup.string().required('City is required'),
	zip: Yup.string().required('Zip is required'),
	positionTitle: Yup.string().required('Position Title is required'),
	positionEmploymentStatus: Yup.string().required(
		'Position Status is required'
	),
	positionAddress: Yup.string().required('Position Address is required'),
	positionCity: Yup.string().required('Position City is required'),
	positionState: Yup.string().required('Position State is required'),
	positionZip: Yup.string().required('Position Zip is required'),
	positionStartDate: Yup.string().required('Position Start Date is required'),
	payType: Yup.string().required('Pay Type is required'),
	vacationDays: Yup.string().required('Vacation days is required'),
	reportsTo: Yup.string().required('Reports to is required'),
	reportsToTitle: Yup.string().required('Reports to Title is required'),
	payAmount: Yup.string().required('Pay Amount is required'),
	department: Yup.string().required('Department is required'),
	workLocation: Yup.string().required('Work Location is required'),
	bonus: Yup.string().required('Bonus/Commission is required'),
	exemptStatus: Yup.string().required('Exempt/Non-Exempt status is required'),
	svs: Yup.string().required('SVS is required'),
	clientContact: Yup.string().email(),
});

const Products = (props) => {
	const classes = useStyles();
	const { applicantData, reloadDataFetch, fetchAllConstants, constants } =
		props;
	const [snackBar, setSnackBar] = useState({ isOpen: false, type: '' });
	const [isDownloading, setIsDownloading] = useState(false);

	useEffect(() => {
		fetchAllConstants();
	}, [fetchAllConstants]);

	const fields1 = {
		firstName: 'First Name',
		lastName: 'Last Name',
		email: 'Email',
		phone: 'Phone',
		address: 'Address',
		city: 'City',
		zip: 'Zip',
	};

	const fields2 = {
		positionTitle: 'Position Title',
		positionAddress: 'Position Address',
		positionCity: 'Position City',
	};

	const fields3 = {
		positionZip: 'Position Zip',
		reportsTo: 'Reports To',
		reportsToTitle: 'Reports To Title',
		department: 'Department',
	};

	const dropdownFields = {
		workLocation: 'Work Location',
		positionEmploymentStatus: 'Position Employment Status',
		bonus: 'Bonus/Comission',
		vacationDays: 'Vacation Days',
		exemptStatus: 'Exempt/Non-Exempt Status',
	};

	// const advisoryFields = {
	//   hiringManager: "Hiring/Engagement Manager",
	//   clientName: "Client Name",
	//   projectName: "Project Name",
	// };

	// const advisoryDropdownFields = {
	//   advisoryDepartment: "Department",
	//   advisoryReferredBy: "Referred By",
	// };

	const getInitialValue = (data, key, defaultValue) => {
		if (data && data[key]) {
			return data[key];
		}
		return defaultValue;
	};

	const submitForm = async (values, resetForm) => {
		try {
			const { ...finalPayload } = {
				...values,
			};
			if (applicantData) {
				await request.post({
					url: `applicants/${applicantData.id}/update`,
					body: finalPayload,
				});
				reloadDataFetch();
			} else {
				await request.post({
					url: `applicants`,
					body: finalPayload,
				});
				resetForm();
			}
			setSnackBar({ ...snackBar, isOpen: true, type: 'success' });
		} catch (error) {
			setSnackBar({ ...snackBar, isOpen: true, type: 'error' });
		}
	};

	const uploadLetter = async (id) => {
		try {
			await request.post({
				url: `offerLetter/${id}`,
			});
			setSnackBar({ ...snackBar, isOpen: true, type: 'success' });
		} catch (error) {
			setSnackBar({ ...snackBar, isOpen: true, type: 'error' });
		}
	};

	const emailLetter = async (id) => {
		try {
			await request.post({
				url: `offerLetter/${id}/send`,
			});
			setSnackBar({ ...snackBar, isOpen: true, type: 'success' });
		} catch (error) {
			setSnackBar({ ...snackBar, isOpen: true, type: 'error' });
		}
	};

	const downloadFile = async (url) => {
		try {
			setIsDownloading(true);
			await request.download({ url: url });
			setIsDownloading(false);
			setSnackBar({ ...snackBar, isOpen: true, type: 'success' });
		} catch (error) {
			setSnackBar({ ...snackBar, isOpen: true, type: 'error' });
			setIsDownloading(false);
		}
	};

	const renderField = ({
		setFieldValue,
		value,
		errors,
		touched,
		name,
		label,
	}) => {
		return (
			<Box pt={3} key={name}>
				<TextField
					label={label}
					name={name}
					value={value}
					variant="outlined"
					fullWidth
					multiline={name === 'address'}
					error={errors[name] && touched[name]}
					helperText={touched[name] ? errors[name] : ''}
					InputLabelProps={{
						shrink: true,
					}}
					onChange={(e) => setFieldValue(name, e.target.value)}
				/>
			</Box>
		);
	};

	const renderDropdown = ({
		setFieldValue,
		value,
		errors,
		touched,
		name,
		label,
		data,
	}) => {
		return (
			<Box pt={3} key={name}>
				<TextField
					select
					type="text"
					label={label}
					name={name}
					value={value}
					variant="outlined"
					error={errors[name] && touched[name]}
					helperText={touched[name] ? errors[name] : ''}
					fullWidth
					InputLabelProps={{
						shrink: true,
					}}
					onChange={(e) => setFieldValue(name, e.target.value)}
				>
					{data.map((val) => (
						<MenuItem key={val.label} value={val.label}>
							{val.label}
						</MenuItem>
					))}
				</TextField>
			</Box>
		);
	};

	const getConstantValues = (constants, key) => {
		let res = constants?.data?.find((val) => val.name === key)?.value ?? [];
		return res ? res.sort((a, b) => a.label.localeCompare(b.label)) : [];
	};

	const renderForm = () => {
		return (
			<Formik
				initialValues={{
					firstName: getInitialValue(applicantData, 'firstName', ''),
					lastName: getInitialValue(applicantData, 'lastName', ''),
					email: getInitialValue(applicantData, 'email', ''),
					phone: getInitialValue(applicantData, 'phone', ''),
					address: getInitialValue(applicantData, 'address', ''),
					state: getInitialValue(applicantData, 'state', ''),
					city: getInitialValue(applicantData, 'city', ''),
					zip: getInitialValue(applicantData, 'zip', ''),
					positionTitle: getInitialValue(applicantData, 'positionTitle', ''),
					positionEmploymentStatus: getInitialValue(
						applicantData,
						'positionEmploymentStatus',
						''
					),
					positionAddress: getInitialValue(
						applicantData,
						'positionAddress',
						''
					),
					positionCity: getInitialValue(applicantData, 'positionCity', ''),
					positionState: getInitialValue(applicantData, 'positionState', ''),
					positionZip: getInitialValue(applicantData, 'positionZip', ''),
					positionStartDate: getInitialValue(
						applicantData,
						'positionStartDate',
						''
					),
					payType: getInitialValue(applicantData, 'payType', ''),
					vacationDays: getInitialValue(applicantData, 'vacationDays', ''),
					reportsTo: getInitialValue(applicantData, 'reportsTo', ''),
					reportsToTitle: getInitialValue(applicantData, 'reportsToTitle', ''),
					payAmount: getInitialValue(applicantData, 'payAmount', ''),

					department: getInitialValue(applicantData, 'department', ''),
					workLocation: getInitialValue(applicantData, 'workLocation', ''),
					bonus: getInitialValue(applicantData, 'bonus', ''),
					exemptStatus: getInitialValue(applicantData, 'exemptStatus', ''),
					svs: getInitialValue(applicantData, 'svs', ''),
					clientContact: getInitialValue(applicantData, 'clientContact', ''),
					// hiringManager: getInitialValue(applicantData, "hiringManager", ""),
					// clientName: getInitialValue(applicantData, "clientName", ""),
					// projectName: getInitialValue(applicantData, "projectName", ""),
					// advisoryDepartment: getInitialValue(
					//   applicantData,
					//   "advisoryDepartment",
					//   ""
					// ),
					// advisoryReferredBy: getInitialValue(
					//   applicantData,
					//   "advisoryReferredBy",
					//   ""
					// ),
				}}
				validationSchema={validationSchema}
				onSubmit={(values, { setSubmitting, resetForm }) => {
					submitForm(values, resetForm).then(() => {
						setSubmitting(false);
					});
				}}
				render={({
					submitForm,
					isSubmitting,
					setFieldValue,
					values,
					errors,
					touched,
				}) => (
					<Form>
						<Grid container spacing={3}>
							<Grid item xs={12} lg={12}>
								<Box mx={3} mt={3}>
									<FlexGrid columns={3}>
										{Object.keys(fields1).map((data) => {
											return renderField({
												setFieldValue: setFieldValue,
												value: values[data],
												errors: errors,
												touched: touched,
												name: data,
												label: fields1[data],
											});
										})}
										<Box pt={3}>
											<TextField
												select
												label="State"
												name="state"
												type="text"
												value={values.state}
												variant="outlined"
												error={errors.state && touched.state}
												helperText={touched.state ? errors.state : ''}
												fullWidth
												InputLabelProps={{
													shrink: true,
												}}
												onChange={(e) => setFieldValue('state', e.target.value)}
											>
												{states.map((val) => (
													<MenuItem
														key={val.abbreviation}
														value={val.abbreviation}
													>
														{val.name}
													</MenuItem>
												))}
											</TextField>
										</Box>
									</FlexGrid>
									<div className="divider"></div>
									<FlexGrid columns={3}>
										{Object.keys(fields2).map((data) => {
											return renderField({
												setFieldValue: setFieldValue,
												value: values[data],
												errors: errors,
												touched: touched,
												name: data,
												label: fields2[data],
											});
										})}

										<Box pt={3}>
											<TextField
												select
												label="Position state"
												name="state"
												type="text"
												value={values.positionState}
												variant="outlined"
												error={errors.positionState && touched.positionState}
												helperText={
													touched.positionState ? errors.positionState : ''
												}
												fullWidth
												InputLabelProps={{
													shrink: true,
												}}
												onChange={(e) =>
													setFieldValue('positionState', e.target.value)
												}
											>
												{states.map((val) => (
													<MenuItem
														key={val.abbreviation}
														value={val.abbreviation}
													>
														{val.name}
													</MenuItem>
												))}
											</TextField>
										</Box>
										{Object.keys(fields3).map((data) => {
											return renderField({
												setFieldValue: setFieldValue,
												value: values[data],
												errors: errors,
												touched: touched,
												name: data,
												label: fields3[data],
											});
										})}
									</FlexGrid>
									<div className="divider"></div>
									<FlexGrid columns={3}>
										{Object.keys(dropdownFields).map((key) => {
											return renderDropdown({
												setFieldValue: setFieldValue,
												value: values[key],
												errors: errors,
												touched: touched,
												name: key,
												label: dropdownFields[key],
												data: getConstantValues(constants.data, key),
											});
										})}
									</FlexGrid>
									<div className="divider"></div>
									<FlexGrid columns={3}>
										<Box pt={3}>
											<TextField
												id="datetime-local"
												label="Position Start Date"
												type="date"
												variant="outlined"
												value={
													values.positionStartDate
														? values.positionStartDate.slice(0, 10)
														: ''
												}
												fullWidth
												InputLabelProps={{
													shrink: true,
												}}
												name="positionStartDate"
												error={
													errors.positionStartDate && touched.positionStartDate
												}
												helperText={
													touched.positionStartDate
														? errors.positionStartDate
														: ''
												}
												onChange={(e) =>
													setFieldValue('positionStartDate', e.target.value)
												}
											/>
										</Box>
										<Box pt={3}>
											<TextField
												select
												label="Pay Type"
												name="payType"
												type="text"
												value={values.payType}
												variant="outlined"
												error={errors.payType && touched.payType}
												helperText={touched.payType ? errors.payType : ''}
												fullWidth
												InputLabelProps={{
													shrink: true,
												}}
												onChange={(e) =>
													setFieldValue('payType', e.target.value)
												}
											>
												{payType.map((val) => (
													<MenuItem key={val.id} value={val.id}>
														{val.value}
													</MenuItem>
												))}
											</TextField>
										</Box>
										<Box pt={3}>
											<TextField
												label="Pay Amount"
												name="payAmount"
												type="number"
												value={values.payAmount}
												variant="outlined"
												fullWidth
												error={errors.payAmount && touched.payAmount}
												helperText={touched.payAmount ? errors.payAmount : ''}
												InputLabelProps={{
													shrink: true,
												}}
												onChange={(e) =>
													setFieldValue('payAmount', e.target.value)
												}
												InputProps={{
													startAdornment: (
														<InputAdornment position="start">$</InputAdornment>
													),
												}}
											/>
										</Box>

										<Box pt={3}>
											<TextField
												select
												type="text"
												label="SVS"
												name="svs"
												value={values.svs}
												variant="outlined"
												error={errors.svs && touched.svs}
												helperText={touched.svs ? errors.svs : ''}
												fullWidth
												InputLabelProps={{
													shrink: true,
												}}
												onChange={(e) => setFieldValue('svs', e.target.value)}
											>
												{svsStatus.map((val) => (
													<MenuItem key={val} value={val}>
														{val}
													</MenuItem>
												))}
											</TextField>
										</Box>
										<Box pt={3}>
											<TextField
												label="Client Contact Email"
												name="clientContact"
												value={values.clientContact}
												variant="outlined"
												fullWidth
												error={errors.clientContact && touched.clientContact}
												helperText={
													touched.clientContact ? errors.clientContact : ''
												}
												InputLabelProps={{
													shrink: true,
												}}
												onChange={(e) =>
													setFieldValue('clientContact', e.target.value)
												}
											/>
										</Box>
									</FlexGrid>
									{/* <Grid item xs={12}>
                    <Typography variant="h6">
                      Advisory and Consulting
                    </Typography>
                  </Grid>
                  <FlexGrid columns={3}>
                    {Object.keys(advisoryFields).map((data) => {
                      return renderField({
                        setFieldValue: setFieldValue,
                        value: values[data],
                        errors: errors,
                        touched: touched,
                        name: data,
                        label: advisoryFields[data],
                      });
                    })}
                    {Object.keys(advisoryDropdownFields).map((key) => {
                      return renderDropdown({
                        setFieldValue: setFieldValue,
                        value: values[key],
                        errors: errors,
                        touched: touched,
                        name: key,
                        label: advisoryDropdownFields[key],
                        data: getConstantValues(constants.data, key),
                      });
                    })}
                    </FlexGrid> */}
								</Box>
							</Grid>
							{isSubmitting && (
								<Grid item xs={12} lg={12}>
									<Box mx={3}>
										<LinearProgress />
									</Box>
								</Grid>
							)}
							<Grid item xs={12} lg={12}>
								<Box ml={3} mb={3}>
									<Button
										variant="contained"
										color="secondary"
										onClick={submitForm}
										className={classes.button}
									>
										Save
									</Button>
								</Box>
							</Grid>
						</Grid>
					</Form>
				)}
			/>
		);
	};

	const createOfferLetter = () => {
		return (
			<>
				<Tooltip title="Upload offer letter">
					<IconButton
						color="inherit"
						size="large"
						onClick={() => uploadLetter(applicantData.id)}
					>
						<Backup fontSize="large" color="secondary" />
					</IconButton>
				</Tooltip>

				{applicantData.fileExist && (
					<Tooltip title="Email offer letter to Applicant">
						<IconButton
							color="inherit"
							size="large"
							onClick={() => emailLetter(applicantData.id)}
						>
							<Email fontSize="large" color="secondary" />
						</IconButton>
					</Tooltip>
				)}
				{applicantData.fileExist && (
					<Tooltip title="Preview Letter">
						<IconButton
							color="inherit"
							size="large"
							disabled={isDownloading}
							onClick={() => downloadFile(applicantData.letter)}
						>
							<Visibility
								fontSize="large"
								color={isDownloading ? 'inherit' : 'secondary'}
							/>
						</IconButton>
					</Tooltip>
				)}
			</>
		);
	};

	return (
		<Fragment>
			<SnackBar
				snackBar={snackBar}
				type={snackBar.type}
				setSnackBar={setSnackBar}
			/>
			{applicantData && createOfferLetter()}
			<Paper className={classes.root}> {renderForm()}</Paper>
		</Fragment>
	);
};

export default connect(
	(state) => ({
		constants: state.constant.constants,
	}),
	(dispatch) => ({
		fetchAllConstants: () => dispatch(fetchAllConstants()),
	})
)(Products);
