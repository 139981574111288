import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { connect } from "react-redux";
import request from "helpers/request";
import { makeStyles } from "@material-ui/styles";
import FlexGrid from "components/FlexGrid";
import { Assessment, Report, Visibility } from "@material-ui/icons";
import { fetchApplicantErxReport } from "store/actions/user";
import styles from "styles/pages/Orders/OrderBackgroundCheck";
import CircularProgress from "components/CircularProgress";

const useStyles = makeStyles(styles);

const ViewReports = (props) => {
  const { data, products, fetchApplicantErxReport, applicantErxReport } = props;
  const classes = useStyles();

  useEffect(() => {
    fetchApplicantErxReport(data.erxId);
  }, [fetchApplicantErxReport]);

  const downloadFile = async (data) => {
    try {
      await request.downloadErx(data);
    } catch (error) {
      console.log("error: ", error);
    }
  };


  const getApplicantErxReport = (data) => {
    return (
      <Tooltip title="Applicant Report">
        <IconButton
          color="inherit"
          size="small"
          onClick={() => downloadFile(data.Data.AttachmentEncodedString)}
        >
          <Visibility fontSize="large" color={"secondary"} />
        </IconButton>
      </Tooltip>
    );
  };

  const getErxRenderFields = () => {
    return (
      <FlexGrid columns={1}>
        {applicantErxReport.loading && <CircularProgress />}
        {applicantErxReport.data && !applicantErxReport.loading && (
          <Typography variant="subtitle1">
            Report: {getApplicantErxReport(applicantErxReport.data)}
          </Typography>
        )}
      </FlexGrid>
    );
  };

  const getFilteredProducts = (request, products) => {
    return request.filter((qb) => products.find(( id ) => `${id.productId}` === qb.QBProductNumber));
  };

  return (
    <Grid container spacing={3} display="flex" flexDirection="column">
      <Grid item xs={12} lg={12}>
        {getErxRenderFields()}
        {data && data.backgroundResponse && (
          <FlexGrid columns={1}>
            {getFilteredProducts(data.backgroundResponse, products).map((value, index) => {
              return (
                <ListItem key={index}>
                  <ListItemAvatar>
                    {value.AlertFlag ? (
                      <Report fontSize="small" color="error" />
                    ) : (
                      <Assessment fontSize="small" color="secondary" />
                    )}
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      products.find(
                        (e) => `${e.productId}` === value.QBProductNumber
                      )?.description ?? ""
                    }
                    secondary={value.Notes}
                  />
                </ListItem>
              );
            })}
          </FlexGrid>
        )}
        <Box pb={2}></Box>
      </Grid>
    </Grid>
  );
};

export default connect(
  (state) => ({
    applicantErxReport: state.user.applicantErxReport,
  }),
  (dispatch) => ({
    fetchApplicantErxReport: (id) => dispatch(fetchApplicantErxReport(id)),
  })
)(ViewReports);
