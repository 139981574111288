const defaultApiUrl =
  "https://j8odf85ftd.execute-api.us-west-2.amazonaws.com/production/api/";
let apiBaseUrl = process.env.REACT_APP_API_URL || defaultApiUrl;
if (!apiBaseUrl.endsWith("/")) {
  apiBaseUrl = apiBaseUrl + "/";
}

export const baseUrl = apiBaseUrl;
export const markerIo = '62d70785447e86beaf27b91b';

const defaultExport = {
  baseUrl,
};

export const config = defaultExport;
export default defaultExport;
