import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  Tooltip,
  IconButton,
  Link,
} from "@material-ui/core";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import FlexGrid from "components/FlexGrid";
import { OpenInNew } from "@material-ui/icons";
import {
  fetchAdverseDocument,
} from "store/actions/adverse";
import styles from "styles/pages/Orders/OrderBackgroundCheck";

const useStyles = makeStyles(styles);

const ViewReports = (props) => {
  const {
    data,
    fetchAdverseDocument,
    adverseDocument
  } = props;
  const classes = useStyles();

  useEffect(() => {
    fetchAdverseDocument(data.id, data.type)
  }, [fetchAdverseDocument]);

  const getApplicatnReport = () => {
    return (
      <Tooltip title="Adverse Report">
        <Link
          href={`${adverseDocument.data}`}
          style={{ textDecoration: "none" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconButton
            color="inherit"
            size="small"
            disabled={!adverseDocument.data}
          >
            <OpenInNew fontSize="small" color="secondary" />
          </IconButton>
        </Link>
      </Tooltip>
    );
  };

  return (
    <Grid container spacing={3} display="flex" flexDirection="column">
      <Grid item xs={12} lg={12}>
          <FlexGrid columns={1}>
            <Typography variant="subtitle1">
              Report:{" "}
              {!adverseDocument.loading &&
                adverseDocument.data &&
                getApplicatnReport()}
            </Typography>
          </FlexGrid>
        <Box pb={2}></Box>
      </Grid>
    </Grid>
  );
};

export default connect(
  (state) => ({
    adverseDocument: state.adverse.adverseDocument
  }),
  (dispatch) => ({
      fetchAdverseDocument: (id, type) => dispatch(fetchAdverseDocument(id, type)),
  })
)(ViewReports);
