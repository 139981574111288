import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	Typography,
	Grid,
	Box,
	Tooltip,
	IconButton,
	DialogTitle,
	DialogContent,
	Dialog,
} from '@material-ui/core';
import { fetchAllAdverses } from 'store/actions/adverse';
import Table from 'components/Table';
import SnackBar from 'components/SnackBar';
import { makeStyles } from '@material-ui/core/styles';
import { Email, Edit, Visibility } from '@material-ui/icons';
import styles from 'styles/pages/Company';
import ViewAdverseFile from './Adverse/ViewAdverseFile';
import request from 'helpers/request';
import { ReactComponent as EyeIcon } from '../assets/Icons/icon_adverseAction_view.svg';
import { ReactComponent as EmailIcon } from '../assets/Icons/icon_adverseAction_mail.svg';
import { ReactComponent as EditIcon } from '../assets/Icons/icon_edit.svg';

const useStyles = makeStyles(styles);

const pageInitState = {
	page: 1,
};

const Adverse = (props) => {
	const { fetchAllAdverses, adverses } = props;
	const [pagination, setPagination] = useState(pageInitState);
	const [open, setOpen] = useState({ isOpen: false, type: '' });
	const [snackBar, setSnackBar] = useState({ isOpen: false, type: '' });
	const [dialogData, setdialogData] = useState('');

	const classes = useStyles();

	useEffect(() => {
		fetchAllAdverses(pagination);
	}, [fetchAllAdverses, pagination]);

	const handleChangePage = (event, newPage) => {
		setPagination({ page: newPage + 1 });
	};

	const closeDialog = () => {
		setOpen({ ...open, isOpen: false, type: '' });
	};

	const closeDialogSuccess = () => {
		setOpen({ ...open, isOpen: false, type: '' });
	};

	const renderDialogContent = () => {
		switch (open.type) {
			case 'viewFile':
				return (
					<ViewAdverseFile
						handleClose={() => closeDialogSuccess()}
						setSnackBar={setSnackBar}
						snackBar={snackBar}
						data={dialogData}
					/>
				);
			default:
				return;
		}
	};

	const emailReport = async (id, type) => {
		try {
			await request.post({
				url: `adverse/${id}/email/${type}`,
			});
			setSnackBar({ ...snackBar, isOpen: true, type: 'success' });
		} catch (error) {
			setSnackBar({ ...snackBar, isOpen: true, type: 'error' });
		}
	};

	const dialogBox = () => {
		return (
			<Dialog
				open={open.isOpen}
				fullWidth={true}
				onClose={closeDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">View Report</DialogTitle>
				<DialogContent>{renderDialogContent()}</DialogContent>
			</Dialog>
		);
	};

	const calculatePreAdverseTime = (date) => {
		let preAdverse = new Date(date);
		const diffInMs = new Date() - new Date(preAdverse);
		const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
		return diffInDays > 0 ? diffInDays.toFixed(0, 2) : '-';
	};

	const meta = [
		{
			label: 'Applicant Name',
			render: (row) => (
				<Typography variant={'body2'}>
					{row?.applicant?.firstName} {row?.applicant?.lastName}
				</Typography>
			),
		},
		{
			label: 'Assessment',
			render: (row) => (
				<>
					<Tooltip title="Edit Assessment">
						<Link
							to={`/adverse/edit/${row.id}/${row.applicant.id}`}
							style={{ textDecoration: 'none' }}
						>
							<IconButton color="inherit" size="small">
								<EditIcon fontSize="small" className="secondary" />
							</IconButton>
						</Link>
					</Tooltip>
				</>
			),
		},
		{
			label: 'Pre-Adverse Actions',
			render: (row) => (
				<>
					<Tooltip title="View Report">
						<IconButton
							color="inherit"
							size="small"
							onClick={() => {
								setOpen({ isOpen: true, type: 'viewFile' });
								setdialogData({
									id: row.id,
									type: 'preAdverse',
								});
							}}
						>
							<EyeIcon fontSize="small" className={'secondary-color'} />
						</IconButton>
					</Tooltip>
					<Tooltip title="Email Adverse Report">
						<IconButton
							color="inherit"
							size="small"
							onClick={() => emailReport(row.id, 'preAdverse')}
							disabled={!row.adjudicate}
						>
							<EmailIcon
								fontSize="small"
								className={row.adjudicate ? 'secondary-color' : 'inherit-color'}
							/>
						</IconButton>
					</Tooltip>
				</>
			),
		},

		{
			label: 'Final-Adverse Actions',
			render: (row) => (
				<>
					<Tooltip title="View Report">
						<IconButton
							color="inherit"
							size="small"
							onClick={() => {
								setOpen({ isOpen: true, type: 'viewFile' });
								setdialogData({
									id: row.id,
									type: 'finalAdverse',
								});
							}}
						>
							<EyeIcon fontSize="small" className={'secondary-color'} />
							{/* <Visibility fontSize="small" color={'secondary'} /> */}
						</IconButton>
					</Tooltip>
					<Tooltip title="Email Adverse Report">
						<IconButton
							color="inherit"
							size="small"
							onClick={() => emailReport(row.id, 'finalAdverse')}
							disabled={
								!row.finalAdverseAction ||
								parseInt(calculatePreAdverseTime(row.dateOfPreAdverse)) < 7
							}
						>
							<EmailIcon
								fontSize="small"
								className={
									row.finalAdverseAction &&
									parseInt(calculatePreAdverseTime(row.dateOfPreAdverse)) > 7
										? 'secondary-color'
										: 'inherit-color'
								}
							/>
						</IconButton>
					</Tooltip>
				</>
			),
		},
		{
			label: 'Time Remaining ',
			render: (row) => (
				<Typography variant={'body2'}>
					{calculatePreAdverseTime(row.dateOfPreAdverse)}
				</Typography>
			),
		},

		// {
		// 	label: 'Disqualifying Event',
		// 	render: (row) => (
		// 		<Typography variant={'body2'}>
		// 			{row.disQualifyingEvent.split(',').join(', ')}
		// 		</Typography>
		// 	),
		// },
		// {
		// 	label: 'Time Since Offense',
		// 	render: (row) => (
		// 		<Typography variant={'body2'}>{row.timeToOffense}</Typography>
		// 	),
		// },
	];

	return (
		<Fragment>
			{dialogBox()}
			<SnackBar
				snackBar={snackBar}
				type={snackBar.type}
				setSnackBar={setSnackBar}
			/>
			<Grid container className="main-container adverse-container">
				<Grid item xs={12}>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						pb={3}
					>
						<Typography variant="h6">Adverse Action</Typography>
					</Box>
				</Grid>
				<Grid item xs={12}>
					{/* {renderBreadCrumbs()} */}
				</Grid>
				<Grid item xs={12}>
					<Table
						meta={meta}
						size="medium"
						data={adverses.data && adverses.data.data}
						loading={adverses.loading}
						errorMessage={adverses.error && adverses.error.message}
						withPagination
						currentPage={adverses.data && adverses.data.currentPage}
						rowsPerPage={adverses.data && adverses.data.perPage}
						totalRowsCount={adverses.data && adverses.data.total}
						onPageChange={handleChangePage}
					/>
				</Grid>
			</Grid>
		</Fragment>
	);
};

export default connect(
	(state) => ({
		adverses: state.adverse.adverses,
	}),
	(dispatch) => ({
		fetchAllAdverses: () => dispatch(fetchAllAdverses()),
	})
)(Adverse);
