import React from "react";
import { Formik, Form } from "formik";
import {
  LinearProgress,
  Box,
  TextField,
  Button,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import FlexGrid from "components/FlexGrid";
import request from "helpers/request";

import styles from "styles/pages/profile/UserAttributes";

const useStyles = makeStyles(styles);

const UserAttributes = (props) => {
  const { handleClose, userName, userData } = props;
  const classes = useStyles();

  const changeUserAttributes = async (values) => {
    try {
      let payload = [
        { Name: "custom:title", Value: values.title },
        { Name: "custom:firstName", Value: values.firstName },
        { Name: "custom:workPhone", Value: values.workPhone },
        { Name: "custom:lastName", Value: values.lastName },
      ];
      await request.post({
        url: `users/attributes/${userName}`,
        body: payload,
      });
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };


  const getInitialData = (data, attribute) => {
    return data.find((e) => e.Name === attribute)?.["Value"];
  };

  return (
    <>
      <Formik
        initialValues={{
          email: getInitialData(userData, "email") || "",
          phoneNumber: getInitialData(userData, "phone_number") || "",
          firstName: getInitialData(userData, "custom:firstName") || "",
          lastName: getInitialData(userData, "custom:lastName") || "",
          workPhone: getInitialData(userData, "custom:workPhone") || "",
          title: getInitialData(userData, "custom:title") || "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          changeUserAttributes(values).then(() => {
            setSubmitting(false);
          });
        }}
        render={({ submitForm, isSubmitting, setFieldValue, values }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <Box px={2}>
                  <FlexGrid columns={3}>
                    <Box pt={3}>
                      <TextField
                        label="Email"
                        name="email"
                        value={values.email}
                        variant="outlined"
                        disabled
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => setFieldValue("email", e.target.value)}
                      />
                    </Box>
                    <Box pt={3}>
                      <TextField
                        disabled
                        label="Phone Number"
                        name="phoneNumber"
                        value={values.phoneNumber}
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) =>
                          setFieldValue("phoneNumber", e.target.value)
                        }
                      />
                    </Box>
                    <Box pt={3}>
                      <TextField
                        label="First Name"
                        name="firstName"
                        value={values.firstName}
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) =>
                          setFieldValue("firstName", e.target.value)
                        }
                      />
                    </Box>
                    <Box pt={3}>
                      <TextField
                        label="Last Name"
                        name="lastName"
                        value={values.lastName}
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) =>
                          setFieldValue("lastName", e.target.value)
                        }
                      />
                    </Box>
                    <Box pt={3}>
                      <TextField
                        label="Work Phone"
                        name="workPhone"
                        value={values.workPhone}
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) =>
                          setFieldValue("workPhone", e.target.value)
                        }
                      />
                    </Box>
                    <Box pt={3}>
                      <TextField
                        label="Title"
                        name="title"
                        value={values.title}
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => setFieldValue("title", e.target.value)}
                      />
                    </Box>
                  </FlexGrid>
                </Box>

                {isSubmitting && (
                  <Grid item xs={12} lg={12}>
                    <LinearProgress />
                  </Grid>
                )}
                <Grid item xs={12} lg={12}>
                  <Box pl={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={submitForm}
                      className={classes.button}
                    >
                      Update
                    </Button>
                  </Box>
                </Grid>
                <Box pb={2} />
              </Grid>
            </Grid>
          </Form>
        )}
      />
    </>
  );
};

export default UserAttributes;
