import React from "react";
import { Formik, Form } from "formik";
import {
  LinearProgress,
  Button,
  Grid,
  Typography,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import FlexGrid from "components/FlexGrid";
import request from "helpers/request";
import styles from "styles/pages/PcrUser/ConfirmUserMigration";

const useStyles = makeStyles(styles);

const ConfirmUserMigration = (props) => {
  const classes = useStyles();
  const { handleClose, setSnackBar, snackBar, data } = props;

  const submit = async (values) => {
    const {createdAt, updatedAt, id, status, ...payload} = data; 
    try {
      await request.post({
        url: `pcrUsers/${values.id}/import`,
        body: payload
      });
      setSnackBar({ ...snackBar, isOpen: true, type: "success" });
      handleClose();
    } catch (error) {
      setSnackBar({ ...snackBar, isOpen: true, type: "error" });
    }
  };

  return (
    <Formik
      initialValues={{
        id: data?.id,
      }}
      onSubmit={(values, { setSubmitting }) => {
        submit(values).then(() => {
          setSubmitting(false);
        });
      }}
      render={({ submitForm, isSubmitting }) => (
        <Form>
          <Grid container spacing={3} display="flex" flexDirection="column">
            <Grid item xs={12} lg={12}>
              <FlexGrid columns={1}>
                <Typography variant="body1">
                  Are you sure you want to move this user to applicants?
                </Typography>
              </FlexGrid>
              <Grid item xs={12} lg={12}>
                {isSubmitting && <LinearProgress />}
              </Grid>
              <Box pb={2}></Box>
              <Grid item xs={12} lg={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={submitForm}
                  className={classes.button}
                >
                  Confirm
                </Button>
              </Grid>
              <Box pb={2}></Box>
            </Grid>
          </Grid>
        </Form>
      )}
    />
  );
};

export default ConfirmUserMigration;
