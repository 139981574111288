import { fetchAction } from "./api";

const actions = {
  FETCH_ALL_ADVERSES: {
    type: "FETCH_ALL_ADVERSES",
    key: "adverses",
  },
  FETCH_ADVERSE: {
    type: "FETCH_ADVERSE",
    key: "adverse",
  },
  FETCH_ADVERSE_DOCUMENT: {
    type: "FETCH_ADVERSE_DOCUMENT",
    key: "adverseDocument",
  },
};

export const fetchAllAdverses = () => {
  let url = `adverse`;
  return fetchAction(actions.FETCH_ALL_ADVERSES, url);
};

export const fetchAdverse = (id) => {
  return fetchAction(actions.FETCH_ADVERSE, `adverse/${id}`);
};

export const fetchAdverseDocument = (id, type) => {
    return fetchAction(actions.FETCH_ADVERSE_DOCUMENT, `adverse/${id}/download/${type}`);
  };

export default actions;
