export default (theme) => ({
    button: {
      backgroundColor: theme.color.secondary,
      color: theme.color.white,
      "&:hover": {
        backgroundColor: theme.color.secondary,
        color: theme.color.white,
      },
    },
  });
  