import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  Typography,
  Grid,
  TextField,
  MenuItem,
  Box,
  Divider,
  InputAdornment,
  Button,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form } from "formik";
import styles from "styles/pages/ApplicantInfo/Sidebar";
import {
  Send,
  FormatListBulleted,
  CheckCircle,
  Cancel,
  ArrowForward,
} from "@material-ui/icons";
// import { ReactComponent as iconAction } from '../../../../assets/Icons/icon_actions.svg';
import request from "../../../../helpers/request";
import {
  ChecklistIcon,
  OptionsIcon,
  SendIcon,
  DownloadBtnIcon,
  MainDownloadIcon,
  ViewBtnIcon,
  // OptionsIcon,
} from "assets";

const useStyles = makeStyles(styles);

const Sidebar = (props) => {
  const {
    applicantData,
    isAdmin,
    setdialogData,
    setOpen,
    orders,
    setSnackBar,
    snackBar,
  } = props;
  const classes = useStyles();
  let history = useHistory();
  const [isDownloading, setIsDownloading] = useState(false);
  const getStatusIcon = (check) => {
    if (check) {
      return <CheckCircle size="small" className={classes.checklistButton} />;
    }

    return <Cancel size="small" className={classes.checklistButtonDisabled} />;
  };

  const downloadEntireEmployment = async (id, name) => {
    try {
      setIsDownloading(true);
      await request.downloadApiDocument({
        url: `offerLetter/${id}/employment`,
        filename: name,
      });
      setIsDownloading(false);
      setSnackBar({ ...snackBar, isOpen: true, type: "success" });
    } catch (error) {
      setIsDownloading(false);
      setSnackBar({ ...snackBar, isOpen: true, type: "error" });
    }
  };
  const downloadFile = async (data) => {
    console.log("clicked!!");
    // try {
    //   await request.downloadErx(data);
    // } catch (error) {
    //   console.log("error: ", error);
    // }
  };
  const renderStatsBox = (data) => {
    console.log("data: ", data);
    return (
      <>
        <Box
          pt={3}
          pb={2}
          display="flex"
          alignItems="center"
          className=" checklist-container"
        >
          {/* <FormatListBulleted fontSize="small" color="default" />{' '} */}
          <ChecklistIcon />
          <Typography variant="variant" className="act-title title-font">
            {"  "}Checklist
          </Typography>
        </Box>
        <Grid className={classes.checklist}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className="App-listItem "
            p={2}
          >
            <div className="left-items">
              {getStatusIcon(data.sourceData?.background)}
              <Typography variant="caption">Employment Application</Typography>
            </div>
            <Button
              disabled={!data.sourceData?.background || isDownloading}
              onClick={() =>
                downloadEntireEmployment(data.id, "Employment Application")
              }
              className="checklistBtn"
            >
              <Tooltip title="Download Application">
                <MainDownloadIcon
                  size="small"
                  className={
                    !data.sourceData?.background
                      ? "DownloadBtn disabled-btn "
                      : "DownloadBtn active-btn"
                  }
                  disabled={!data.sourceData?.background || isDownloading}
                />
              </Tooltip>
            </Button>
          </Box>
          <Box px={1}>
            <Divider />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className="App-listItem "
            p={2}
          >
            <div className="left-items">
              {getStatusIcon(data.sourceData?.application)}
              <Typography variant="caption">Applicant Submission </Typography>
            </div>
            <Button
              // disabled={!data.sourceData?.application}
              onClick={() => {
                setOpen({ isOpen: true, type: "sourceData" });
                setdialogData(data.sourceData);
                console.log("whoa look at this data", data.sourceData);
              }}
              className="checklistBtn"
            >
              <Tooltip title="View Submission">
                <ViewBtnIcon
                  size="small"
                  className={
                    !data.sourceData?.application
                      ? "DownloadBtn disabled-btn "
                      : "DownloadBtn active-btn"
                  }
                  // disabled={!data.sourceData?.application}
                  // onClick={() => {
                  //   setOpen({ isOpen: true, type: "sourceData" });
                  //   setdialogData(data.sourceData);
                  //   console.log("whoa look at this data", data.sourceData);
                  // }}
                />
              </Tooltip>
            </Button>
          </Box>
          <Box px={1}>
            <Divider />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className="App-listItem"
            p={2}
          >
            <div className="left-items">
              {getStatusIcon(data.sourceData?.offerLetter ? true : false)}
              <Typography variant="caption">
                Conditional Offer Letter
              </Typography>
            </div>

            {data.sourceData?.offerLetter ? (
              <Button
                onClick={() =>
                  window.open(
                    data.sourceData?.offerLetter.url,
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
              >
                {/* <DownloadBtnIcon size="small" className="DownloadBtn" /> */}

                <ViewBtnIcon size="small" className="DownloadBtn active-btn" />
              </Button>
            ) : (
              <Button>
                {/* <DownloadBtnIcon size="small" className="DownloadBtn" /> */}

                <ViewBtnIcon
                  size="small"
                  className="DownloadBtn disabled-btn "
                />
              </Button>
            )}
          </Box>
          <Box px={1}>
            <Divider />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className="App-listItem"
            p={2}
          >
            <div className="left-items">
              {getStatusIcon(data.erxId)}
              <Typography variant="caption">Background Check</Typography>
            </div>
            <Button onClick={() => downloadFile(data.AttachmentEncodedString)}>
              <DownloadBtnIcon size="small" className="DownloadBtn" />
            </Button>
          </Box>

          {/* <Box px={1}>
            <Divider />
          </Box> */}
          {/* <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className="App-listItem"
            p={2}
          >
            <div className="left-items">
              <Cancel
                size="small"
                className={classes.checklistButtonDisabled}
              />
              <Typography variant="caption">Final Offer Letter</Typography>
            </div>
            <DownloadBtnIcon size="small" className="DownloadBtn" />
          </Box>

          <Box px={1}>
            <Divider />
          </Box> */}
          {/* <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className="App-listItem"
            p={2}
          >
            <div className="left-items">
              <div className=" set-inline check-container">
                <CheckCircle
                  size="small"
                  className={classes.checklistButton + " align-check"}
                />
              </div>
              <div className="set-inline">
                <Typography variant="caption">eCOC Form</Typography>
              </div>
            </div>
            <DownloadBtnIcon size="small" className="DownloadBtn" />
          </Box>
*/}
          <Box px={1}>
            <Divider />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className="App-listItem"
            p={2}
          >
            <div className="left-items">
              {getStatusIcon(data.backgroundCheckResponse)}
              <Typography variant="caption">Drug Test</Typography>
            </div>
            {/* <DownloadBtnIcon size="small" className="DownloadBtn" /> */}
          </Box>
        </Grid>
      </>
    );
  };

  const renderOptions = (data) => {
    return (
      <>
        <Box
          pt={3}
          pb={2}
          display="flex"
          alignItems="center"
          className=" checklist-container"
        >
          {/* <FormatListBulleted fontSize="small" color="default" />{' '} */}
          {/* <OptionsIcon /> */}
          <Typography variant="variant" className="act-title title-font">
            {"  "}Actions
          </Typography>
        </Box>
        <Grid className={classes.checklist}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className="App-listItem app-negative"
            p={2}
          >
            <div className="left-items">
              {/* <i
								className={
									classes.checklistButton + ' far fa-file-alt align-check'
								}
							></i> */}
              <Typography variant="caption">Employment Application</Typography>
            </div>

            <MainDownloadIcon
              size="small"
              className={
                !data.sourceData
                  ? "DownloadBtn disabled-btn "
                  : "DownloadBtn active-btn"
              }
              disabled={!data.sourceData?.background || isDownloading}
              onClick={() =>
                downloadEntireEmployment(data.id, "Employment Application")
              }
            />
          </Box>
          <Box px={1}>
            <Divider />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className="App-listItem app-negative"
            p={2}
          >
            <div className="left-items">
              {/* <i
								className={
									classes.checklistButton + ' far fa-file-alt align-check'
								}
							></i> */}
              <Typography variant="caption">Applicant Submission </Typography>
            </div>

            <ViewBtnIcon
              size="small"
              className={
                !data.sourceData
                  ? "DownloadBtn disabled-btn "
                  : "DownloadBtn active-btn"
              }
              disabled={!data.sourceData}
              onClick={() => {
                setOpen({ isOpen: true, type: "sourceData" });
                setdialogData(data.sourceData);
                console.log("whoa look at this data", data.sourceData);
              }}
            />
          </Box>
          <Box px={1}>
            <Divider />
          </Box>
        </Grid>
      </>
    );
  };

  const renderRecruiterInfo = (data) => {
    return (
      <div className="Rec-info">
        <Box px={1} pt={7} pb={2}>
          <Divider />
        </Box>
        <Box px={1}>
          <Typography
            variant="subtitle2"
            className={classes.subHeading + " title-font"}
          >
            Recruiter Info
          </Typography>
        </Box>
        <Box px={1}>
          <Typography variant="variant">{data.clientName}</Typography>
        </Box>
        <Box px={1}>
          <Typography variant="variant">{data.clientContact}</Typography>
        </Box>
        {/* <Box px={1}>
          <Typography variant="variant">(123) 22-1234</Typography>
        </Box> */}
      </div>
    );
  };

  const setDialogBox = (applicantData, type) => {
    setOpen({ isOpen: true, type: type });
    setdialogData(applicantData);
  };

  const uploadLetter = async (id) => {
    try {
      await request.post({
        url: `offerLetter/${id}`,
      });
    } catch (error) {
      throw error;
    }
  };

  const emailLetter = async (id) => {
    try {
      await request.post({
        url: `offerLetter/${id}/send`,
      });
    } catch (error) {
      throw error;
    }
  };

  const sendConditionalOfferLetter = async (id) => {
    try {
      await uploadLetter(id);
      await emailLetter(id);
      setSnackBar({ ...snackBar, isOpen: true, type: "success" });
    } catch (err) {
      setSnackBar({ ...snackBar, isOpen: true, type: "error" });
    }
  };

  const sendReferenceLibrary = async (id) => {
    try {
      await request.post({
        url: `offerLetter/${id}/send/reference`,
      });
      setSnackBar({ ...snackBar, isOpen: true, type: "success" });
    } catch (err) {
      setSnackBar({ ...snackBar, isOpen: true, type: "error" });
    }
  };
  const sendFinalOfferLetter = async (id) => {
    try {
      await request.post({
        url: `offerLetter/${id}/send/final`,
      });
      setSnackBar({ ...snackBar, isOpen: true, type: "success" });
    } catch (err) {
      setSnackBar({ ...snackBar, isOpen: true, type: "error" });
    }
  };

  const submitForm = (value) => {
    switch (value) {
      case "adverse":
        return applicantData.adverse
          ? history.push(
              `/adverse/edit/${applicantData.adverse.id}/${applicantData.id}`
            )
          : history.push(`/adverse/${applicantData.id}/add`);

      case "order":
        return setDialogBox(applicantData, "order");

      case "delete":
        return setDialogBox(applicantData, "deleteApplicant");

      case "conditionalLetter":
        return sendConditionalOfferLetter(applicantData.id);

      case "referenceLibrary":
        return sendReferenceLibrary(applicantData.id);

      case "finalLetter":
        return sendFinalOfferLetter(applicantData.id);

      default:
        return null;
    }
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          action: "",
        }}
        onSubmit={(values) => {
          submitForm(values.action);
        }}
        render={({ submitForm, setFieldValue, values, touched, errors }) => (
          <Form>
            <TextField
              id="outlined-helperText"
              select
              label="Choose an action..."
              className={classes.selectBox}
              fullWidth
              value={values.action}
              error={errors.action && touched.action}
              onChange={(e) => {
                setFieldValue("action", e.target.value);
                // submitForm();
              }}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      variant="contained"
                      onClick={submitForm}
                      className={classes.button}
                    >
                      <ArrowForward />
                    </Button>
                  </InputAdornment>
                ),
              }}
            >
              {applicantData.status === "Complete" && (
                <MenuItem value={"adverse"}>
                  {applicantData.adverse
                    ? "Edit Adverse Action"
                    : "Add Adverse Action"}
                </MenuItem>
              )}
              <MenuItem
                value={"conditionalLetter"}
                disabled={
                  !applicantData.status || applicantData.status === "Request"
                }
              >
                {applicantData.isOfferLetterSent ||
                applicantData.status === "In Progress" ||
                applicantData.status === "Complete"
                  ? "Resend Conditional letter"
                  : "Send Conditional letter"}
              </MenuItem>
              <MenuItem
                value={"finalLetter"}
                disabled={
                  !applicantData.erxId || !applicantData.isOfferLetterSent
                }
              >
                {applicantData.isFinalOfferLetterSent
                  ? "Resend final letter"
                  : "Send final letter"}
              </MenuItem>
              <MenuItem
                value={"referenceLibrary"}
                disabled={!applicantData.erxId}
              >
                {applicantData.erxId
                  ? "Resend Reference Library"
                  : "Send Reference Library"}
              </MenuItem>
              {applicantData.positionTitle &&
                applicantData.positionStartDate &&
                applicantData.payAmount && (
                  <MenuItem value={"order"}>Submit Order</MenuItem>
                )}

              {isAdmin && (
                <MenuItem value={"delete"}>Delete Applicant</MenuItem>
              )}
            </TextField>
          </Form>
        )}
      />
    );
  };

  return (
    <Fragment>
      <Box p={3} className="sidebar-content">
        <Box pb={2} display="flex" alignItems="center">
          {/* <Send fontSize="small" color="default" />{' '} */}
          <SendIcon />

          <Typography variant="variant" className="act-title title-font">
            {"  "}Actions
          </Typography>
        </Box>
        <Box>{renderForm()}</Box>
        {renderStatsBox(applicantData)}
        {/* {renderOptions(applicantData)} */}
        {renderRecruiterInfo(applicantData)}
      </Box>
    </Fragment>
  );
};

export default Sidebar;
