import { fetchAction } from "./api";

const actions = {
  FETCH_PCR_STATS: {
    type: "FETCH_PCR_STATS",
    key: "pcrStats",
  },
  FETCH_BACKGROUND_STATS: {
    type: "FETCH_BACKGROUND_STATS",
    key: "backgroundStats",
  },
};

export const fetchPcrStats = () => {
  return fetchAction(actions.FETCH_PCR_STATS, `pcrUsers/stats`);
};

export const fetchBackgroundStats = () => {
  return fetchAction(actions.FETCH_BACKGROUND_STATS, `applicants/stats/background`);
};

export default actions;
