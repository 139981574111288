import React, { useState } from "react";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  CssBaseline,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  Tooltip,
  Container,
  Grid,
} from "@material-ui/core";
import { Auth } from "aws-amplify";

import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { ExitToApp } from "@material-ui/icons";
import MainListItems from "components/ListItems";
import { pageTitles } from "constants/constants";
import styles from "styles/pages/Dashboard";
import { EmployifyLogo, HomeIcon } from "assets";

import Layout from "./Layout";
import IdleDetector from "react-idle-detector";

const useStyles = makeStyles(styles);

const Dashboard = (props) => {
  const { isEmailVerified, userData, userGroup } = props;
  const location = useLocation();
  const parentParam = location.pathname.split("/", 2).join("/");
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [emailVerification, setEmailVerification] = useState(isEmailVerified);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const signOut = () => {
    Auth.signOut()
      .then(() => console.log("Signed Out"))
      .catch((err) => console.log(err));
  };

  const noActivityHandler = () => {
    console.log("no activity, logging out");
    signOut();
  };

  return (
    <div className={classes.root}>
      {/* <IdleDetector
        events={["mousemove"]} // Array of listen events
        onNoActivity={noActivityHandler} // callback on no activity
        idleTime={3.6e6} // no activity time, ms
      /> */}
      <CssBaseline />
      {/* <AppBar
        position="absolute"
        color="default"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {pageTitles[location.pathname.split("/")[1]]}
          </Typography>
          <Tooltip title="Logout">
            <IconButton size="large" onClick={() => signOut()}>
              <ExitToApp color="primary" />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar> */}
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !open && classes.drawerPaperClose,
            classes.navbar
          ),
        }}
        open={open}
      >
        <Grid className={classes.toolbarTitle + " Logo-container"}>
          <Grid className={classes.logo + " Logo-container-child"}>
            <EmployifyLogo />
          </Grid>
        </Grid>
        <Divider />
        <List>
          {emailVerification && (
            <MainListItems
              userEmail={userData?.data?.attributes?.email.split("@")[1] ?? ""}
              route={parentParam}
              userGroup={userGroup}
            />
          )}
        </List>
      </Drawer>
      <main className={classes.content}>
        <Container
          maxWidth="lg"
          className={classes.container + " global-parent-container"}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} className="remove-padding">
              <Layout
                isEmailVerified={emailVerification}
                setEmailVerification={setEmailVerification}
                userEmail={
                  userData?.data?.attributes?.email.split("@")[1] ?? ""
                }
                userGroup={userGroup}
              />
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
};

export default Dashboard;
