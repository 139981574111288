import React, { Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Typography, Grid, CircularProgress, Box } from '@material-ui/core';
import AdverseForm from 'forms/Adverse';
import { fetchAdverse } from 'store/actions/adverse';
import { fetchApplicant } from 'store/actions/user';

const EditCommunication = (props) => {
	const { fetchAdverse, adverse, applicant, fetchApplicant } = props;
	const { id, applicantId } = useParams();

	useEffect(() => {
		fetchAdverse(id);
		fetchApplicant(applicantId);
	}, [fetchAdverse, fetchApplicant]);

	return (
		<div className="main-container">
			<Fragment>
				{adverse && applicant && adverse.loading && applicant.loading && (
					<Box display="flex" justifyContent="center">
						<CircularProgress />{' '}
					</Box>
				)}
				{adverse && !adverse.loading && adverse.data && applicant.data && (
					<Grid
						container
						spacing={3}
						display="flex"
						justify="center"
						alignItems="left"
					>
						<Grid item xs={12}>
							<Typography variant="h4" className="add-app-title ">
								Edit Adverse Action
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<AdverseForm
								adverseData={adverse.data}
								applicantData={applicant.data}
								reloadDataFetch={() => fetchAdverse(id)}
							/>
						</Grid>
					</Grid>
				)}
			</Fragment>
		</div>
	);
};

export default connect(
	(state) => ({
		adverse: state.adverse.adverse,
		applicant: state.user.applicant,
	}),
	(dispatch) => ({
		fetchAdverse: (id) => dispatch(fetchAdverse(id)),
		fetchApplicant: (applicantId) => dispatch(fetchApplicant(applicantId)),
	})
)(EditCommunication);
