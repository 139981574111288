import { START, SUCCESS, FAIL } from "store/actions/types";
import request from "helpers/request";
import { Auth, API } from "aws-amplify";

export const roles = ["recruiter"];

export const fetchStart = (action) => {
  if (action.type) {
    return {
      ...action,
      status: START,
    };
  }

  return {
    type: action,
    status: START,
  };
};

export const fetchSuccess = (data, action) => {
  if (action.type) {
    return {
      ...action,
      payload: data,
      status: SUCCESS,
    };
  }

  return {
    type: action,
    payload: data,
    status: SUCCESS,
  };
};

export const fetchFail = (error, action) => {
  if (action.type) {
    return {
      ...action,
      payload: { error },
      status: FAIL,
    };
  }

  return {
    type: action,
    payload: { error },
    status: FAIL,
  };
};

export const getQueryString = (params) => {
  return Object.keys(params)
    .filter((key) =>
      Boolean(Array.isArray(params[key]) ? params[key].length : params[key])
    )
    .map((key) => `${key}=${params[key]}`)
    .join("&");
};

export const fetchAction = (action, url) => {
  return (dispatch) => {
    dispatch(fetchStart(action));
    return request
      .get({ url })
      .then((data) => dispatch(fetchSuccess(data, action)))
      .catch((err) => dispatch(fetchFail(err, action)));
  };
};



const fetchNonGroupUsers = async (action) => {
  try {
    let apiName = "AdminQueries";
    let path = "/listUsers";
    let myInit = {
      queryStringParameters: {
        limit: 25,
        token: "",
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    let data = await API.get(apiName, path, myInit);
    return data?.Users.map((v) => ({ ...v, role: "" })) || [];
  } catch (err) {
    console.log("err: ", err);
  }
};
export const listUsers = (action, limit, nextToken) => {
  return async (dispatch) => {
    dispatch(fetchStart(action));
    try {
      let finalData = [];
      let apiName = "amplifhr";
      let path = "/listUsersInGroup";
      for (let i = 0; i < roles.length; i++) {
        let myInit = {
          queryStringParameters: {
            // username: "48b3d0ac-cb6e-48e6-8d35-8afa1306d1cf",
            groupname: roles[i],
            limit: limit,
            token: nextToken,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
          },
        };
        let data = await API.get(apiName, path, myInit);
        if (data.Users) {
          let withRoleData = await data.Users.map((v) => ({
            ...v,
            role: roles[i],
          }));
          finalData.push(...withRoleData);
        }
      }
      let allUsers = await fetchNonGroupUsers(action);
      let finalizedArray = await allUsers.map((val) => ({
        ...val,
        // ...finalData.find((use) => use.Username === val.Username),
      }));
      return dispatch(fetchSuccess(finalizedArray, action));
    } catch (err) {
      dispatch(fetchFail(err, action));
    }
  };
};
