export default theme => ({
    row: {
        backgroundColor: theme.color.paleBlue,

        '&:last-child > td': {
            borderBottom: 'none',
        },

        '&:hover': {
            backgroundColor: theme.color.whiteSmoke,
        },
    },
    header: {
        backgroundColor: 'transparent',

        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
});
