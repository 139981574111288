import React from "react";
import { TableCell, TableRow, Typography } from "@material-ui/core";

const EmptyData = props => {
  switch (props.type) {
    case "table":
      return (
        <TableRow>
          <TableCell colSpan="100%">
            <Typography align="center">No data found</Typography>
          </TableCell>
        </TableRow>
      );
    case "card":
      return <Typography variant="h6">No data found</Typography>;
    default:
      return "No data found";
  }
};

export default EmptyData;
