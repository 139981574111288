import React, { Fragment, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Paper,
  TextField,
  LinearProgress,
  Grid,
  Button,
  MenuItem,
  FormControlLabel,
  Switch,
  Select,
  Input,
} from "@material-ui/core";
import FlexGrid from "components/FlexGrid";
import SnackBar from "components/SnackBar";
import request from "helpers/request";
import {
  disqualifyingEvents,
  criminalOffenses,
  timeToOffenses,
  jobDutiesConstants,
} from "constants/constants";

import styles from "styles/forms/Company";

const useStyles = makeStyles(styles);

const validationSchema = Yup.object({
  applicantId: Yup.string().required("Applicant is required"),
  dateOfConditionalOffer: Yup.string().required(
    "Date of Conditional Offer is required"
  ),
  dateOfPreAdverse: Yup.string().required("Date of Pre Adverse is required"),
  position: Yup.string().required("Position is required"),
  dateOfApplication: Yup.string().required("Date of Application is required"),
  disQualifyingEvent: Yup.array().required("Disqualifying Event is required"),
  criminalOffense: Yup.string().required("Criminal Offense is required"),
  jobDuties: Yup.string().required("Job Duty is required"),
  dateFinalAdverseAction: Yup.string().required("Date is required"),
  timeToOffense: Yup.string().required("Time since Offense is required"),
});

const Adverse = (props) => {
  const classes = useStyles();
  const { adverseData, applicantData } = props;
  const [snackBar, setSnackBar] = useState({ isOpen: false, type: "" });

  const getInitialValue = (data, key, defaultValue) => {
    if (data && data[key]) {
      return data[key];
    }
    return defaultValue;
  };

  const submitForm = async (values, resetForm) => {
    try {
      const { ...finalPayload } = {
        ...values,
        applicantId: applicantData.id,
        disQualifyingEvent: values.disQualifyingEvent.join(","),
      };
      if (adverseData) {
        await request.post({
          url: `adverse/${adverseData.id}/update`,
          body: finalPayload,
        });
      } else {
        await request.post({
          url: `adverse`,
          body: finalPayload,
        });
        resetForm();
      }
      setSnackBar({ ...snackBar, isOpen: true, type: "success" });
    } catch (error) {
      setSnackBar({ ...snackBar, isOpen: true, type: "error" });
    }
  };

  const switches = [
    { id: "adjudicate", label: "Adjudicate" },
    { id: "finalAdverseAction", label: "Final Adverse" },
  ];

  const renderSwitches = ({ value, name, label, setFieldValue }) => {
    return (
      <Box pt={3}>
        <FormControlLabel
          control={
            <Switch
              checked={value}
              onChange={(e) => setFieldValue(name, e.target.checked)}
            />
          }
          label={label}
        />
      </Box>
    );
  };
  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          applicantId: getInitialValue(applicantData, "id", ""),
          adjudicate: adverseData
            ? getInitialValue(adverseData, "adjudicate", false)
            : true,
          dateOfPreAdverse: getInitialValue(
            adverseData,
            "dateOfPreAdverse",
            ""
          ),
          position: getInitialValue(adverseData, "position", ""),
          dateOfApplication: getInitialValue(
            adverseData,
            "dateOfApplication",
            ""
          ),
          disQualifyingEvent: adverseData?.disQualifyingEvent.split(",") ?? [],
          criminalOffense: getInitialValue(adverseData, "criminalOffense", ""),
          dateOfConditionalOffer: getInitialValue(
            adverseData,
            "dateOfConditionalOffer",
            ""
          ),
          timeToOffense: getInitialValue(adverseData, "timeToOffense", ""),
          jobDuties: getInitialValue(adverseData, "jobDuties", ""),
          dateFinalAdverseAction: getInitialValue(
            adverseData,
            "dateFinalAdverseAction",
            ""
          ),
          finalAdverseAction: adverseData
            ? getInitialValue(adverseData, "finalAdverseAction", false)
            : true,
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          submitForm(values, resetForm).then(() => {
            setSubmitting(false);
          });
        }}
        render={({
          submitForm,
          isSubmitting,
          setFieldValue,
          values,
          errors,
          touched,
        }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <Box mx={3} mt={3}>
                  <FlexGrid columns={3}>
                    <Box pt={3}>
                      <TextField
                        label="Applicant"
                        disabled={true}
                        name="applicantId"
                        type="text"
                        value={`${applicantData?.firstName} ${applicantData?.lastName}`}
                        variant="outlined"
                        error={errors.applicantId && touched.applicantId}
                        helperText={
                          touched.applicantId ? errors.applicantId : ""
                        }
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) =>
                          setFieldValue("applicantId", e.target.value)
                        }
                      />
                    </Box>
                    <Box pt={3}>
                      <TextField
                        id="datetime-local"
                        label="Date of Conditional Offer"
                        type="date"
                        variant="outlined"
                        value={values.dateOfConditionalOffer}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="dateOfConditionalOffer"
                        error={
                          errors.dateOfConditionalOffer &&
                          touched.dateOfConditionalOffer
                        }
                        helperText={
                          touched.dateOfConditionalOffer
                            ? errors.dateOfConditionalOffer
                            : ""
                        }
                        onChange={(e) =>
                          setFieldValue(
                            "dateOfConditionalOffer",
                            e.target.value
                          )
                        }
                      />
                    </Box>
                    <Box pt={3}>
                      <TextField
                        id="datetime-local"
                        label="Date of PreAdverse"
                        type="date"
                        variant="outlined"
                        value={values.dateOfPreAdverse}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="dateOfPreAdverse"
                        error={
                          errors.dateOfPreAdverse && touched.dateOfPreAdverse
                        }
                        helperText={
                          touched.dateOfPreAdverse
                            ? errors.dateOfPreAdverse
                            : ""
                        }
                        onChange={(e) =>
                          setFieldValue("dateOfPreAdverse", e.target.value)
                        }
                      />
                    </Box>
                    <Box pt={3}>
                      <TextField
                        label="Position"
                        name="position"
                        value={values.position}
                        variant="outlined"
                        fullWidth
                        error={errors.position && touched.position}
                        helperText={touched.position ? errors.position : ""}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) =>
                          setFieldValue("position", e.target.value)
                        }
                      />
                    </Box>
                    <Box pt={3}>
                      <TextField
                        id="datetime-local"
                        label="Date of Application"
                        type="date"
                        variant="outlined"
                        value={values.dateOfApplication}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="dateOfApplication"
                        error={
                          errors.dateOfApplication && touched.dateOfApplication
                        }
                        helperText={
                          touched.dateOfApplication
                            ? errors.dateOfApplication
                            : ""
                        }
                        onChange={(e) =>
                          setFieldValue("dateOfApplication", e.target.value)
                        }
                      />
                    </Box>
                    <Box pt={3}>
                      <Select
                        label="Disqualifying Events"
                        name="disQualifyingEvent"
                        type="text"
                        inputProps={<Input />}
                        multiple
                        value={values.disQualifyingEvent}
                        variant="outlined"
                        error={
                          errors.disQualifyingEvent &&
                          touched.disQualifyingEvent
                        }
                        helperText={
                          touched.disQualifyingEvent
                            ? errors.disQualifyingEvent
                            : ""
                        }
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) =>
                          setFieldValue("disQualifyingEvent", e.target.value)
                        }
                      >
                        {disqualifyingEvents.map((val) => (
                          <MenuItem key={val} value={val}>
                            {val}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>

                    <Box pt={3}>
                      <TextField
                        select
                        label="Criminal Offense"
                        name="criminalOffense"
                        type="text"
                        value={values.criminalOffense}
                        variant="outlined"
                        error={
                          errors.criminalOffense && touched.criminalOffense
                        }
                        helperText={
                          touched.criminalOffense ? errors.criminalOffense : ""
                        }
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) =>
                          setFieldValue("criminalOffense", e.target.value)
                        }
                      >
                        {criminalOffenses.map((val) => (
                          <MenuItem key={val} value={val}>
                            {val}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>

                    <Box pt={3}>
                      <TextField
                        select
                        label="Time Since Offense"
                        name="timeToOffense"
                        type="text"
                        value={values.timeToOffense}
                        variant="outlined"
                        error={errors.timeToOffense && touched.timeToOffense}
                        helperText={
                          touched.timeToOffense ? errors.timeToOffense : ""
                        }
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) =>
                          setFieldValue("timeToOffense", e.target.value)
                        }
                      >
                        {timeToOffenses.map((val) => (
                          <MenuItem key={val} value={val}>
                            {val}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>

                    <Box pt={3}>
                      <TextField
                        select
                        label="Job Duty"
                        name="jobDuties"
                        type="text"
                        value={values.jobDuties}
                        variant="outlined"
                        error={errors.jobDuties && touched.jobDuties}
                        helperText={touched.jobDuties ? errors.jobDuties : ""}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) =>
                          setFieldValue("jobDuties", e.target.value)
                        }
                      >
                        {jobDutiesConstants.map((val) => (
                          <MenuItem key={val} value={val}>
                            {val}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>

                    <Box pt={3}>
                      <TextField
                        id="datetime-local"
                        label="Date of Final Adverse"
                        type="date"
                        variant="outlined"
                        value={values.dateFinalAdverseAction}
                        defaultValue={new Date().toISOString().substring(0, 16)}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="dateFinalAdverseAction"
                        error={
                          errors.dateFinalAdverseAction &&
                          touched.dateFinalAdverseAction
                        }
                        helperText={
                          touched.dateFinalAdverseAction
                            ? errors.dateFinalAdverseAction
                            : ""
                        }
                        onChange={(e) =>
                          setFieldValue(
                            "dateFinalAdverseAction",
                            e.target.value
                          )
                        }
                      />
                    </Box>
                    {switches.map((data) => {
                      return renderSwitches({
                        setFieldValue: setFieldValue,
                        value: values[data.id],
                        name: data.id,
                        label: data.label,
                      });
                    })}
                  </FlexGrid>
                </Box>
              </Grid>
              {isSubmitting && (
                <Grid item xs={12} lg={12}>
                  <Box mx={3}>
                    <LinearProgress />
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} lg={12}>
                <Box ml={3} mb={3}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={submitForm}
                    className={classes.button}
                  >
                    Submit
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      />
    );
  };

  return (
    <Fragment>
      <SnackBar
        snackBar={snackBar}
        type={snackBar.type}
        setSnackBar={setSnackBar}
      />
      <Paper className={classes.root}> {renderForm()}</Paper>
    </Fragment>
  );
};

export default Adverse;
