import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
	Table as MuiTable,
	TableBody,
	TableHead,
	Paper,
	TableSortLabel,
	Checkbox,
	TablePagination,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { TableCell, TableRow, Spinner, EmptyData } from 'components';
import styles from 'styles/components/Table';

const useStyles = makeStyles(styles);

/**
 * @param {*} props
 */
const Table = (props) => {
	const classes = useStyles();
	const {
		meta,
		data,
		withPagination,
		currentPage,
		totalRowsCount,
		rowsPerPage,
		onPageChange,
		onChangeRowsPerPage,
		sortKey,
		sortDirection,
		onSort,
		loading,
		errorMessage,
		withSelection,
		onSelectAll,
		selected,
		selectionKey,
		onSelect,
		tableProps,
	} = props;

	const getRowCount = () => {
		if (data) {
			return data.length < rowsPerPage ? data.length : rowsPerPage;
		}
		return 0;
	};

	const getTableHead = () => {
		if (errorMessage) return null;
		return (
			<TableHead className="employifi-table">
				<TableRow header>
					{withSelection && onSelectAll && (
						<TableCell padding="checkbox">
							<Checkbox
								indeterminate={
									selected.length > 0 && selected.length < getRowCount()
								}
								checked={selected.length === getRowCount()}
								onChange={onSelectAll}
							/>
						</TableCell>
					)}
					{meta.map((field) => (
						<TableCell
							key={field.label}
							sortDirection={sortKey === field.sortKey ? sortDirection : false}
							style={{ width: field.width }}
						>
							{field.sortKey ? (
								<TableSortLabel
									active={sortKey === field.sortKey}
									direction={sortKey === field.sortKey ? sortDirection : 'desc'}
									onClick={() => onSort(field.sortKey)}
								>
									{field.label}
								</TableSortLabel>
							) : (
								field.label
							)}
						</TableCell>
					))}
				</TableRow>
			</TableHead>
		);
	};

	const showSpinner = () => {
		if (!errorMessage && loading)
			return (
				<TableRow>
					<TableCell colSpan="100%">
						<Spinner />
					</TableCell>
				</TableRow>
			);

		return null;
	};

	const checkError = () => {
		if (errorMessage)
			return (
				<TableRow>
					<TableCell colSpan="100%">Error:{errorMessage}</TableCell>
				</TableRow>
			);

		return null;
	};

	const checkData = () => {
		if (!loading && data && data.length === 0)
			return <EmptyData type="table" />;
		return null;
	};
	const showData = () => {
		if (loading || errorMessage || !data || data.length === 0) return null;
		return data.map((row, index) => (
			<TableRow
				hover
				role={withSelection ? 'checkbox' : undefined}
				aria-checked={withSelection && selected.includes(row[selectionKey])}
				tabIndex={-1}
				key={index}
				selected={withSelection && selected.includes(row[selectionKey])}
			>
				{withSelection && (
					<TableCell padding="checkbox">
						<Checkbox
							checked={selected.includes(row[selectionKey])}
							onClick={(event) => onSelect(event, row[selectionKey])}
						/>
					</TableCell>
				)}
				{meta.map((field) => {
					return (
						<TableCell key={field.label} {...field.tableCellProps}>
							{field.render(row, index)}
						</TableCell>
					);
				})}
			</TableRow>
		));
	};

	const getTableBody = () => {
		return (
			<TableBody>
				{showSpinner()}
				{checkData()}
				{showData()}
			</TableBody>
		);
	};

	return (
		<Fragment>
			<Paper className={classes.root}>
				<MuiTable className={classes.table} {...tableProps}>
					{checkError()}
					{getTableHead()}
					{getTableBody()}
				</MuiTable>
			</Paper>
			{withPagination && !loading && data && (
				<TablePagination
					rowsPerPageOptions={onChangeRowsPerPage ? [10, 25, 50] : [25]}
					component="div"
					count={totalRowsCount}
					rowsPerPage={rowsPerPage}
					page={currentPage - 1}
					onChangePage={onPageChange}
					labelDisplayedRows={({ from, to, count }) =>
						`${from}-${to} of ${count}`
					}
					labelRowsPerPage={'Rows per page'}
					backIconButtonProps={{
						'aria-label': 'Previous Page',
					}}
					nextIconButtonProps={{
						'aria-label': 'Next Page',
					}}
					onChangeRowsPerPage={onChangeRowsPerPage}
				/>
			)}
		</Fragment>
	);
};

Table.propTypes = {
	meta: PropTypes.arrayOf(PropTypes.object).isRequired,
	data: PropTypes.arrayOf(PropTypes.object),
	withPagination: PropTypes.bool,
	currentPage: PropTypes.number,
	totalRowsCount: PropTypes.number,
	rowsPerPage: PropTypes.number,
	onPageChange: PropTypes.func,
	onChangeRowsPerPage: PropTypes.func,
	sortKey: PropTypes.string,
	onSort: PropTypes.func,
	loading: PropTypes.bool,
	withSelection: PropTypes.bool,
	selected: PropTypes.arrayOf(PropTypes.number),
	selectionKey: PropTypes.string,
	onSelect: PropTypes.func,
	onSelectAll: PropTypes.func,
};

Table.defaultProps = {
	meta: [],
	data: [],
	withPagination: false,
	sortDirection: false,
	loading: false,
	withSelection: false,
	currentPage: 1,
	rowsPerPage: 25,
	selected: [],
};

export default Table;
