// todo eslint disabled
/* eslint-disable */

import React from "react";
import { Formik, Form } from "formik";
import { Auth, API } from "aws-amplify";
import {
  LinearProgress,
  Box,
  TextField,
  Button,
  Grid,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import FlexGrid from "components/FlexGrid";
import { roles } from "store/actions/api";
import request from "helpers/request";

import styles from "styles/pages/Users/ChangeRole";

const useStyles = makeStyles(styles);

const ChangeRole = (props) => {
  const classes = useStyles();
  const { userRole, userName, handleClose } = props;

  const submit = async (values) => {
    try {
      let payload = {
        username: values.user,
        groupname: values.role,
        role: userRole ?? "",
      };
      await request.post({
        url: `users/update/role`,
        body: payload,
      });
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const capitalizeFirstLetter = (str) => {
    return str[0].toUpperCase() + str.slice(1);
  };

  return (
    <Formik
      initialValues={{
        role: userRole,
        user: userName,
      }}
      onSubmit={(values, { setSubmitting }) => {
        submit(values).then(() => {
          setSubmitting(false);
        });
      }}
      render={({ submitForm, isSubmitting, setFieldValue, values }) => (
        <Form>
          <Grid container spacing={3} display="flex" flexDirection="row">
            <Grid item xs={12} lg={12}>
              <FlexGrid columns={1}>
                <Box>
                  <TextField
                    select
                    name="role"
                    label="Role"
                    value={values.role}
                    onChange={(e) => setFieldValue("role", e.target.value)}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  >
                    {roles.map((val) => (
                      <MenuItem key={val} value={val}>
                        {capitalizeFirstLetter(val)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </FlexGrid>
              {isSubmitting && (
                <Grid item xs={12} lg={12}>
                  <LinearProgress />
                </Grid>
              )}
              <Grid item xs={12} lg={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={submitForm}
                  className={classes.button}
                >
                  Submit
                </Button>
              </Grid>
              <Box pb={2}/>
            </Grid>
          </Grid>
        </Form>
      )}
    />
  );
};

export default ChangeRole;
