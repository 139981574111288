import React from "react";
import { Formik, Form } from "formik";
import { Button, TextField, InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ArrowForward } from "@material-ui/icons";
import styles from "styles/components/SearchUsers";

const useStyles = makeStyles(styles);

const SearchUsers = (props) => {
  const classes = useStyles();
  const { data, setPagination, pagination } = props;

  return (
    <Formik
      initialValues={{
        id: data?.id,
        lastName: data?.lastName,
      }}
      onSubmit={(values) => {
        setPagination({
          ...pagination,
          page: 1,
          lastName: values.lastName,
        });
      }}
      render={({ submitForm, setFieldValue, values, touched, errors }) => (
        <Form>
          <TextField
            id="outlined-helperText"
            label="Last Name"
            value={values.lastName}
            error={errors.lastName && touched.lastName}
            helperText={"Search users by last name"}
            onChange={(e) => setFieldValue("lastName", e.target.value)}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={submitForm}
                    className={classes.submitCode}
                  >
                    <ArrowForward />
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </Form>
      )}
    />
  );
};

export default SearchUsers;
