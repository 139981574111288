export default (theme) => ({
  button: {
    color: theme.color.white,
  },
  root: {
    minWidth: "70%",
    background: `linear-gradient(to right, #7A4FFE, #a08cdb)`,
    color: theme.color.white,
  },
  cardContent: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
});
