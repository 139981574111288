import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  LinearProgress,
  Box,
  Button,
  Grid,
  Checkbox,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import FlexGrid from "components/FlexGrid";
import request from "helpers/request";
import styles from "styles/pages/Applicants/CreateOrder";

const useStyles = makeStyles(styles);

const CreateOrder = (props) => {
  const classes = useStyles();
  const {
    handleClose,
    setSnackBar,
    snackBar,
    data,
    products,
    allProducts,
  } = props;

  const submit = async (values) => {
    try {
      let defaultIds = allProducts
        .filter((f) => f.package === "default")
        .map((e) => e.id);
      let optionalIds = Object.keys(values).filter((f) => values[f] === true);
      const finalPayload = {
        applicantId: data.id,
        candidateId: data.candidateId,
        isBackgroundCheckOrdered: false,
        productIds: [
          ...defaultIds.map((e) => parseInt(e)),
          ...optionalIds.map((e) => parseInt(e)),
        ],
      };

      await request.post({
        url: `orders`,
        body: finalPayload,
      });
      setSnackBar({ ...snackBar, isOpen: true, type: "success" });
      handleClose();
    } catch (error) {
      setSnackBar({ ...snackBar, isOpen: true, type: "error" });
    }
  };

  const initialData = (data) => {
    let temp = [];
    data.map((obj) => {
      let key = Object.keys(obj)[0];
      let value = false;
      temp[key] = value;
    });
    return { ...temp };
  };

  return (
    <Formik
      initialValues={initialData(products)}
      onSubmit={(values, { setSubmitting }) => {
        submit(values).then(() => {
          setSubmitting(false);
        });
      }}
      render={({
        submitForm,
        isSubmitting,
        setFieldValue,
        values,
        errors,
        touched,
      }) => (
        <Form>
          <Grid container spacing={3} display="flex" flexDirection="column">
            <Grid item xs={12} lg={12}>
              <Typography variant="subtitle1" gutterBottom>
                CA Bundle:
              </Typography>
              <Typography variant={"body2"}>
                {allProducts?.filter(e => e.package === 'default')?.map((e) => e.description).join(", ")}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Typography variant="subtitle1" gutterBottom>
                Add Additional Products:
              </Typography>
              <FlexGrid columns={1}>
                {products.map((obj) => {
                  let key = Object.keys(obj)[0];
                  let value = obj[key];
                  return (
                    <Box key={key}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Checkbox
                          checked={values[key]}
                          onChange={(e) => setFieldValue(key, e.target.checked)}
                        />
                        <Typography variant="body2">{value}</Typography>
                      </Box>
                    </Box>
                  );
                })}
              </FlexGrid>
            </Grid>
            {isSubmitting && (
              <Grid item xs={12} lg={12}>
                <LinearProgress />
              </Grid>
            )}
            <Grid item xs={12} lg={12}>
              <Button
                variant="contained"
                color="secondary"
                onClick={submitForm}
                className={classes.button}
              >
                Create Order
              </Button>
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        </Form>
      )}
    />
  );
};

export default CreateOrder;
