import React from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import defaultTheme from "styles/themes/default";

const ThemeLoader = (props) => {
  const theme = createMuiTheme({
    ...defaultTheme,
  });

  const themeProvider = (
    <React.Fragment>
      <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
    </React.Fragment>
  );

  return themeProvider;
};

export default ThemeLoader;
