import React, { Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Typography, Grid, CircularProgress, Box } from '@material-ui/core';
import { fetchConstant } from 'store/actions/constant';
import ConstantForm from './components/ViewConstants';

const EditConstant = (props) => {
	const { constant, fetchConstant } = props;
	const { id } = useParams();

	useEffect(() => {
		fetchConstant(id);
	}, [fetchConstant]);

	const formatConstantData = (constants) => {
		return constants.value.map((row) => {
			return { ...row, isEditMode: false, id: row.name };
		});
	};

	return (
		<div className="main-container">
			<Fragment>
				{constant && constant.loading && (
					<Box display="flex" justifyContent="center">
						<CircularProgress />{' '}
					</Box>
				)}
				{constant && !constant.loading && constant.data && (
					<Grid
						container
						spacing={3}
						display="flex"
						justify="center"
						alignItems="left"
					>
						<Grid item xs={12}>
							<Typography variant="h4">Edit constant</Typography>
						</Grid>
						<Grid item xs={12}>
							<ConstantForm
								constantData={formatConstantData(constant.data)}
								fetchConstant={() => fetchConstant(id)}
								id={id}
								data={constant.data}
							/>
						</Grid>
					</Grid>
				)}
			</Fragment>
		</div>
	);
};

export default connect(
	(state) => ({
		constant: state.constant.constant,
	}),
	(dispatch) => ({
		fetchConstant: (id) => dispatch(fetchConstant(id)),
	})
)(EditConstant);
