import actions from "store/actions/orders";
import { START, SUCCESS, FAIL } from "store/actions/types";

const initialState = {
  orders: {
    data: null,
    loading: false,
    error: null,
  },
};

export default function (state = initialState, action) {
  if (!Object.keys(actions).includes(action.type)) {
    return state;
  }

  switch (action.type) {
    default:
      switch (action.status) {
        case START:
          return {
            ...state,
            [action.key]: {
              ...state[action.key],
              loading: true,
            },
          };

        case SUCCESS:
          return {
            ...state,
            [action.key]: {
              ...state[action.key],
              loading: false,
              data: action.payload,
            },
          };

        case FAIL:
          return {
            ...state,
            [action.key]: {
              ...state[action.key],
              loading: false,
              error: action.payload.error,
            },
          };

        default:
          return state;
      }
  }
}
