export default (theme) => ({
	selectBox: {
		backgroundColor: theme.color.white,
		color: theme.color.body,
		borderRadius: '0px',
	},

	checklistButton: {
		color: theme.color.lightGreen,
		fontSize: '20px',
	},

	checklist: {
		backgroundColor: theme.color.white,
	},
	checklistButtonDisabled: {
		color: theme.color.darkGray,
		fontSize: '20px',
	},

	subHeading: {
		fontWeight: 'bold',
	},

	button: {
		backgroundColor: theme.color.primary,
		color: theme.color.white,
		'&:hover': {
			backgroundColor: theme.color.primary,
			color: theme.color.white,
		},
	},
});
