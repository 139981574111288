import React, { Fragment, useEffect } from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Paper,
  TextField,
  LinearProgress,
  Grid,
  Button,
  MenuItem,
  InputAdornment
} from "@material-ui/core";
import FlexGrid from "components/FlexGrid";
import request from "helpers/request";
import { fetchAllConstants } from "store/actions/constant";
import { payType } from "constants/constants";

import styles from "styles/forms/Company";

const useStyles = makeStyles(styles);

const validationSchema = Yup.object({
    positionTitle: Yup.string().required("Position Title is required"),
    positionEmploymentStatus: Yup.string().required(
      "Position Status is required"
    ),
    positionAddress: Yup.string().required("Position Address is required"),
    positionCity: Yup.string().required("Position City is required"),
    positionState: Yup.string().required("Position State is required"),
    positionZip: Yup.string().required("Position Zip is required"),
    positionStartDate: Yup.string().required("Position Start Date is required"),
    payType: Yup.string().required("Pay Type is required"),
    vacationDays: Yup.string().required("Vacation days is required"),
});

const PositionInfo = (props) => {
  const classes = useStyles();
  const { applicantData, setSnackBar, snackBar, handleClose, fetchAllConstants,
    constants } = props;

  useEffect(() => {
    fetchAllConstants();
  }, [fetchAllConstants]);

  const fields1 = {
    positionTitle: "Position Title",
  };

  const dropdownFields = {
    department: "Department",
    positionEmploymentStatus: "Position Employment Status",
    bonus: "Bonus/Comission",
    vacationDays: "Vacation Days",
    exemptStatus: "Exempt/Non-Exempt Status",
  };

  const getInitialValue = (data, key, defaultValue) => {
    if (data && data[key]) {
      return data[key];
    }
    return defaultValue;
  };

  const submitForm = async (values, resetForm) => {
    try {
      const { ...finalPayload } = {
        ...values,
      };
      await request.post({
        url: `applicants/${applicantData.id}/update`,
        body: finalPayload,
      });
      setSnackBar({ ...snackBar, isOpen: true, type: "success" });
      handleClose();
    } catch (error) {
      setSnackBar({ ...snackBar, isOpen: true, type: "error" });
    }
  };

  const renderField = ({
    setFieldValue,
    value,
    errors,
    touched,
    name,
    label,
  }) => {
    return (
      <Box pt={3} key={name}>
        <TextField
          label={label}
          name={name}
          value={value}
          variant="outlined"
          fullWidth
          multiline={name === "address"}
          error={errors[name] && touched[name]}
          helperText={touched[name] ? errors[name] : ""}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setFieldValue(name, e.target.value)}
        />
      </Box>
    );
  };

  const renderDropdown = ({
    setFieldValue,
    value,
    errors,
    touched,
    name,
    label,
    data,
  }) => {
    return (
      <Box pt={3} key={name}>
        <TextField
          select
          type="text"
          label={label}
          name={name}
          value={value}
          variant="outlined"
          error={errors[name] && touched[name]}
          helperText={touched[name] ? errors[name] : ""}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setFieldValue(name, e.target.value)}
        >
          {data.map((val) => (
            <MenuItem key={val.label} value={val.label}>
              {val.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    );
  };

  const getConstantValues = (constants, key) => {
    let res = constants?.data?.find((val) => val.name === key)?.value ?? [];
    return res ? res.sort((a, b) => a.label.localeCompare(b.label)) : [];
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
            positionTitle: getInitialValue(applicantData, "positionTitle", ""),
            positionEmploymentStatus: getInitialValue(
              applicantData,
              "positionEmploymentStatus",
              ""
            ),
            positionAddress: getInitialValue(
              applicantData,
              "positionAddress",
              ""
            ),
            positionCity: getInitialValue(applicantData, "positionCity", ""),
            positionState: getInitialValue(applicantData, "positionState", ""),
            positionZip: getInitialValue(applicantData, "positionZip", ""),
            positionStartDate: getInitialValue(
              applicantData,
              "positionStartDate",
              ""
            ),
            payType: getInitialValue(applicantData, "payType", ""),
            vacationDays: getInitialValue(applicantData, "vacationDays", ""),
            payAmount: getInitialValue(applicantData, "payAmount", ""),
  
            department: getInitialValue(applicantData, "department", ""),
            bonus: getInitialValue(applicantData, "bonus", ""),
            exemptStatus: getInitialValue(applicantData, "exemptStatus", ""),
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          submitForm(values, resetForm).then(() => {
            setSubmitting(false);
          });
        }}
        render={({
          submitForm,
          isSubmitting,
          setFieldValue,
          values,
          errors,
          touched,
        }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <Box mx={3} mt={3}>
                  <FlexGrid columns={3}>
                    {Object.keys(fields1).map((data) => {
                      return renderField({
                        setFieldValue: setFieldValue,
                        value: values[data],
                        errors: errors,
                        touched: touched,
                        name: data,
                        label: fields1[data],
                      });
                    })}
                    {Object.keys(dropdownFields).map((key) => {
                      return renderDropdown({
                        setFieldValue: setFieldValue,
                        value: values[key],
                        errors: errors,
                        touched: touched,
                        name: key,
                        label: dropdownFields[key],
                        data: getConstantValues(constants.data, key),
                      });
                    })}
                    <Box pt={3}>
                      <TextField
                        select
                        label="Pay Type"
                        name="payType"
                        type="text"
                        value={values.payType}
                        variant="outlined"
                        error={errors.payType && touched.payType}
                        helperText={touched.payType ? errors.payType : ""}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) =>
                          setFieldValue("payType", e.target.value)
                        }
                      >
                        {payType.map((val) => (
                          <MenuItem key={val.id} value={val.id}>
                            {val.value}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                    <Box pt={3}>
                      <TextField
                        label="Pay Amount"
                        name="payAmount"
                        type="number"
                        value={values.payAmount}
                        variant="outlined"
                        fullWidth
                        error={errors.payAmount && touched.payAmount}
                        helperText={touched.payAmount ? errors.payAmount : ""}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) =>
                          setFieldValue("payAmount", e.target.value)
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                    <Box pt={3}>
                      <TextField
                        id="datetime-local"
                        label="Position Start Date"
                        type="date"
                        variant="outlined"
                        value={
                          values.positionStartDate
                            ? values.positionStartDate.slice(0, 10)
                            : ""
                        }
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="positionStartDate"
                        error={
                          errors.positionStartDate && touched.positionStartDate
                        }
                        helperText={
                          touched.positionStartDate
                            ? errors.positionStartDate
                            : ""
                        }
                        onChange={(e) =>
                          setFieldValue("positionStartDate", e.target.value)
                        }
                      />
                    </Box>
                  </FlexGrid>
                </Box>
              </Grid>
              {isSubmitting && (
                <Grid item xs={12} lg={12}>
                  <Box mx={3}>
                    <LinearProgress />
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} lg={12}>
                <Box ml={3} mb={3}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={submitForm}
                    className={classes.button}
                  >
                    Submit
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      />
    );
  };

  return (
    <Fragment>
      <Paper className={classes.root}> {renderForm()}</Paper>
    </Fragment>
  );
};

export default connect(
    (state) => ({
      constants: state.constant.constants,
    }),
    (dispatch) => ({
      fetchAllConstants: () => dispatch(fetchAllConstants()),
    })
  )(PositionInfo);
  
