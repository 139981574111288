import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Typography, Grid, Box, Tooltip, IconButton } from '@material-ui/core';
import { fetchAllCompanies } from 'store/actions/company';
import Table from 'components/Table';
import SnackBar from 'components/SnackBar';
import { makeStyles } from '@material-ui/core/styles';
import { AddBox } from '@material-ui/icons';
import styles from 'styles/pages/Company';
import { ReactComponent as AddIcon } from '../assets/Icons/icon_addAction.svg';

const useStyles = makeStyles(styles);

const pageInitState = {
	page: 1,
};

const Products = (props) => {
	const { fetchAllCompanies, companies } = props;
	const [pagination, setPagination] = useState(pageInitState);
	const [snackBar, setSnackBar] = useState({ isOpen: false, type: '' });

	const classes = useStyles();

	useEffect(() => {
		fetchAllCompanies(pagination);
	}, [fetchAllCompanies, pagination]);

	const handleChangePage = (event, newPage) => {
		setPagination({ page: newPage + 1 });
	};

	const meta = [
		{
			label: 'Email',
			render: (row) => <Typography variant={'body2'}>{row.email}</Typography>,
		},
	];

	const addNewProduct = () => {
		return (
			<Box display="flex" alignItems="center" className="add-btn-container">
				<Typography variant="body2" className={classes.bold + ' add-btn-title'}>
					Add Company
				</Typography>

				<Tooltip title="Add new Company">
					<Link to={`/company/add`} style={{ textDecoration: 'none' }}>
						<IconButton color="inherit" size="large">
							{/* <AddBox fontSize="large" color="secondary" /> */}
							<AddIcon />
						</IconButton>
					</Link>
				</Tooltip>
			</Box>
		);
	};

	return (
		<Fragment>
			<SnackBar
				snackBar={snackBar}
				type={snackBar.type}
				setSnackBar={setSnackBar}
			/>
			<Grid container className="main-container">
				<Grid item xs={12}>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						pb={3}
					>
						<Typography variant="h6">Company</Typography>
						{addNewProduct()}
					</Box>
				</Grid>
				<Grid item xs={12}>
					{/* {renderBreadCrumbs()} */}
				</Grid>
				<Grid item xs={12}>
					<Table
						meta={meta}
						size="medium"
						data={companies.data && companies.data.data}
						loading={companies.loading}
						errorMessage={companies.error && companies.error.message}
						withPagination
						currentPage={companies.data && companies.data.currentPage}
						rowsPerPage={companies.data && companies.data.perPage}
						totalRowsCount={companies.data && companies.data.total}
						onPageChange={handleChangePage}
					/>
				</Grid>
			</Grid>
		</Fragment>
	);
};

export default connect(
	(state) => ({
		companies: state.company.company,
	}),
	(dispatch) => ({
		fetchAllCompanies: (params) => dispatch(fetchAllCompanies(params)),
	})
)(Products);
