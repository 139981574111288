const colors = {
	primary: '#62A7E0',
	secondary: '#ff9900',
	black: 'rgba(0, 0, 0, 0.87)',
	body: '#757976',
	white: '#FFFFFF',
	gray: '#FCFCFC',
	darkGray: '#707070',
	tertiaryGray: '#EAEAEA',
	whiteSmoke: '#F5F5F5',
	paleBlue: '#F8F9FB',
	sectionHeading: '#363F50',
	darkYellow: '#E2C571',
	darkRed: '#CD5B6C',
	hotRed: '#ff3f3f',
	darkBlue: '#2d67c1',
	darkGreen: '#418c59',
	lightGreen: '#74C62E',
	lightBlue: '#579ddb',
	lightGray: '#aaabaa',
	brightBlue: '#6199e2',
	orange: '#E2C571',
	lightRed: '#f44336',
	red: '#CD5B6C',
	violet: '#8A2BE2',
	cyan: '#00FFFF',
	disabled: '#B6B6B6',
};

const theme = {
	palette: {
		common: {
			black: 'rgba(0, 0, 0, 0.87)',
			white: '#FFFFFF',
		},
		primary: {
			main: colors.primary,
		},
		secondary: {
			main: colors.secondary,
		},
		text: {
			primary: colors.black,
			white: colors.white,
		},
		warning: {
			main: colors.orange,
		},
		error: {
			main: colors.red,
		},
		grey: {
			25: '#FAFAFA',
			50: '#FCFCFC',
			100: '#F5F5F5',
			750: '#6F7075',
		},
	},
	color: colors,
	typography: {
		fontFamily: "'Lato', 'Helvetica', 'Arial', sans-serif",
		body1: {
			color: 'inherit',
			fontWeight: 'inherit',
		},
		greyed: {
			color: colors.darkGray,
		},
		fontWeightBold: 700,
	},
};

export default {
	...theme,
};
