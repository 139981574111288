import React, { Fragment } from 'react';
import { Typography, Grid } from '@material-ui/core';
import ProductForm from 'forms/Products';

const AddProduct = () => {
	return (
		<Fragment>
			<div className="main-container">
				<Grid
					container
					spacing={3}
					display="flex"
					justify="center"
					alignItems="left"
				>
					<Grid item xs={12}>
						<Typography variant="h6" className="add-app-title">
							Add Product
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<ProductForm />
					</Grid>
				</Grid>
			</div>
		</Fragment>
	);
};

export default AddProduct;
