export default (theme) => ({
  stat: {
    display: "flex",
    flexDirection: "column",
    justifyConten: "center",
    alignItems: "center",
  },

  root: {
    minWidth: 275,
    height: 195,
  },
});
