import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import {
	Typography,
	Grid,
	Box,
	Tooltip,
	IconButton,
	Link as MuiLink,
	Button,
	Divider,
} from '@material-ui/core';
import { fetchAllApplicants } from 'store/actions/user';
import { fetchBackgroundStats } from 'store/actions/stats';
import { fetchAllProducts } from 'store/actions/products';
import { fetchCommunication } from 'store/actions/communication';
import Table from 'components/Table';
import SnackBar from 'components/SnackBar';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { AddBox, CheckCircle, Cancel } from '@material-ui/icons';
import styles from 'styles/pages/Applicants';
import SearchUsers from 'components/SearchUsers';
import { progressBarConstants } from 'constants/constants';
import { fetchAllOrders } from 'store/actions/orders';
import { ReactComponent as AddIcon } from '../assets/Icons/icon_addAction.svg';
const useStyles = makeStyles(styles);

const pageInitState = {
	page: 1,
};

const HtmlTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: 'white',
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 220,
		width: 400,
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid #dadde9',
	},
}))(Tooltip);

const Applicants = (props) => {
	const {
		fetchAllApplicants,
		applicants,
		fetchAllProducts,
		products,
		fetchBackgroundStats,
		orderStats,
		fetchCommunication,
		communication,
		isAdmin,
		fetchAllOrders,
		orders,
	} = props;
	const [pagination, setPagination] = useState(pageInitState);
	const [snackBar, setSnackBar] = useState({ isOpen: false, type: '' });
	const [sortKey, setSortKey] = useState('');
	const [sortDirection, setSortDirection] = useState('');

	const classes = useStyles();
	const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

	useEffect(() => {
		fetchAllApplicants({
			...pagination,
			sortKey: sortKey,
			sortDirection: sortDirection,
		});
	}, [fetchAllApplicants, pagination, sortKey, sortDirection]);

	useEffect(() => {
		fetchAllProducts();
		fetchBackgroundStats();
		fetchCommunication('dashboard');
		fetchAllOrders({ all: 'all' });
	}, [
		fetchAllProducts,
		fetchBackgroundStats,
		fetchCommunication,
		fetchAllOrders,
	]);

	const handleChangePage = (event, newPage) => {
		setPagination({ page: newPage + 1 });
	};

	const handleRequestSort = (property) => {
		const isAsc = sortKey === property && sortDirection === 'asc';
		setSortDirection(isAsc ? 'desc' : 'asc');
		setSortKey(property);
	};

	const interleave = (arr, x) => arr.flatMap((e) => [e, x]).slice(0, -1);

	const getStatusIcon = (check) => {
		if (check) {
			return <CheckCircle size="small" className={classes.checklistButton} />;
		}

		return <Cancel size="small" className={classes.checklistButtonDisabled} />;
	};

	const getFilteredProducts = (request, products) => {
		return request.filter((qb) =>
			products.find((id) => `${id.productId}` === qb.QBProductNumber)
		);
	};

	const fetchApplicantOrder = (order, applicantId) => {
		let userOrder = order?.find((e) => e.applicant.id === applicantId);
		if (userOrder) {
			userOrder = userOrder?.products?.filter(function (obj) {
				return userOrder.applicant.erxId ? obj.productId !== 4 : true;
			});
			return userOrder || [];
		} else {
			return []
		}
	};

	const ifTraceReportOrdered = (order, applicantId) => {
		let userOrder = order?.find((e) => e.applicant.id === applicantId);
		let isTraceReport = userOrder?.products?.find((e) => e.productId === 4);
		return isTraceReport;
	};

	const renderProgressBarModal = (data) => {
		if (data.backgroundCheckResponse) {
			let renderObject = getFilteredProducts(
				data.backgroundCheckResponse,
				products?.data?.data
			).map((v) => {
				return (
					<Box
						display="flex"
						alignItems="flex-start"
						justifyContent="space-between"
						p={1}
					>
						<Typography variant="caption">
							{products?.data?.data.find(
								(e) => `${e.productId}` === v.QBProductNumber
							)?.description ?? ''}
						</Typography>
						&emsp;
						{getStatusIcon(!v['AlertFlag'])}
					</Box>
				);
			});
			return interleave(
				renderObject.filter((e) => e),
				<Box px={1}>
					<Divider />
				</Box>
			);
		} else if (
			data.erxId &&
			!data.backgroundCheckResponse &&
			ifTraceReportOrdered(orders?.data?.data, data.id)
		) {
			return (
				<Box
					display="flex"
					alignItems="flex-start"
					justifyContent="space-between"
					p={1}
				>
					<Typography variant="caption">Trace Report</Typography>
					&emsp;
					{getStatusIcon(true)}
				</Box>
			);
		}
	};

	const renderRemainingProgressItems = (data) => {
		let renderObject =
			orders?.data?.data &&
			fetchApplicantOrder(orders?.data?.data, data.id)?.map((v) => {
				return (
					<Box
						display="flex"
						alignItems="flex-start"
						justifyContent="space-between"
						p={1}
					>
						<Typography variant="caption">{v.description}</Typography>
						&emsp;
						{getStatusIcon(false)}
					</Box>
				);
			});
		return (
			renderObject &&
			interleave(
				renderObject.filter((e) => e),
				<Box px={1}>
					<Divider />
				</Box>
			)
		);
	};

	const getPercentProgress = (data) => {
		let percent = 0;
		let row =
			data.backgroundCheckResponse &&
			getFilteredProducts(data.backgroundCheckResponse, products?.data?.data);
		if (data.erxId) {
			percent += 25;
		}
		if (row && row.length >= 1 && data.erxId) {
			percent += 75;
		}
		return percent;
	};

	const meta = [
		{
			label: 'Last Name',
			sortKey: 'lastName',
			render: (row) => (
				<Typography variant={'body2'}>{row.lastName}</Typography>
			),
		},
		{
			label: 'First Name',
			sortKey: 'firstName',
			render: (row) => (
				<Typography variant={'body2'}>{row.firstName}</Typography>
			),
		},
		{
			label: 'Date Added',
			render: (row) =>
				row.createdAt ? new Date(row.createdAt).toLocaleDateString() : '',
		},
		{
			label: 'Last Updated',
			render: (row) =>
				row.updatedAt ? new Date(row.updatedAt).toLocaleDateString() : '',
		},
		{
			label: 'Status',
			width: 230,
			render: (row) => (
				<Grid className={classes.container}>
					<HtmlTooltip
						title={renderRemainingProgressItems(row)}
						arrow
						className={classes.tooltip + ' progressbar-parent'}
					>
						<Grid className={classes.progressbackground}>
							<Typography variant={'body2'} />
						</Grid>
					</HtmlTooltip>
					{/* <HtmlTooltip
            title={renderProgressBarModal(
              {
                isOfferLetterSent: row.isOfferLetterSent && true,
                erxId: row.erxId && true,
                backgroundCheckResponse: row.backgroundCheckResponse,
                ecocForm: false,
                finalOfferLetter: false,
                another: true,
              },
              true
            )}
            arrow
            className={classes.tooltip}
          >
            <Grid className={classes.progressbackground}>
              <Typography variant={"body2"} />
            </Grid>
          </HtmlTooltip> */}
					{getPercentProgress(row) ? (
						<HtmlTooltip
							// title={renderProgressBarModal({
							//   isOfferLetterSent: row.isOfferLetterSent && true,
							//   erxId: row.erxId && true,
							//   backgroundCheckResponse: row.backgroundCheckResponse,
							//   ecocForm: false,
							//   finalOfferLetter: false,
							//   another: true,
							// })}
							title={renderProgressBarModal(row)}
							arrow
							className={classes.tooltip + ' progressbar-child'}
						>
							<Grid
								className={classes.progressBar}
								style={{
									width: `${getPercentProgress(row)}%`,
									backgroundColor: '#62A7E0',
								}}
							></Grid>
						</HtmlTooltip>
					) : (
						''
					)}
				</Grid>
			),
		},
		{ label: '', render: () => <></> },
		{
			label: 'Action',
			render: (row) => (
				<Button
					color="default"
					size="small"
					className={classes.viewButton}
					component={Link}
					to={`/applicants/edit/${row.id}`}
				>
					<Typography variant="caption">View</Typography>
				</Button>
			),
		},
	];

	const addNewApplicant = () => {
		return (
			<Box display="flex" alignItems="center" className="add-btn-container">
				<Typography variant="body2" className={classes.bold + ' add-btn-title'}>
					Add Applicant
				</Typography>

				<Tooltip title="Add Applicant">
					<Link to={`/applicants/add`} style={{ textDecoration: 'none' }}>
						<IconButton color="inherit" size="large">
							{/* <AddBox fontSize="large" color="primary" /> */}
							<AddIcon />
						</IconButton>
					</Link>
				</Tooltip>
			</Box>
		);
	};

	return (
		<Fragment>
			<SnackBar
				snackBar={snackBar}
				type={snackBar.type}
				setSnackBar={setSnackBar}
			/>
			<Grid container className="main-container">
				<Grid item xs={12}>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						pb={3}
						pt={2}
					>
						<Typography variant="h6">Applicants</Typography>
						{addNewApplicant()}
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Box pb={3}>
						<SearchUsers
							setPagination={setPagination}
							pagination={pagination}
						/>
					</Box>
				</Grid>
				<Grid item xs={12} className="applicant-table-container">
					<Table
						meta={meta}
						size="medium"
						data={applicants.data && applicants.data.data}
						loading={applicants.loading}
						errorMessage={applicants.error && applicants.error.message}
						withPagination
						currentPage={applicants.data && applicants.data.currentPage}
						rowsPerPage={applicants.data && applicants.data.perPage}
						totalRowsCount={applicants.data && applicants.data.total}
						onPageChange={handleChangePage}
						sortKey={sortKey}
						sortDirection={sortDirection}
						onSort={(e) => handleRequestSort(e)}
					/>
				</Grid>
			</Grid>
		</Fragment>
	);
};

export default connect(
	(state) => ({
		applicants: state.user.applicants,
		products: state.products.products,
		orderStats: state.stats.backgroundStats,
		communication: state.communication.communication,
		orders: state.orders.orders,
	}),
	(dispatch) => ({
		fetchAllApplicants: (params) => dispatch(fetchAllApplicants(params)),
		fetchAllProducts: () => dispatch(fetchAllProducts()),
		fetchBackgroundStats: () => dispatch(fetchBackgroundStats()),
		fetchCommunication: (id) => dispatch(fetchCommunication(id)),

		fetchAllOrders: (params) => dispatch(fetchAllOrders(params)),
	})
)(Applicants);
