export default (theme) => ({
  container: (props) => {
    const columns = props.columns || 4;

    return {
      display: "flex",
      flexWrap: "wrap",
      paddingBottom: theme.spacing(2),
      "& > *": {
        [theme.breakpoints.down("md")]: {
          width: "50%",
          paddingRight: theme.spacing(2),

          [`&:nth-child(2n)`]: {
            paddingRight: theme.spacing(0),
          },
        },

        [theme.breakpoints.down("sm")]: {
          width: "100%",
          paddingRight: theme.spacing(0),
        },

        width: `calc(100% / ${columns})`,
        paddingRight: theme.spacing(2),

        [`&:nth-child(${columns}n)`]: {
          paddingRight: theme.spacing(0),
        },
      },
    };
  },
});
